import { CommentSap } from '../types/model';
import { CommentSapItemComponent } from './ComponentSapItemComponent';
import * as React from 'react'
import { FunctionComponent } from 'react';

interface CommentSapProps {
    commentSap? : CommentSap;
}

export const CommentSapComponent: FunctionComponent<CommentSapProps> = ({commentSap}) => {
    return (
        <div className="column">
            <div className="columns is-3">
                <div className="column mr-6">
                    <CommentSapItemComponent title="BASIC DATA TEXT" name={"commentSap.basicDataText"}  />
                </div>
                <div className="column ml-6">
                    <CommentSapItemComponent title="INTERNAL COMMENT" name={"commentSap.internalComment"} />
                </div>
            </div>
            <div className="columns">
                <div className="column mr-6 mt-6">
                    <CommentSapItemComponent title="PURCHASE ORDER TEXT" name={"commentSap.purchaseOrderText"} />
                </div>
                <div className="column ml-6 mt-6">
                    <CommentSapItemComponent title="MATERIAL MEMO" name={"commentSap.materialMemo"}/>
                </div>
            </div>
        </div>
    );
}

