import { Button, Col, CustomInput, Input, Row } from "reactstrap";
import React, { FunctionComponent } from "react";

interface OwnProps {
  pageCount: number;
  gotoPage: (index: number) => void;
  canPreviousPage: boolean;
  canNextPage: boolean;
  previousPage: () => void;
  pageIndex: number;
  pageOptions: number[];
  setPageSize: (number: number) => void;
  pageSize: number;
  nextPage: any;
  addRow?: (index?: number) => void;
  rows: number;
}

type Props = OwnProps;

export const GridviewFooterComponent: FunctionComponent<Props> = ({
  nextPage,
  canNextPage,
  canPreviousPage,
  previousPage,
  pageSize,
  pageIndex,
  pageOptions,
  gotoPage,
  pageCount,
  setPageSize,
  rows,
  addRow
}) => {
  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <div className="columns gridview-footer mt-3 mb-3 mr-0">
      <div style={{ width: "240px" }}></div>
      <>
        <Row
          style={{
            justifyContent: "center",
            display: "inline-flex",
            marginLeft: addRow ? "130px" : "0px",
          }}
        >
          <Col md={3}>
            <Button
              color="primary"
              className="navigation-gridview-button"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              className="navigation-gridview-button"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </Col>
          <Col style={{ marginRight: "5px" }}>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>
          {rows > 0 && (
            <>
              <Col md={2} style={{ marginRight: "5px" }}>
                <Input
                  type="number"
                  min={1}
                  style={{ width: 70 }}
                  max={pageOptions.length}
                  defaultValue={pageIndex + 1}
                  onChange={onChangeInInput}
                />
              </Col>
              <Col md={2}>
                <CustomInput
                  id={1}
                  type="select"
                  value={pageSize}
                  onChange={onChangeInSelect}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </CustomInput>
              </Col>
            </>
          )}
          <Col md={3} style={{ marginLeft: "5px" }}>
            <Button
              color="primary"
              onClick={nextPage}
              className="navigation-gridview-button"
              disabled={!canNextPage}
            >
              {">"}
            </Button>
            <Button
              color="primary"
              className="navigation-gridview-button"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </Col>
        </Row>
        <div style={{ width: "240px" }}>
          {addRow && (
            <>
              <Button
                color="primary"
                type={"button"}
                style={{ marginLeft: "20px" }}
                className="navigation-gridview-button"
                onClick={() => (addRow ? addRow() : undefined)}
              >
                {"+"}
              </Button>
              Add Item
            </>
          )}
        </div>
      </>
    </div>
  );
};
