import { Action } from "redux";

export const SET_TACTICAL_PARTS_FIND_VALUES = "SET_TACTICAL_PARTS_FIND_VALUES";
export const SET_PARTSPERPSM_FIND_VALUES = "SET_PARTSPERPSM_FIND_VALUES";
export const SET_MISSED_SALES_FIND_VALUES = "SET_MISSED_SALES_FIND_VALUES";

export interface SetTacticalPartsFindValues extends Action<string> {
    group:string,
    ert: string,
    inAssortiment: string,
}

export interface SetPartsPerPSMFindValues extends Action<string>{
    psm: string,
    brand: string,
    ert: string,
}

export interface SetMissedSalesFindValues extends Action<string>{
    ert: string,
    hitrate: string,
    psm: string, 
    authGroup: string,
    inAssortiment: string,
}