import React, { FunctionComponent, useState } from "react";
import { faSignInAlt, faUser } from "@fortawesome/free-solid-svg-icons";

import { AccountInfo } from "@azure/msal-browser";
import { AnyAction } from "redux";
import AzureAuthenticationContext from "../auth/authContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../images/logo.png";
import { NavLink } from "react-router-dom";
import ProductDropdown from "../components/InputTypes/ProductDropdown";
import { RootState } from "../types/state";
import { ThunkDispatch } from "redux-thunk";
import classnames from "classnames";
import { connect } from "react-redux";
import { setProfile } from "../actions/profileActions";
import { checkPermission } from "../helpers/permissionHelper";
import { Role } from "../types/model";

const mapStateToProps = ({ profile }: RootState) => ({
  profile: profile,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, AnyAction>
) => ({
  setProfile: (profile?: AccountInfo) => dispatch(setProfile(profile)),
});

interface NavigationBarProps {
  showProductDropdown: boolean;
}

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const isIE = msie > 0 || msie11 > 0;

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  NavigationBarProps;

const NavigationBarContainer: FunctionComponent<Props> = ({
  setProfile,
  profile,
  showProductDropdown,
}) => {
  const setProfileReducer = (account?: AccountInfo) => {
    if (account?.name !== profile?.name) {
      setProfile(account);
    }
  };

  const authenticationModule: AzureAuthenticationContext =
    new AzureAuthenticationContext();
  var account = authenticationModule.getAccount();
  setProfileReducer(account);

  const [authenticated, setAuthenticated] = useState<Boolean>(
    account ? true : false
  );
  const [user, setUser] = useState<AccountInfo | undefined>(account);

  const logIn = (): any => {
    const typeName = "loginPopup";
    const logInType = isIE ? "loginRedirect" : typeName;
    authenticationModule.login(logInType, returnedAccountInfo, dummy);
  };

  const dummy = (): any => { };

  const logOut = (): any => {
    if (user) {
      authenticationModule.logout(user);
    }
  };

  const returnedAccountInfo = (user: AccountInfo) => {
    setProfileReducer(user);
    setAuthenticated(user?.name ? true : false);
    setUser(user);
  };

  return (
    <div className="columns navigation-bar">
      <div className="column navigation-column is-narrow logo-wrapper">
        <img src={Logo} alt="logo"></img>
      </div>
      <div className="is-5 column navigation-column">
        <div className="columns navigation-columns">
          <div className="column navbar-item-container">
            <NavLink
              className="navbar-item is-flex "
              activeClassName="is-active"
              to="/products"
            >
              Product Dashboard
            </NavLink>
          </div>
          {profile && checkPermission(profile, Role.DashboardExportUser) && (
            <>
              <div className="column navbar-item-container">
                <NavLink
                  className="navbar-item is-flex "
                  activeClassName="is-active"
                  to="/tacticalparts"
                >
                  Tactical Parts
                </NavLink>
              </div>
              <div className="column navbar-item-container">
                <NavLink
                  className="navbar-item is-flex "
                  activeClassName="is-active"
                  to="/partsperpsm"
                >
                  Parts per Psm
                </NavLink>
              </div>
              <div className="column navbar-item-container">
                <NavLink
                  className="navbar-item is-flex "
                  activeClassName="is-active"
                  to="/missedsales"
                >
                  Missed Sales
                </NavLink>
              </div>
            </>
          )}
          {profile && checkPermission(profile, Role.Admin) && (
            <>
              <div className="column navbar-item-container">
                <NavLink
                  className="navbar-item is-flex "
                  activeClassName="is-active"
                  to="/newpart"
                >
                  New part
                </NavLink>
              </div>
            </>
          )}

          {profile && checkPermission(profile, Role.DashboardExportUser) && (
            <div className="column navbar-item-container">
              <NavLink
                className="navbar-item is-flex "
                activeClassName="is-active"
                to="/dashboardexport"
              >
                Dashboard export
              </NavLink>
            </div>
          )}
        </div>
      </div>
      {showProductDropdown && (
        <div className="column navigation-column">
          <ProductDropdown />
        </div>
      )}

      <div
        className={classnames(
          showProductDropdown ? (user ? "is-2" : "is-1") : "",
          "column navigation-column is-justify-content-flex-end"
        )}
      >
        <div
          className="columns"
          style={{ width: "100%", height: "40px", justifyContent: "flex-end", maxWidth: "200px" }}
        >
          {user && (
            <div
              className="column is-narrow is-flex is-paddingless is-justify-content-end"
              style={{ justifyContent: "flex-end" }}
            >
              <button className="navigation-button">
                <div className="is-flex is-align-items-center">
                  <FontAwesomeIcon icon={faUser} />
                  <span>{user.name}</span>
                </div>
              </button>
            </div>
          )}
          <div
            className="column is-narrow is-flex is-paddingless is-justify-content-end"
            style={{ justifyContent: "flex-end" }}
          >
            <button
              className="navigation-button"
              onClick={() => (authenticated ? logOut() : logIn())}
            >
              <div className="is-flex is-align-items-center">
                <FontAwesomeIcon icon={faSignInAlt} />
                {/* <span>{authenticated ? "Log Out" : "Log In"}</span> */}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationBarContainer);
