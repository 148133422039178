import { AuthGroup } from '../types/model';

import ApiBase from './ApiBase';

const authGroupsEndpoint = 'authgroups' as string;

class AuthGroupsApi extends ApiBase {
    getAuthGroups() : Promise<{authGroups: AuthGroup[]}> {
        return this.doHttpRequest<{authGroups: AuthGroup[]}>('get', `${authGroupsEndpoint}`, null, false);
    }
}

export default new AuthGroupsApi();