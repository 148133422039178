import ErtsApi from "../api/ErtsApi";
import { AsyncAction } from "./";
import * as types from '../types/actionTypes/ertType';
import { ErtsAction } from "../types/actionTypes/ertType";

export const getAllErts = (): AsyncAction<ErtsAction> => {
    return async (dispatch) => {
        var erts = await ErtsApi.getAllErts();
        erts.erts = erts.erts.filter(x => x.inDashboard).sort((a, b) => { return a.ert > b.ert ? 1 : -1 });

        var brands = [] as string[];
        erts.erts.map((x) => {
            var item = brands.find(y => y === x.brand);
            if (!item)
                brands.push(x.brand);
            return null;
        });

        var psms = [] as string[];
        erts.erts.map((x) => {
            var item = psms.find(y => y === x.ssss);
            if (!item && x.ssss != null)
                psms.push(x.ssss);
            return null;
        });

        return dispatch({type: types.SET_ERTS, erts: erts.erts, brands: brands, psms: psms });
    }
}