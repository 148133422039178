import { Value } from 'classnames';
import { GeneralInformation, MissedSalesInputValues, Product, ProductDropdownOption, TacticalParts, MissedSale } from '../types/model';

import ApiBase from './ApiBase';

const salesEndpoint = 'missedSales' as string;

class MissedSales extends ApiBase {
    getMissedSales(values : MissedSalesInputValues ): Promise<MissedSale[]> {
        var psms = values.psm?.map((x) => {
            return x.value;
        }); 
        var erts = values.ert?.map((x) => {
            return x.value;
        });
        var authGroups = values.authGroup?.map((x) => {
            return x.value;
        });
        
        return this.doHttpRequest<MissedSale[]>('get', `${salesEndpoint}/${erts}/${values.hitrate}/${psms}/${authGroups}/${values.inAssortiment}`, null, false);
    }
}

export default new MissedSales();