import { FunctionComponent } from "react";
import Loader from "react-loader-spinner";
import * as React from 'react'

interface Props {
  showText?: boolean;
}

export const LoadSpinnerComponent: FunctionComponent<Props> = (props) => {
  return (
    <div className="load-wrapper">
      <Loader type="Oval" color="#1C98CC" height={100} width={100} />
      {props.showText && (
        <div>Xlsx file is downloading, this can take a minute</div>
      )}
    </div>
  );
};
