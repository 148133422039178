import { Field } from 'redux-form';
import { FunctionComponent } from 'react';
import { TextAreaComponent } from './InputTypes/TextArea';
import * as React from 'react'

type CommentSapItemProps = {
    title: string;
    name: string
}

export const CommentSapItemComponent: FunctionComponent<CommentSapItemProps> = ({ title, name }) => {
    return (
        <div className="columns commentsap-item-container">
            <div className="column commentsap-item-title">
                {title}
            </div>
            <div className="column commentsap-textarea-container">
                <Field
                    component={TextAreaComponent}
                    name={name}
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
    );
}

