import * as accountTypes from '../types/actionTypes/accountType';

import { AccountInfo } from '@azure/msal-browser';
import { AsyncAction } from "./";
import { ProfileAction } from "../types/actionTypes/accountType";

export const setProfile = (profile?: AccountInfo): AsyncAction<ProfileAction> => {
    return async (dispatch) => {
        return dispatch({ type: accountTypes.SET_PROFILE, profile, role: '' });
    }
}

export const setRole = (role : string) => {
    return async (dispatch) => {
        return dispatch({ type: accountTypes.SET_ROLE, role });
    }
}

