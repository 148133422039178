import DatePickerComponent from '../../components/InputTypes/DatePicker';
import { DropdownComponent } from '../../components/InputTypes/Dropdown';
import { DropdownOption } from '../../types/model';
import { Field } from "redux-form"
import React from "react"
import { TextInputComponent } from '../../components/InputTypes/TextInput';
import { lang } from 'moment';

let booleanOptions = [
    { value: "true", label: "true" },
    { value: "false", label: "false" }
] as DropdownOption[];

export const CompetitorPriceEditColumns = (index: number, readOnly: boolean): JSX.Element => (
    <>
        <div className="columns">
            <div className="column">
                <Field
                    key={5}
                    component={TextInputComponent}
                    type="number"
                    readOnly={readOnly}
                    name={`competitorPrices[${index}].grossPrice`}
                    label="Gross €​ price/pce "
                    labelInComponent={true}
                />
            </div>
            <div className="column">
                <Field
                    key={6}
                    component={TextInputComponent}
                    type="number"
                    readOnly={readOnly}
                    name={`competitorPrices[${index}].nettPrice`}
                    label="Nett €​ price/pce "
                    labelInComponent={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={8}
                    component={readOnly ? TextInputComponent : DropdownComponent}
                    options={booleanOptions}
                    hasDefaultOption={true}
                    required={true}
                    readOnly={readOnly}
                    name={`competitorPrices[${index}].recon`}
                    label="Recon"
                    labelInComponent={true}
                />
            </div>
            <div className="column">
                <Field
                    key={9}
                    component={TextInputComponent}
                    required={true}
                    name={`competitorPrices[${index}].competitor`}
                    label="Competitor"
                    readOnly={readOnly}
                    labelInComponent={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={8}
                    component={DatePickerComponent}
                    required={true}
                    name={`competitorPrices[${index}].priceDate`}
                    label="Price date"
                    readOnly={readOnly}
                    labelInComponent={true}
                />
            </div>
        </div>
    </>
)

