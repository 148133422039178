import * as React from "react";

import {
  ProductsRoute,
  ProductsWithMaterialRoute,
  TacticalPartsRoute,
  PartsPerPsmRoute,
  MissedSalesRoute,
  DashboardExportRoute,
  NewPartRoute,
} from "../constants/routes";

import { AnyAction } from "redux";
import { BrowserRouter } from "react-router-dom";
import { ProductDashboardPage } from "../pages/ProductDashboard";
import { RootState } from "../types/state";
import RouteComponent from "../components/Route/RouteComponent";
import { Switch } from "react-router";
import { TacticalPartsPage } from "../pages/TacticalParts";
import { PartsPerPsmPage } from "../pages/PartsPerPsm";
import { MissedSalesPage } from "../pages/MissedSales";
import { DashboardExportPage } from "../pages/DashboardExport";
import { NewPartPage } from "../pages/NewPart";

import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { getGeneralInformation } from "../actions/generalActions";
import { getSeverityGroups } from "../actions/severityGroupsAction";
import { getAllErts } from "../actions/ertsActions";
import { getTacticalPartGroups } from "../actions/tacticalPartsActions";
import { getAuthGroups } from "../actions/authGroupsAction";

const mapStateToProps = ({ profile }: RootState) => ({
  profile: profile,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, AnyAction>
) => ({
  getGeneralInformation: () => dispatch(getGeneralInformation()),
  getAllErts: () => dispatch(getAllErts()),
  getSeverityGroups: () => dispatch(getSeverityGroups()),
  getTacticalPartGroups: () => dispatch(getTacticalPartGroups()),
  getAuthGroups: () => dispatch(getAuthGroups()),
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const AppContainer: React.FunctionComponent<Props> = ({
  getGeneralInformation,
  getSeverityGroups,
  getAllErts,
  getTacticalPartGroups,
  getAuthGroups,
}, props) => {
  React.useEffect(() => {
    getGeneralInformation();
    getSeverityGroups();
    getAllErts();
    getTacticalPartGroups();
    getAuthGroups();
  }, [props]);
  return (
    <>
      <BrowserRouter>
        <Switch>
          <RouteComponent
            showProductDropdown={true}
            exact={true}
            path={[`/`, ProductsRoute, ProductsWithMaterialRoute]}
            component={ProductDashboardPage}
          />
          <RouteComponent
            showProductDropdown={false}
            exact={true}
            path={[TacticalPartsRoute]}
            component={TacticalPartsPage}
          />
          <RouteComponent
            showProductDropdown={false}
            exact={true}
            path={[DashboardExportRoute]}
            component={DashboardExportPage}
          />
          <RouteComponent
            showProductDropdown={false}
            exact={true}
            path={[PartsPerPsmRoute]}
            component={PartsPerPsmPage}
          />
          <RouteComponent
            showProductDropdown={false}
            exact={true}
            path={[MissedSalesRoute]}
            component={MissedSalesPage}
          />
          <RouteComponent
            showProductDropdown={false}
            exact={true}
            path={[NewPartRoute]}
            component={NewPartPage}
          />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
