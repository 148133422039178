import { Route, RouteProps } from "react-router-dom";

import LoginContainer from '../../containers/LoginContainer'
import NavigationBarContainer from '../../containers/NavigationBarContainer';
import { RootState } from "../../types/state";
import { checkPermission } from '../../helpers/permissionHelper'
import { connect } from "react-redux";
import * as React from 'react'

interface Props {
    showProductDropdown: boolean;
}

const mapStateToProps = ({ profile }: RootState) => ({
    profile: profile,
});

const RouteComponent = ({ showProductDropdown, component, profile, ...rest }: RouteProps & Props & ReturnType<typeof mapStateToProps>) => {
    return (
        <>
            {profile ? <>
                <NavigationBarContainer showProductDropdown={showProductDropdown} />
                <Route {...rest} component={component} />
            </> :
            <LoginContainer/>}
        </>
    );
}

export default connect(mapStateToProps, {})(RouteComponent);


