import React, { FunctionComponent, useState } from "react";
import { Field, InjectedFormProps, isDirty, reduxForm } from "redux-form";

import { RootState } from "../types/state";
import { NewPartFormName } from "../constants/formNames";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ERTSComponent } from "../components/ERTSComponent";
import { TextInputComponent } from "../components/InputTypes/TextInput";
import { DropdownComponent } from "../components/InputTypes/Dropdown";
import { NoAccessContainer } from "./NoAccessContainer";
import { checkPermission } from "../helpers/permissionHelper";
import { Role, SubmitNewPartValues } from "../types/model";
import { toast } from "react-toastify";
import { submitNewPart } from "../actions/partsActions";
import { LoadSpinnerComponent } from "../components/LoadSpinnerComponent";

interface MatchParams {
  material: string;
}

const mapStateToProps = (state: RootState) => {
  return {
    profile: state.profile,
    isDirty: isDirty(NewPartFormName)(state),
    allErts: state.erts?.erts,
    tacticalPartGroup: state.tacticalPartGroups?.tacticalPartGroups
  };
};

type Props = ReturnType<typeof mapStateToProps> & RouteComponentProps<MatchParams> & InjectedFormProps<{}, any>;

const NewPartContainer: FunctionComponent<Props> = ({ profile, isDirty, handleSubmit, reset, allErts, tacticalPartGroup}) => {
  var [isLoading, setIsLoading] = useState<boolean>(false);

  const erts = allErts ? allErts.map((e) => {
    return { label: e.ert, value: e.ert };
  }) : [];

  const groups = tacticalPartGroup ? tacticalPartGroup.map((g) => {
    return { label: g.group + " | " + g.description, value: g.group };
  }) : [];

  const submitNewPartForm = async (submitValues: SubmitNewPartValues) => {
    setIsLoading(true);

    await submitNewPart(submitValues)
      .then(() => {
        reset();
        toast.success("New part is saved, form cleared for new input.");
      })
      .catch((e) => {
        toast.error("An error has occured. Please try again.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return checkPermission(profile, Role.Admin) ? (
    <div className="main-container pt5">
      <div className="column main-data-column">
        <span style={{ backgroundColor: "#000000" }}>New part fill in form</span>
        {isLoading ? (
          <LoadSpinnerComponent />
        ) : (
          <form
            onSubmit={handleSubmit((e: any) => {
              submitNewPartForm(e);
            })}
          >
            <div className="columns">
              <div className="column is-5">
                <Field key={1} component={TextInputComponent} name="material" label="Material" labelInComponent={true} />
              </div>
            </div>
            <div className="columns">
              <div className="column is-5">
                <Field options={erts} name="ert" component={DropdownComponent} labelInComponent={true} label="ERT" />
              </div>
              <div className="column is-5">
                <Field options={groups} name="group" component={DropdownComponent} labelInComponent={true} label="Group" />
              </div>
            </div>
            <ERTSComponent erts={allErts}/>
            {isDirty && (
              <div
                className="unsaved-changes-wrapper"
                style={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  <span style={{ marginRight: "10px" }}>Save your data here</span>
                  <button className="save-button" disabled={isLoading}>
                    Save changes
                  </button>
                </div>
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  ) : (
    <NoAccessContainer />
  );
};

const NewPartReduxForm = reduxForm<{}, any>({
  form: NewPartFormName,
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
})(NewPartContainer);

export default withRouter(connect(mapStateToProps)(NewPartReduxForm));
