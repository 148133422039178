import { TacticalParts, TacticalPartsInputValues, TacticalPartGroup } from '../types/model';

import ApiBase from './ApiBase';

const tacticalPartsEndpoint = 'tacticalParts' as string;
const tacticalPartGroupsEndpoint = 'tacticalPartGroups' as string;

class TacticalPartsApi extends ApiBase {
    getTacticalParts(values : TacticalPartsInputValues ): Promise<TacticalParts[]> {
        var groups = values.group?.map((x) => {
            return x.value;
        }); 
        var erts = values.ert?.map((x) => {
            if (x.value)
                return x.value;
            return x.label;
        });

        return this.doHttpRequest<TacticalParts[]>('get', `${tacticalPartsEndpoint}/${groups}/${values.inAssortiment}`, {erts: erts}, false);
    }

    getTacticalPartGroups() : Promise<{groups: TacticalPartGroup[]}> {
        return this.doHttpRequest<{groups: TacticalPartGroup[]}>('get', `${tacticalPartGroupsEndpoint}`, null, false);
    }
}

export default new TacticalPartsApi();