import * as types from '../types/actionTypes/filterSettingType';

const filterSettings = [] as {name: string, value: string}[]
const initialState = { 
    filterSettingsPartsPerPSM: filterSettings, 
    filterSettingsTacticalParts: filterSettings,
    filterSettingsMissedSales: filterSettings, 
}

type State = typeof initialState

export const setFilterSettingsReducer = (
    state: State = initialState,
    action: types.SetFilterSettingAction
) => {
    if (action.type === types.SET_FILTER_SETTING_PARTS_PER_PSM)
        return { ...state, filterSettingsPartsPerPSM: action.filterSettings};
    if (action.type === types.SET_FILTER_SETTING_TACTICAL_PARTS)
        return { ...state, filterSettingsTacticalParts: action.filterSettings};
    if (action.type === types.SET_FILTER_SETTING_MISSED_SALES)
        return { ...state, filterSettingsMissedSales: action.filterSettings};


    return state;
};