import * as types from '../types/actionTypes/ertType';
import { Ert } from '../types/model';

const erts = null as Ert | null
const initialState = erts

type State = typeof initialState

export const getAllErts = (
    state: State = initialState,
    action: types.ErtsAction
) => {
    if (action.type === types.SET_ERTS)
        return { ...state, erts: action.erts, brands: action.brands, psms: action.psms};

    return state;
};