import * as generalTypes from '../types/actionTypes/generalInformationType';

import { GeneralInformationAction, TabAction } from "../types/actionTypes/generalInformationType";

import { AsyncAction } from "./";
import GeneralInformationApi from '../api/GeneralInformationApi';

export const getGeneralInformation = (): AsyncAction<GeneralInformationAction> => {
    return async (dispatch) => {
        var generalInformation = await GeneralInformationApi.getInformation();
        return dispatch({ type: generalTypes.SET_GENERAL_INFORMATION, generalInformation });
    }
}

export const setSelectedTab = (index: number): AsyncAction<TabAction> => {
    return async (dispatch) => {
        return dispatch({ type: generalTypes.SET_SELECTED_TAB, tabIndex: index });
    }
}
