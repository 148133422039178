import ApiBase from './ApiBase';
import { Document } from '../types/model';

const documentEndpoint = 'documents' as string;

class DocumentApi extends ApiBase {
    getDocuments(material : string): Promise<Document> {
        return this.doHttpRequest<Document>('get', `${documentEndpoint}/${material}`, null, false);
    }
}

export default new DocumentApi();