import { AsyncAction } from ".";
import * as actionTypes from "../types/actionTypes/findValuesType";
import { SetPartsPerPSMFindValues, SetTacticalPartsFindValues, SetMissedSalesFindValues } from "../types/actionTypes/findValuesType";


export const setPartsPerPSMFindValues = (psm: string, brand: string, ert: string): AsyncAction<SetPartsPerPSMFindValues> => {
    return async (dispatch) => {
        return dispatch({ type: actionTypes.SET_PARTSPERPSM_FIND_VALUES, psm, brand, ert });
    };
}

export const setTacticalPartsFindValues = (group: string, ert: string, inAssortiment: string): AsyncAction<SetTacticalPartsFindValues> => {
    return async (dispatch) => {
        return dispatch({ type: actionTypes.SET_TACTICAL_PARTS_FIND_VALUES, group, ert, inAssortiment });
    };
}

export const setMissedSalesFindValues = (ert: string, hitrate: string, psm: string, authGroup: string, inAssortiment: string): AsyncAction<SetMissedSalesFindValues> => {
    return async (dispatch) => {
        return dispatch({ type: actionTypes.SET_MISSED_SALES_FIND_VALUES, ert, hitrate, psm, authGroup, inAssortiment });
    };
}