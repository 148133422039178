import { Field } from "redux-form"
import React from "react"
import { TextInputComponent } from '../../components/InputTypes/TextInput';

export const EngineTypeEditColumns = (index: number, readOnly: boolean): JSX.Element => (
    <>
        <div className="columns">
            <div className="column">
                <Field
                    key={1}
                    component={TextInputComponent}
                    name={`mainData.partEngineTypes[${index}].productType`}
                    label="Product type"
                    readOnly={readOnly}
                    labelInComponent={true}
                />
            </div>
            <div className="column">
                <Field
                    key={2}
                    component={TextInputComponent}
                    type="number"
                    name={`mainData.partEngineTypes[${index}].qtyEngines`}
                    label="Qty engines"
                    readOnly={readOnly}
                    labelInComponent={true}
                />
            </div>
        </div>
    </>
)

