import { Product, ProductDropdownOption } from '../../types/model';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import Select, { OptionProps, components, InputActionMeta } from 'react-select';
import { destroy, reset } from 'redux-form';
import { faChevronLeft, faChevronRight, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons'
import { getProduct, getProductMainData, getProducts } from '../../actions/productActions'

import { AnyAction } from "redux";
import AsyncSelect from 'react-select/async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ProductApi from '../../api/ProductDashboardApi';
import { ProductDashboardFormName } from '../../constants/formNames';
import { ProductDropdownStyle } from '../../constants/styles';
import { RootState } from '../../types/state';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { setSelectedTab } from '../../actions/generalActions';

type ProductDropdownProps = {
    products: Product[];
    product: Product;
    getProduct: (material: string) => void;
    getProductMainData: (material: string) => void;
    getProducts: (material: string) => void;
    setSelectedTab: (index: number) => void;
    resetForm: () => void;
}

interface MatchParams {
    material: string
}

const ProductDropdown: FunctionComponent<ProductDropdownProps & RouteComponentProps<MatchParams>> = ({ products, product, getProduct, getProducts, getProductMainData, resetForm, history, match, setSelectedTab }) => {
    const initialValue =
        [] as { product: Product, value: string, label: string }[];

    var [defaultOptions, setOptions] = React.useState(initialValue);
    var [searchText, setSearchText] = React.useState("")
    var [isLoading, setIsLoading] = React.useState(false);
    var [isMenuOpen, setMenuOpen] = useState(false);
    var [selectedProduct, setSelectedProduct] = React.useState("")

    const Option = (options: OptionProps<any, any, any>) => {
        var product = options.data.product as Product

        return (
            <components.Option  {...options}>
                <div className="columns">
                    <div className="column">
                        <span className="dropdownHeaderTitle">{product?.material}</span> - <span className="dropdownHeaderDescription">{product?.materialName}</span>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-3">
                        <div className="columns is-flex-direction-column">
                            <div className="column pb-0 dropdownSubHeader">
                                ERT
                            </div>
                            <div className="column pt-0 dropdownSubValue">
                                {product?.ert}
                            </div>
                        </div>
                    </div>
                    <div className="column is-4">
                        <div className="columns is-flex-direction-column">
                            <div className="column pb-0 dropdownSubHeader">
                                Material Status
                            </div>
                            <div className="column pt-0 dropdownSubValue">
                                {product?.materialStatus}
                            </div>
                        </div>
                    </div>
                    <div className="column is 5">
                        <div className="columns is-flex-direction-column">
                            <div className="column pb-0 dropdownSubHeader">
                                ATP
                            </div>
                            <div className="column pt-0 dropdownSubValue">
                                {product?.atp}
                            </div>
                        </div>
                    </div>
                </div>
            </components.Option >
        )
    };

    const onChange = (event: ProductDropdownOption) => {
        if (event.value !== selectedProduct) {
            history.push({
                pathname: event ? `/Products/${event.value}` : '/Products'
            });
            if (event?.value) {
                getProductMainData(event.value);
                getProduct(event.value);
                setSelectedTab(0);
            }
            resetForm();
            setMenuOpen(false);
            setSelectedProduct(event.value);
        }
    }

    const navigate = (isNext: boolean) => {
        var index = defaultOptions?.findIndex((o) => o.value === product.material);
        if (index >= 0) {
            index = isNext ? ++index : --index;
            if (index >= defaultOptions.length)
                index = 0;
            if (index < 0)
                index = defaultOptions.length - 1;
            var newProduct = defaultOptions[index];

            history.push({
                pathname: `/Products/${newProduct.value}`
            });
            setSelectedTab(0);
            getProductMainData(newProduct.value);
            getProduct(newProduct.value);
        }
    }

    const loadItems = () => {
        var products = ProductApi.getProducts(searchText).then(e => e.map(p => { return { value: p.material, product: p, label: searchText } }));
        products.then(e => { setOptions(e) });
        return products;
    }

    const onKeyDown = (key: string) => {
        if (key === "Enter" || key === "Search") {
            if (searchText !== "") {
                setIsLoading(true);
                loadItems().then(e => {
                    setIsLoading(false);
                    setMenuOpen(true);
                });
            }
        }
    }

    const clearResults = () => {
        setOptions(initialValue)
        setSearchText("");
    }

    const onInputChange = (searchString: string, action: InputActionMeta) => {
        if (action.action === "input-change") {
                setSearchText(searchString)
            }
    }

    const filterOption = (option, input) => {
        return option.label.toLowerCase().replace(/ /g, '').replace(' ', '').includes(input.toLowerCase().replace(/ /g, '').replace(' ', ''));
    };

    return (
        <div className="product-container is-flex" style={{ width: '100%' }}>
            <button className="product-navigation-button" disabled={searchText.length < 1} onClick={() => clearResults()} >
                <FontAwesomeIcon color="black" icon={faTimes} />
            </button>
            <Select
                id="materialSearch"
                menuPosition={'fixed'}
                key={1}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                // Fix for pasting with mouse
                input: (cssProps) => ({ 
                    ...cssProps,
                    width: "100% !important",
                    
                    "> div": {
                      width: "100%"
                     },
                  
                    input: {
                      width: "100% !important",
                      textAlign: "left",
                      opacity: "1 !important"
                    }
                  }),
                  placeholder: (cssProps) => ({
                    ...cssProps,
                    pointerEvents: "none"
                })
            }}
                menuIsOpen={isMenuOpen}
                onMenuClose={() => setMenuOpen(false)}
                onMenuOpen={() => setMenuOpen(true)}
                cacheOptions
                isSearchable={true}
                filterOption={filterOption}
                isLoading={isLoading}
                defaultOptions={defaultOptions}
                onKeyDown={(event) => onKeyDown(event.key)}
                options={defaultOptions}
                onInputChange={(e, action) => { onInputChange(e, action) }}
                onChange={(e: ProductDropdownOption) => onChange(e)}
                placeholder={"Find material..."}
                inputValue={searchText}
                value={searchText}
                components={{ Option }}                
            />
            <button className="product-navigation-button" disabled={searchText.length < 1} onClick={() => { onKeyDown("Search");  }} >
                <FontAwesomeIcon color="black" icon={faSearch} />
            </button>
            <div className="is-flex product-navigation-button-container" >
                <button className="product-navigation-button" disabled={!product} onClick={() => navigate(false)}>
                    <FontAwesomeIcon color="black" icon={faChevronLeft} />
                </button>
                <button className="product-navigation-button" disabled={!product} onClick={() => navigate(true)}>
                    <FontAwesomeIcon color="black" icon={faChevronRight} />
                </button>
            </div>
        </div>
    )
}

const mapStateToProps = ({ products, product }: RootState) => ({
    products: products,
    product: product
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>) => ({
    getProduct: (material: string) => dispatch(getProduct(material)),
    getProductMainData: (material: string) => dispatch(getProductMainData(material)),
    getProducts: (material: string) => dispatch(getProducts(material)),
    resetForm: () => dispatch(destroy(ProductDashboardFormName)),
    setSelectedTab: (tab: number) => dispatch(setSelectedTab(tab)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductDropdown))



