import { SubmitNewPartValues } from "./../types/model";
import { PartsPerPsm, PartsPerPsmFormValues } from "../types/model";

import ApiBase from "./ApiBase";

const partsEndpoint = "parts" as string;

class Parts extends ApiBase {
  getPartsPerPsm(values: PartsPerPsmFormValues): Promise<PartsPerPsm[]> {
    var psms = values.psm?.map((x) => {
      return x.value;
    }); 
    var erts = values.ert?.map((x) => {
        return x.value;
    });
    var brands = values.brand?.map((x) => {
        return x.value;
    });
    return this.doHttpRequest<PartsPerPsm[]>("get", `${partsEndpoint}/${psms}/${brands}`, {ert: erts}, false);
  }

  getPartsPerPsmCsvData(values: PartsPerPsmFormValues): Promise<any> {
    var psms = values.psm?.map((x) => {
      return x.value;
    }); 
    var erts = values.ert?.map((x) => {
        return x.value;
    });
    var brands = values.brand?.map((x) => {
        return x.value;
    });
    return this.doHttpRequest<any>("get", `${partsEndpoint}/csv/${psms}/${erts}/${brands}`, null, false);
  }

  submitNewPart(submitValues: SubmitNewPartValues): Promise<void> {
    return this.doHttpRequest<void>('post', partsEndpoint, submitValues)
  }
}

export default new Parts();
