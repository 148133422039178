import { Action } from "redux";
import { Ert } from "../model";

export const SET_ERTS = "SET_ERTS";


export interface ErtsAction extends Action<string> {
    erts: Ert[];
    brands: string[];
    psms: string[];
}