import * as types from '../types/actionTypes/severityGroupsType';
import { SeverityGroup } from '../types/model';

const severityGroups = null as SeverityGroup[] | null
const initialState = severityGroups

type State = typeof initialState

export const severityGroupsReducer = (
    state: State = initialState,
    action: types.SeverityGroupsAction
) => {
    if (action.type === types.GET_SEVERITY_GROUPS)
        return action.severityGroups;

    return state;
};