import { Product, ProductDropdownOption, SubmitProductDashboardValues } from '../types/model';

import ApiBase from './ApiBase';

const productEndpoint = 'productDashboards' as string;
const productMainDataEndpoint = 'productDashboardsMainData' as string;
const productsSearchEndpoint = 'productsSearch' as string;

class ProductDashboardApi extends ApiBase {
    getProducts(material: string): Promise<Product[]> {
        return this.doHttpRequest<Product[]>('get', `${productsSearchEndpoint}/${material}`, null, false);
    }
    getProduct(material: string): Promise<Product> {
        return this.doHttpRequest<Product>('get', `${productEndpoint}/${material}`, null, true);
    }
    getProductMainData(material: string): Promise<Product> {
        return this.doHttpRequest<Product>('get', `${productMainDataEndpoint}/${material}`, null, true);
    }
    updateProduct(submitValues: SubmitProductDashboardValues): Promise<void> {
        return this.doHttpRequest<void>('put', `${productEndpoint}`, submitValues)
    }
}

export default new ProductDashboardApi();