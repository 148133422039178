import * as types from '../types/actionTypes/accountType';

import { AccountInfo } from '@azure/msal-browser';
import { UserInfo } from '../types/model';

const initialProfileState = null as AccountInfo | null;

type ProfileState = typeof initialProfileState;

export const profileReducer = (
    state: ProfileState = initialProfileState,
    action: types.ProfileAction
) => {
    if (action.type === types.SET_PROFILE)
        return action.profile;
    if (action.type === types.SET_ROLE) {
        var profile = state as UserInfo;

        if (profile?.idTokenClaims) {
            profile.idTokenClaims!.roles = [action.role]
        }
        return profile;
    }
    return state;
};
