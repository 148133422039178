import { AsyncAction } from ".";
import { AuthGroupsAction } from "../types/actionTypes/authGroupsType";
import * as types from '../types/actionTypes/authGroupsType';
import AuthGroupsApi from "../api/AuthGroupsApi";

export const getAuthGroups = (): AsyncAction<AuthGroupsAction> => {
    return async (dispatch) => {
        var authGroups = await AuthGroupsApi.getAuthGroups();
        authGroups.authGroups = authGroups.authGroups.filter(x => x.authGroup !== ' ');
        return dispatch({type: types.SET_AUTH_GROUPS, authGroups: authGroups.authGroups });
    }
}