import React from "react";
import { WrappedFieldProps } from "redux-form";
import classnames from "classnames";

interface Props {
  label?: string;
  readOnly?: boolean;
  hidden?: boolean;
  onValueChanged?: (value: any) => void;
  onClick?: (value: any) => void;
  customWrapperClass?: string;
  customClassLabel?: string;
}

interface CheckboxState {
  isChecked: boolean;
}

export type CheckboxProps = Props & WrappedFieldProps;

export class Checkbox extends React.Component<CheckboxProps, CheckboxState> {
  constructor(props: CheckboxProps) {
    super(props);

    this.state = { isChecked: props.input.value };
  }

  componentWillReceiveProps(nextProps: Readonly<CheckboxProps>) {
    this.setState({ isChecked: nextProps.input.value });
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.readOnly) {
      return;
    }

    const newValue = !this.state.isChecked;

    if (this.props.onClick) {
      this.props.onClick(newValue);
    }

    this.setState({ isChecked: newValue });

    event.target.focus();
    event.target.blur();
  };

  render() {
    const {
      input,
      label,
      readOnly,
      meta: { touched, error },
      customWrapperClass,
      customClassLabel,
      hidden,
    } = this.props;

    let wrapperClass = "field column is-paddingless is-horizontal is-marginless is-full-mobile is-full-tablet";

    const labelComponent = label && (
      <label className={classnames(`label`, customClassLabel)} htmlFor={label}>
        {label}
      </label>
    );

    return (
      !hidden && (
        <div className={customWrapperClass ? customWrapperClass : wrapperClass}>
          <div className="field-body mt-2">
            <div className="checkbox">
              <input
                {...input}
                type="checkbox"
                style={{ cursor: readOnly ? "not-allowed" : "pointer" }}
                hidden={hidden}
                onClick={() => this.props.onClick}
                onChange={(event) => this.onChange(event)}
                checked={this.state.isChecked}
                id={label}
              />
              {label && labelComponent}
            </div>
          </div>
        </div>
      )
    );
  }
}
