import { DashboardExportInputValues } from './../types/model';
import { DashboardExport } from '../types/model';

import ApiBase from './ApiBase';

class DashboardExportApi extends ApiBase {
    getDashboardExport(values: DashboardExportInputValues[]): Promise<DashboardExport[]> {
        return this.doHttpRequest<DashboardExport[]>('post', 'dashboardexports', values, false);
    }

    getDashboardExportxlsx(values: File): Promise<DashboardExport[]> {
        return this.doHttpRequest<DashboardExport[]>('post', 'dashboardexportsxlsx', values, false);
    }
}

export default new DashboardExportApi();