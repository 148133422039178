import moment from "moment";

export const showDate = (date?: Date | string): string => {
  var newDate = moment(date);

  if (!newDate.isValid()) return "";

  return newDate.format("DD-MM-YYYY"); //${newDate.date()}-${newDate.month() + 1}-${newDate.year()}`;
};

export const showMoney = (value: string, isCsvExport?: boolean): string => {
  if (value === null) return "";
  var intValue = parseFloat(value);

  if (isCsvExport) {
    return intValue.toString();
  }
  var newValue = intValue
    .toLocaleString("en", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .replaceAll(".", "*")
    .replaceAll(",", "&");

  return `${!isCsvExport ? "€" : ""}${newValue
    .replaceAll("*", ",")
    .replaceAll("&", ".")}`;
};

export const showPercentage = (
  value: string,
  decimals: number,
  isCsvExport?: boolean
): string => {
  if (value === null) return `0${!isCsvExport ? "%" : ""}`;
  var intValue = parseFloat(value);
  if (isCsvExport) {
    intValue /= 100;
  }
  value = intValue.toFixed(decimals).replace(".", ",");
  return `${value}${!isCsvExport ? "%" : ""}`;
};

export const showDecimal = (
  value: string,
  decimals: number,
  language: string
) => {
  const decimalValue = parseFloat(value);

  return decimalValue;
};

export const showDecimalCsv = (
  value: number,
  language: string,
  decimals: number
) => {
  return value ? value.toFixed(decimals) : "";
};
