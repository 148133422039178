import React, { FunctionComponent } from "react";

export const NoAccessContainer: FunctionComponent = () => {
  return (
    <div>
      <h1 style={{ textAlign: "center", fontSize: "25px", marginTop: "100px" }}>
        No Access
      </h1>
    </div>
  );
};
