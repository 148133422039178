import * as types from '../types/actionTypes/generalInformationType';

import { GeneralInformation } from '../types/model';

const initialGeneralInformationState = null as GeneralInformation | null;
const initialSelectedTabState = 0 as number;


type GeneralInformationState = typeof initialGeneralInformationState;
type TabIndexState = typeof initialSelectedTabState;

export const generalReducer = (
    state: GeneralInformationState = initialGeneralInformationState,
    action: types.GeneralInformationAction
) => {
    if (action.type === types.SET_GENERAL_INFORMATION) {
        var temp = action.generalInformation;
        temp.erts.sort((a, b) => a > b ? 1 : -1)
        return action.generalInformation;
    }

    return state;
};


export const tabIndexReducer = (
    state: TabIndexState = initialSelectedTabState,
    action: types.TabAction
) => {
    if (action.type === types.SET_SELECTED_TAB)
        return action.tabIndex;

    return state;
};
