import ApiBase from './ApiBase';
import { SeverityGroup } from '../types/model';

const severityGroupsEndpoint = 'severitygroups' as string;

class SeverityGroupsApi extends ApiBase {
    getSeverityGroups(): Promise<SeverityGroup[]> {
        return this.doHttpRequest<SeverityGroup[]>('get', `${severityGroupsEndpoint}`, null, false);
    }
}

export default new SeverityGroupsApi();