import axios, { AxiosRequestConfig, Method } from "axios";

import { CustomWindow } from "../types";

type Header = Record<string, string>;

const customWindow = window as CustomWindow;

class ApiBase {
  public async doHttpRequest<TResponse>(
    method: string,
    endpoint: string,
    data: {} | PromiseLike<{}> | null = null,
    isSingleItem?: boolean
  ): Promise<TResponse> {
    const headers: Header = {};
    headers["Content-Type"] = "application/json";
    headers["X-ProductDashboard-Correlation-ID"] = Math.floor(Math.random() * 1000000).toString();
    headers["x-functions-key"] = localStorage.getItem("hostKey") ?? "";
    var baseUrl = localStorage.getItem("baseUrl");
    const url = `${baseUrl}/api/${endpoint}`;

    const config: AxiosRequestConfig = {
      method: method as Method,
      url,
      headers,
      data,
    };
    if (method === "get") {
      config.params = data;
    }
    const response = await axios.request<TResponse>(config);

    return isSingleItem ? response.data[0] : response.data;
  }
}

export default ApiBase;
