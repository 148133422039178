import { Consumption, Costs, HitrateDetails, OtherPartsReferences, ProductHierarchy, SourcingDetails, Stock, UsedInEngineType, Successor } from '../constants/columns/MainData';
import React, { FunctionComponent } from 'react';
import { change, formValues, getFormValues } from 'redux-form'

import { AccountInfo } from '@azure/msal-browser';
import { MainDataColumnComponent } from './MainDataColumnComponent'
import { ProductDashboardFormName } from '../constants/formNames'
import { Role, SeverityGroup } from '../types/model';
import { RootState } from '../types/state';
import { check } from 'yargs';
import { checkPermission } from '../helpers/permissionHelper'
import { connect } from 'react-redux';

interface MainDataProps {
    onClick : (index: number) => void;
    profile?: AccountInfo | undefined;
    severityGroups?: SeverityGroup[];
    onFieldChange: (form: string, field: string, value: string) => any;
}

const mapStateToProps = (state: RootState) => ({
    formValues: getFormValues(ProductDashboardFormName)(state) as any,
    product: state.product,
    profile: state.profile,
    severityGroups: state.severityGroups?.severityGroups
});

type Props = ReturnType<typeof mapStateToProps> & MainDataProps;

const MainDataComponent: FunctionComponent<Props> = ({ product, formValues, onClick, profile, severityGroups, onFieldChange }) => {
    var mainData = formValues?.mainData;

    return (
        <div className="columns">
            <div className="column is-5">
                <div className="columns">
                    <MainDataColumnComponent content={Stock(profile)} backgroundColorHeader={"#000000"} headerTitle="STOCK" />
                    <MainDataColumnComponent content={Consumption(profile)} backgroundColorHeader={"#3AC6FA"} headerTitle="CONSUMPTION - INCL.PREDCESSORS" />
                </div>
                <div className="columns">
                    <MainDataColumnComponent content={Costs(profile)} backgroundColorHeader={"#DB0800"} headerTitle="COSTS / PRICING" />
                </div>
                <div className="columns">
                    <MainDataColumnComponent content={HitrateDetails(profile)} backgroundColorHeader={"#8E00C3"} headerTitle="HITRATE DETAILS (LAST 365 DAYS)" />
                </div>
            </div>
            <div className="column is-4">
                <div className="columns">
                    <MainDataColumnComponent content={ProductHierarchy(profile)} backgroundColorHeader={"#484848"} headerTitle="PRODUCT HIERARCHY / MATERIAL GROUP" />
                </div>
                <div className="columns">
                    <MainDataColumnComponent content={<SourcingDetails mainData={mainData} profile={profile} />} backgroundColorHeader={"#00A81A"} headerTitle="SOURCING DETAILS" />
                </div>
            </div>
            <div className="column is-3">
                <div className="columns">
                    <MainDataColumnComponent content={Successor(profile, mainData)} backgroundColorHeader={"#4C6900"} headerTitle="SUCCESSOR" />
                </div>
                <div className="columns">
                    <MainDataColumnComponent content={UsedInEngineType(product?.mainData?.partEngineTypes, '#FF9D00', onClick, profile)} backgroundColorHeader={"#FF9D00"} headerTitle="USED IN ENGINE TYPE" />
                </div>
                <div className="columns">
                    <MainDataColumnComponent content={OtherPartsReferences(profile)} backgroundColorHeader={"#999999"} headerTitle="OTHER PART REFERENCES" />
                </div>
            </div>
        </div>
    );
}

export default connect(mapStateToProps, {})(MainDataComponent)