import {
  Ert,
  DropdownOption,
  PartsPerPsm,
  PartsPerPsmFormValues,
  Role,
} from "../types/model";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import GridviewComponent from "../components/Gridview/GridviewComponent";
import { RootState } from "../types/state";
import {
  PartsPerPsmColumns,
} from "../components/Gridview/tableColumns";
import { PartsPerPageFormName } from "../constants/formNames";
import { connect } from "react-redux";
import { getPartsPerPsm, getPartsPerPsmCsvData } from "../actions/partsActions";
import { checkPermission } from "../helpers/permissionHelper";
import { NoAccessContainer } from "../containers/NoAccessContainer";
import { DropdownComponent } from "../components/InputTypes/Dropdown";
import { setFilterSettings } from "../actions/filterSettingsAction";
import { setPartsPerPSMFindValues } from "../actions/findValuesAction";

interface MatchParams {
  material: string;
}

const mapStateToProps = (state: RootState): any => {
  return {
    profile: state.profile,
    initialValues: {
      psm: "",
      brand: "",
      ert: "",
    } as any,
    filterSettings: state.setFilters.filterSettingsPartsPerPSM ? state.setFilters.filterSettingsPartsPerPSM : [] as DropdownOption[],
    findValues: state.setPartsPerPSMFindValues,
    allErts: state.erts?.erts as Ert[],
    psms: state.erts?.psms as string[],
    brands: state.erts?.brands as string[],
  };
};

const mapDispatchToProps = (dispatch: any): any => ({
  getPartsPerPsm: (values: PartsPerPsmFormValues) =>
    dispatch(getPartsPerPsm(values)),
  getPartsPerPsmCsvData: (values: PartsPerPsmFormValues) =>
    dispatch(getPartsPerPsmCsvData(values)),
  setFilterSettings:(filterSettings: {name:string, value:string}[]) => dispatch(setFilterSettings(filterSettings, "PartsPerPSM")),
  setPartsPerPSMValues: (psm: string, brand: string, ert: string) => dispatch(setPartsPerPSMFindValues(psm, brand, ert)),
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<MatchParams> &
  InjectedFormProps<{}, any>;

const PartsPerPsmContainer: React.FunctionComponent<Props> = (props) => {
  var [formValues, setFormValues] = useState<PartsPerPsmFormValues | null>(
    null
  );
  var [partsPerPsm, setPartsPerPsm] = useState<PartsPerPsm[]>([]);
  var [isLoading, setIsLoading] = useState<boolean>(false);
  var [brandsDropdown, setBrandsDropdown] = useState<DropdownOption[]>([]);
  var [ertsDropdown, setErtsDropdown] = useState<DropdownOption[]>([]);

  var erts: DropdownOption[] = [];
  erts = props.allErts ?
    props.allErts.map(e => { return { label: e.ert, value: e.ert } as DropdownOption })
    : [];
  if (ertsDropdown.length > 0)
    erts = ertsDropdown;

  var brands: DropdownOption[] = [];
  brands = props.brands ?
    props.brands.map(e => { return { label: e, value: e } as DropdownOption })
    : [];
  if (brandsDropdown.length > 0)
    brands = brandsDropdown;

  var psms: DropdownOption[] = [];
  psms = props.psms ?
    props.psms.map(e => { return { label: e, value: e } as DropdownOption })
    : [];

  const sumbitPartsPerPsm = async (values: PartsPerPsmFormValues) => {
    setFormValues(values);
    setIsLoading(true);
    await getPartsPerPsm(values).then(async (partsPerPsm) => {
      props.setPartsPerPSMValues(values.psm, values.brand, values.ert);
      setPartsPerPsm(partsPerPsm);
      setIsLoading(false);
    });
  };

  const onFilterChanged = (field: string, value: string) => {
    var settings = props.filterSettings;

    var filter = settings.find((x) => x.field === field);

    if (!filter)
      settings = [...settings, {field: field, value: value}]
    else
      settings[settings.indexOf(filter)].value = value;

    props.setFilterSettings(settings);
  } 

  const getCsvData = async () => {
    const columns = PartsPerPsmColumns();

    var headers = columns.map((c, x) => {
      return c.Header;
    });

    if (formValues) {
      setIsLoading(true);
      await getPartsPerPsmCsvData(formValues).then(async (partsPerPsm) => {
        var link = window.document.createElement("a");
        link.setAttribute(
          "href",
          `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${partsPerPsm}`
        );
        link.setAttribute("download", "download.xlsx");
        link.click();

        setIsLoading(false);
      });
    }
  };
  
  const visiblePsms = useMemo(
    () => <GridviewComponent
            columns={PartsPerPsmColumns()}
            isTacticalParts={true}
            data={partsPerPsm}
            isLoading={isLoading}
            getCsvData={getCsvData}
            onFilterChanged={onFilterChanged}
            defaultFilter={props.filterSettings}
    />,
    [isLoading]
  );

  const setPreviousState = () => {
    sumbitPartsPerPsm(props.findValues);
    props.change('psm', props.findValues.psm);
    props.change('brand', props.findValues.brand);
    props.change('ert', props.findValues.ert);
  }

  const onPsmChange = (val) => {
    var brandsstring = [] as string[] 
    props.allErts.forEach((x) => {
      var find = val.find((y) => y.label === x.ssss);
      if (find && brandsstring.indexOf(x.brand) === -1) {
        brandsstring = [...brandsstring, x.brand];
      }
    });

    var brandsMapped = brandsstring.map((x) => { return {label: x, value: x} })
    setBrandsDropdown(brandsMapped);
    props.change("brand", []);
    props.change("ert", []);
  }

  const onBrandsChange = (val) => {
    var ertsString = [] as string[] 
    var findArray = val;
    if (val.find((x) => x.value === '*')) {
      findArray = brands;
    }
    props.allErts.forEach((x) => {
      var find = findArray.find((y) => y.label === x.brand);
      if (find && ertsString.indexOf(x.brand) === -1) {
        ertsString = [...ertsString, x.ert];
      }
    });

    var ertsMapped = ertsString.map((x) => { return {label: x, value: x} })
    setErtsDropdown(ertsMapped);
    props.change("ert", []);
  }

  useEffect(() => {
    if (props.findValues.psm !== "") {
      setPreviousState();
    }
  }, [])

  return checkPermission(props.profile, Role.DashboardExportUser) ? (
    <>
      <form
        className="tactical-parts-form"
        onSubmit={props.handleSubmit((e: any) => {
          sumbitPartsPerPsm(e);
        })}
      >
        <div className="columns tactical-parts-header">
          <div className="column">
            <div className="columns">
              <div className="column">
                <Field
                  key={1}
                  options={psms}
                  component={DropdownComponent}
                  name="psm"
                  required={true}
                  label="PSM:"
                  labelInComponent={true}
                  isMulti={true}
                  onChange={onPsmChange}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="columns">
              <div className="column">
                <Field
                  key={2}
                  options={brands}
                  component={DropdownComponent}
                  name="brand"
                  required={true}
                  label="Brand:"
                  labelInComponent={true}
                  isMulti={true}
                  onChange={onBrandsChange}
                />
              </div>
              <div className="column description pt-0">eg Sulzer or all</div>
            </div>
          </div>
          <div className="column">
            <div className="columns">
              <div className="column">
                <Field
                  key={2}
                  options={erts}
                  component={DropdownComponent}
                  name="ert"
                  required={true}
                  label="ERT:"
                  labelInComponent={true}
                  isMulti={true}
                />
              </div>
              <div className="column description pt-0">eg S20 or all</div>
            </div>
          </div>
          <div className="column">
            <div className="columns">
              <div className="column">
                <button className="tactical-parts-button">Find Parts</button>
              </div>
              <div className="column description pt-0"></div>
            </div>
          </div>
        </div>
      </form>
      <div className="main-container tactical-parts-container mt-5">
        {visiblePsms}
      </div>
    </>
  ) : (
    <NoAccessContainer />
  );
};

const PartsPerPsmReduxForm = reduxForm<{}, any>({
  form: PartsPerPageFormName,
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
})(PartsPerPsmContainer);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PartsPerPsmReduxForm)
);
