import React from 'react';
import { Style } from '../constants/general';

interface Props {
    title: string;
    content: any;
    onClose: () => void;
    styleType?: string;
}

export const Modal = (props: Props) => {
    const { content, title, onClose, styleType } = props;
    var isWarning = styleType === Style.Warning; 
    
    return (
        <>
            <div className="modal is-active">
                <div onClick={() => onClose()} className="modal-background"></div>
                <div className="modal-card" style={{ height: isWarning ? "15%" : "85%", width: isWarning ? "30%" : "70%" }} >
                    <div style={{ textAlign: 'center', paddingBottom: '30px' }}>{title}</div>
                    <div style={{ color: 'black', textAlign: isWarning ? 'center' : 'left' }}>{content}</div>
                </div>
            </div>
        </>
    )
}