import { Action } from "redux";
import { Product } from "../model";

export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCT_MAINDATA = "GET_PRODUCT_MAINDATA";
export const GET_PRODUCT = "GET_PRODUCT";

export interface GetProductsAction extends Action<string> {
    products: Product[];
}

export interface GetProductAction extends Action<string> {
    productMainData?: Product | null | undefined;
    product?: Product | null | undefined;
}

