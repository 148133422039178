import * as types from '../types/actionTypes/findValuesType';

const initialStatePartsPerPSM = { brand: "", psm: "", ert: ""}
const initialStateTacticalParts = { group: "", ert: "", inAssortiment: "" }
const initialStateMissedSales = {ert: "", hitrate: "", psm: "", authGroup: "", inAssortiment: ""}

type StatePartsPerPSM = typeof initialStatePartsPerPSM
type StatePartsTacticalParts = typeof initialStateTacticalParts
type StatePartsMissedSales = typeof initialStateMissedSales

export const setPartsPerPSMFindValues = (
    state: StatePartsPerPSM = initialStatePartsPerPSM,
    action: types.SetPartsPerPSMFindValues
) => {
    if (action.type === types.SET_PARTSPERPSM_FIND_VALUES) {
        return { ...state, brand: action.brand, psm: action.psm, ert: action.ert};
    }
    return state;
};

export const setTacticalPartsFindValues = (
    state: StatePartsTacticalParts = initialStateTacticalParts,
    action: types.SetTacticalPartsFindValues
) => {
    if (action.type === types.SET_TACTICAL_PARTS_FIND_VALUES) {
        return { ...state, group: action.group, ert: action.ert, inAssortiment: action.inAssortiment};
    }

    return state;
};

export const setMissedSalesFindValues = (
    state: StatePartsMissedSales = initialStateMissedSales,
    action: types.SetMissedSalesFindValues
) => {
    if (action.type === types.SET_MISSED_SALES_FIND_VALUES) {
        return { ...state, ert: action.ert, hitrate: action.hitrate, psm: action.psm, authGroup: action.authGroup, inAssortiment: action.inAssortiment};
    }

    return state;
};