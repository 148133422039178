import ApiBase from './ApiBase';
import { Ert } from '../types/model';

const ertsEndpoint = 'erts' as string;

class ErtsApi extends ApiBase {
    getAllErts() : Promise<{erts: Ert[]}> {
        return this.doHttpRequest<{erts: Ert[]}>('get', `${ertsEndpoint}`, null, false);
    }
}

export default new ErtsApi();