import * as types from "../types/actionTypes/productType";

import { Product } from "../types/model";

const initialProductsState: Array<Product> = [];
const initialProductState = null as Product | null

type ProductsState = typeof initialProductsState
type ProductState = typeof initialProductState

export const productsReducer = (
    state: ProductsState = initialProductsState,
    action: types.GetProductsAction
) => {
    if (action.type === types.GET_PRODUCTS)
        return action.products;

    return state;
};

export const productReducer = (
    state: ProductState = initialProductState,
    action: types.GetProductAction
) => {
    if (action.type === types.GET_PRODUCT_MAINDATA) {
        if (action.productMainData) {
            var existingProduct = { ...state }

            var productMainData = action.productMainData;
            if (productMainData) {
                productMainData.maindataLoaded = true;
                if (existingProduct) {
                    existingProduct = { ...existingProduct, ...productMainData }

                }
                return existingProduct;
            }
        }
    }
    if (action.type === types.GET_PRODUCT) {
        if (action.product) {
            var newProduct = { ...state }
            if (newProduct) {
                newProduct.competitorPrices = action.product?.competitorPrices
                newProduct.maintenanceParts = action.product?.maintenanceParts
                newProduct.glpHistories = action.product?.glpHistories
                newProduct.infoRecords = action.product?.infoRecords
                newProduct.qteAllYears = action.product?.qteAllYears
                newProduct.hitrateHistories = action.product?.hitrateHistories
                newProduct.oldDeutzSuppliers = action.product?.oldDeutzSuppliers
                newProduct.commentSap = action.product?.commentSap
                newProduct.restdataloaded = true;
                return newProduct;
            }
        }
    }
    return state;
};
