import React, { FunctionComponent, useEffect, useState } from "react";
import { faSignInAlt, faUser } from '@fortawesome/free-solid-svg-icons'

import { AccountInfo } from "@azure/msal-browser";
import { AnyAction } from "redux";
import AzureAuthenticationContext from "../auth/authContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo from '../images/logo.png';
import { NavLink } from "react-router-dom";
import ProductDropdown from '../components/InputTypes/ProductDropdown';
import { RootState } from "../types/state";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { setProfile } from "../actions/profileActions";

const mapStateToProps = ({ profile }: RootState) => ({
    profile: profile,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, AnyAction>) => ({
    setProfile: (profile?: AccountInfo) => dispatch(setProfile(profile)),
});

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const isIE = msie > 0 || msie11 > 0;

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const LoginContainer: FunctionComponent<Props> = ({ setProfile, profile }) => {
    const setProfileReducer = (account?: AccountInfo) => {
        if (account?.name !== profile?.name) {
            setProfile(account)
        }
    }

    useEffect(() => {
        if (!authenticated)
            logIn();
    });

    const authenticationModule: AzureAuthenticationContext = new AzureAuthenticationContext();
    var account = authenticationModule.getAccount();
    setProfileReducer(account);

    const [authenticated, setAuthenticated] = useState<Boolean>(account ? true : false);
    const [user, setUser] = useState<AccountInfo | undefined>(account);
    const [loggingIn, setLoggingIn] = useState<Boolean>(true);

    const logIn = (): any => {        
        const typeName = "loginPopup";
        const logInType = isIE ? "loginRedirect" : typeName;
        authenticationModule.login(logInType, returnedAccountInfo, setLoggingIn);
        
    };

    const logOut = (): any => {
        if (user) {
            authenticationModule.logout(user);
        }
    };

    const returnedAccountInfo = (user: AccountInfo) => {
        setProfileReducer(user)
        setAuthenticated(user?.name ? true : false);
        setUser(user);        
    };

    return (
        <div className="login-container">
            <div className="login-wrapper columns">
                <div className="column">
                    <img src={Logo} style={{ height: '70px' }} alt="logo"></img>
                </div>
                <div className="column" style={{ paddingTop: '60px' }}>
                    Please login first, before using this application
                </div>
                <div className="column">
                    {
                        !loggingIn &&
                        <button className="navigation-button" style={{ display: 'inline', padding: '10px' }} onClick={() => authenticated ? logOut() : logIn()}>
                            <div className="is-flex is-align-items-center">
                                <FontAwesomeIcon icon={faSignInAlt} />
                                <span>{authenticated ? "Log Out" : "Log In"}</span>
                            </div>
                        </button>
                    }
                </div>
            </div>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)

