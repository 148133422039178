import { AsyncAction } from ".";
import TacticalPartsApi from "../api/TacticalPartsApi";
import { TacticalPartsInputValues } from "../types/model";
import { TacticalPartGroupsAction } from "../types/actionTypes/tactialPartType";
import * as types from '../types/actionTypes/tactialPartType';

export const getTacticalParts = (values: TacticalPartsInputValues) => {
    return TacticalPartsApi.getTacticalParts(values);
}

export const getTacticalPartGroups = (): AsyncAction<TacticalPartGroupsAction> => {
    return async (dispatch) => {
        var groups = await TacticalPartsApi.getTacticalPartGroups();
        groups.groups = groups.groups.sort((a, b) => { return a.group > b.group ? 1 : -1 })
        return dispatch({type: types.SET_TACTICAL_PART_GROUPS, tacticalPartGroups: groups.groups });
    }
}