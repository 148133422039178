import { WrappedFieldProps } from 'redux-form';
import classnames from 'classnames'
import * as React from 'react'

interface Props {
    hidden?: boolean;
    readOnly?: boolean
}

export type TextAreaProps = WrappedFieldProps & Props;


export const TextAreaComponent = (props: TextAreaProps) => {
    const { input, hidden, readOnly } = props;
    let { meta: { touched, error } } = props;
    return (
        <textarea
            {...input}
            readOnly={readOnly}
        />
    );
}
