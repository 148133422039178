import { Configuration, LogLevel } from '@azure/msal-browser';

export class MSAL_CONFIG {

    getConfig(): Configuration {
        var adApplicationClientId = localStorage.getItem('adApplicationClientId');
        var adApplicationRedirectUri = localStorage.getItem('adApplicationRedirectUri');
        var adApplicationTentantId = localStorage.getItem('adApplicationTentantId');
        return {
            auth: {
                clientId: adApplicationClientId ? adApplicationClientId : "",
                authority: `https://login.microsoftonline.com/${adApplicationTentantId}`,
                redirectUri: adApplicationRedirectUri ? adApplicationRedirectUri : ''
            },
            cache: {
                cacheLocation: "sessionStorage",
                storeAuthStateInCookie: false,
            },
            system: {
                loggerOptions: {
                    loggerCallback: (level: any, message: any, containsPii: any) => {
                        if (containsPii) {
                            return;
                        }
                        switch (level) {
                            case LogLevel.Error:
                                // eslint-disable-next-line                        
                                console.error(message);
                                return;
                            case LogLevel.Info:
                                // eslint-disable-next-line
                                console.info(message);
                                return;
                            case LogLevel.Verbose:
                                // eslint-disable-next-line
                                console.debug(message);
                                return;
                            case LogLevel.Warning:
                                // eslint-disable-next-line
                                console.warn(message);
                        }
                    }
                }
            }
        }
    }
}

export default MSAL_CONFIG