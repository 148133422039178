import * as types from '../types/actionTypes/tactialPartType';
import { TacticalPartGroup } from '../types/model';

const tacticalPartGroups = null as TacticalPartGroup | null
const initialState = tacticalPartGroups

type State = typeof initialState

export const getTacticalPartGroups = (
    state: State = initialState,
    action: types.TacticalPartGroupsAction
) => {
    if (action.type === types.SET_TACTICAL_PART_GROUPS)
        return { ...state, tacticalPartGroups: action.tacticalPartGroups};

    return state;
};