import { AccountInfo } from "@azure/msal-browser";
import { Action } from "redux";

export const SET_PROFILE = "SET_PROFILE";
export const SET_ROLE = "SET_ROLE";

export interface ProfileAction extends Action<string> {
    profile?: AccountInfo;
    role: string;
}

export interface RoleAction extends Action<string> {
    role?: string;
}

