import "react-datepicker/dist/react-datepicker.css";

import React from 'react';
import ReactDatePicker from "react-datepicker";
import { WrappedFieldProps } from 'redux-form';
import classnames from 'classnames';
import moment from 'moment';

interface Props {
    placeholder?: string;
    label?: string;
    readOnly?: boolean
    type?: string;
    showSideBySide?: boolean;
    required?: boolean;
    showTimeSelect?: boolean;
    dateFormat?: string
}

export default class DatePickerComponent extends React.Component<WrappedFieldProps & Props> {
    onChange = (newDate: Date) => {
        this.props.input.onChange(newDate ? moment(newDate) : "");
    }

    render() {
        const { input, label, required, meta: { touched, error }, dateFormat, readOnly } = this.props;
        const format = dateFormat || "dd-MM-yyyy";

        let style = {};

        return (
            <div className="field-body">
                <div className="field">
                    <div className={"form-group"} style={{ backgroundColor: readOnly ? "#f7f7f7" : 'transparent' }}>
                        {label ? <label style={{ backgroundColor: readOnly ? "#f7f7f7" : 'white' }}>{label} &nbsp;</label> : <label style={{ visibility: 'hidden' }}>-</label>}
                        <div style={{ display: 'flex' }}>
                            <ReactDatePicker
                                selected={input.value ? new Date(input.value) : required ? new Date() : null}
                                name={input.name}
                                className={classnames("input", touched && error && "is-danger")}
                                dateFormat={format}
                                dateFormatCalendar={format}
                                readOnly={readOnly}
                                timeCaption="Tijdstip"
                                timeIntervals={15}
                                autoComplete="off"
                                onChange={this.onChange}
                                showTimeSelect={this.props.showTimeSelect ? this.props.showTimeSelect : false}
                                strictParsing={true}
                                disabled={readOnly}
                                todayButton={"Vandaag"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}