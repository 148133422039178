import * as productTypes from '../types/actionTypes/productType';

import { GetProductAction, GetProductsAction } from "../types/actionTypes/productType";
import { Product, SubmitProductDashboardValues } from '../types/model';

import { AsyncAction } from "./";
import ProductApi from '../api/ProductDashboardApi';

export const getProducts = (material: string): AsyncAction<GetProductsAction> => {
    return async (dispatch) => {
        var products = await ProductApi.getProducts(material);
        return dispatch({ type: productTypes.GET_PRODUCTS, products });
    }
}

export const getProduct = (material: string): AsyncAction<GetProductAction> => {
    let product = null as Product | null;
    return async (dispatch) => {
        if (material === null)
            return dispatch({ type: productTypes.GET_PRODUCT, product });
        product = await ProductApi.getProduct(material);
        return dispatch({ type: productTypes.GET_PRODUCT, product });
    }
}

export const getProductMainData = (material: string): AsyncAction<GetProductAction> => {
    let productMainData = null as Product | null;
    return async (dispatch) => {
        if (material === null)
            return dispatch({ type: productTypes.GET_PRODUCT_MAINDATA, productMainData });
        productMainData = await ProductApi.getProductMainData(material);
        return dispatch({ type: productTypes.GET_PRODUCT_MAINDATA, productMainData });
    }
}

export const updateProduct = (values: SubmitProductDashboardValues) => {
    return ProductApi.updateProduct(values);
}
