import * as types from '../types/actionTypes/loadingType';

const initialLoadingState = false as boolean;

type LoadingState = typeof initialLoadingState;

export const loadingReducer = (
    state: LoadingState = initialLoadingState,
    action: types.LoadingAction
) => {
    if (action.type === types.SET_LOADING)
        return action.isLoading;

    return state;
};
