//import { HighSpeedErts, LargeBoreErts, SmallBoreErts } from '../constants/erts';

import ERTComponent from './ErtComponent';
import { FunctionComponent } from 'react';
import * as React from 'react'
import { RootState } from '../types/state';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Ert, CategoryErt } from '../types/model';

interface ERTSProps{
    erts: Ert[];
}
const mapStateToProps = (state: RootState): ERTSProps => {
    return {
        erts: state.erts?.erts,
    };
};

type Props = ReturnType<typeof mapStateToProps>

export const ERTSComponent: FunctionComponent<Props> = ({erts}) => {

    const getCategory = (category: string) => {
        var filtered = erts?.filter(x => x.productGroup === category);

        var brands = [...new Set(filtered?.map(x => x.brand))];

        var categories = [] as CategoryErt[];
        brands.forEach((x) => {
            var erts = filtered?.filter(y => y.brand === x);
            categories.push({category: x.toUpperCase(), erts: erts });
        })

        return categories;
    }

    return (
        <div className="columns">
            <div className="column">
                <ERTComponent
                    categoryErts={getCategory("HS")}
                    color="#00A81A"
                    title="HIGH SPEED"
                />
            </div>
            <div className="column">
                <ERTComponent
                    categoryErts={getCategory("SB")}
                    color="#DB0800"
                    maxColumnWidth={5}
                    title="SMALL BORE"
                />
            </div>
            <div className="column">
                <ERTComponent
                    categoryErts={getCategory("LB")}
                    color="#086FD6"
                    title="LARGE BORE"
                />
            </div>
            <div className="column">
                <ERTComponent
                    categoryErts={getCategory("Dummy")}
                    color="#000000"
                    title="DUMMY"
                    maxColumnWidth={10}
                />
            </div>
        </div> 
    );
}

export default withRouter(
connect(mapStateToProps)(ERTSComponent)
);
