import { CompetitorPrice, MaintenancePart, OldDeutzSupplier, PartEngineType } from '../types/model';

export const mapCompetitorPrices = (competitorPrices: any): CompetitorPrice[] => {
    var newCompetitorPrices = [] as CompetitorPrice[];
    for (var i = 0; i < competitorPrices.length; i++) {
        var competitorPrice = competitorPrices[i];

        if (competitorPrice.id) {
            newCompetitorPrices.push(competitorPrice);
        } else {
            newCompetitorPrices.push({
                id: 0,
                competitor: competitorPrice.competitor,
                nettPrice: parseFloat(competitorPrice.nettPrice),
                grossPrice: parseFloat(competitorPrice.grossPrice),
                recon: competitorPrice.recon ? competitorPrice.recon.toString() === 'true' : true,
                priceDate: competitorPrice.priceDate
            })
        }
    }
    return newCompetitorPrices;
}

export const mapEngineType = (engineTypes: any): PartEngineType[] => {
    var newEngineTypes = [] as PartEngineType[];
    for (var i = 0; i < engineTypes.length; i++) {
        var engineType = engineTypes[i];

        if (engineType.id) {
            newEngineTypes.push(engineType);
        } else {
            newEngineTypes.push({
                id: 0,
                productType: engineType.productType,
                qtyEngines: parseInt(engineType.qtyEngines)
            })
        }
    }
    return engineTypes;
}

export const mapOldDeutzSuppliers = (oldDeutzSuppliers: any): OldDeutzSupplier[] => {
    var newOldDeutzSuppliers = [] as OldDeutzSupplier[];
    for (var i = 0; i < oldDeutzSuppliers.length; i++) {
        var oldDeutzSupplier = oldDeutzSuppliers[i];

        if (oldDeutzSupplier.id) {
            newOldDeutzSuppliers.push(oldDeutzSupplier);
        } else {
            newOldDeutzSuppliers.push({
                id: 0,
                source: oldDeutzSupplier.source,
                materialDetails: oldDeutzSupplier.materialDetails,
                name1: oldDeutzSupplier.name1,
                name2: oldDeutzSupplier.name2,
                country: oldDeutzSupplier.country,
                town1: oldDeutzSupplier.town1,
                town2: oldDeutzSupplier.town2,
                street: oldDeutzSupplier.street,
                telephone: oldDeutzSupplier.telephone,
                date: oldDeutzSupplier.date,
                qtyOrdered: parseFloat(oldDeutzSupplier.qtyOrdered),
                nettPrice: parseFloat(oldDeutzSupplier.nettPrice),
                currency: oldDeutzSupplier.currency,
                orderUnit: oldDeutzSupplier.orderUnit,
                priceUnit: parseInt(oldDeutzSupplier.priceUnit),
            })
        }
    }
    return newOldDeutzSuppliers;
}

export const mapMaintenanceParts = (maintenanceParts: any): MaintenancePart[] => {
    var newMaintenanceParts = [] as MaintenancePart[];
    for (var i = 0; i < maintenanceParts.length; i++) {
        var maintenancePart = maintenanceParts[i];

        if (maintenancePart.id) {
            newMaintenanceParts.push(maintenancePart);
        } else {
            newMaintenanceParts.push({
                id: 0,
                ert: maintenancePart.ert,
                interval: maintenancePart.interval,
                jobDescription: maintenancePart.jobDescription,
                inspDep: maintenancePart.inspDep,
                comment: maintenancePart.comment,
                lastSuccessor: maintenancePart.lastSuccessor
            })
        }
    }
    return newMaintenanceParts;
}