import ApiBase from './ApiBase';
import { Document } from '../types/model';

const sparePartpageEndpoint = 'sparepartpage' as string;

class SparePartPageApi extends ApiBase {
    getSparePartPage(containerName : string, document: string): Promise<string> {
        return this.doHttpRequest<string>('get', `${sparePartpageEndpoint}/${containerName}/${document}`, null, false);
    }
}

export default new SparePartPageApi();