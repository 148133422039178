import { AsyncAction } from ".";
import * as actionTypes from "../types/actionTypes/filterSettingType";
import { SetFilterSettingAction } from "../types/actionTypes/filterSettingType";


export const setFilterSettings = (filterSettings: {name: string, value: string}[], type: string): AsyncAction<SetFilterSettingAction> => {
    return async (dispatch) => {
        if (type === "PartsPerPSM")
            return dispatch({ type: actionTypes.SET_FILTER_SETTING_PARTS_PER_PSM, filterSettings });
        if (type === "TacticalParts")
        return dispatch({ type: actionTypes.SET_FILTER_SETTING_TACTICAL_PARTS, filterSettings });
        else
            return dispatch({ type: actionTypes.SET_FILTER_SETTING_MISSED_SALES, filterSettings });
    };
}