import { Field } from "redux-form"
import React from "react"
import { TextInputComponent } from '../../components/InputTypes/TextInput';
import { lang } from 'moment';

export const MaintenanceEditColumns = (index: number, readOnly: boolean): JSX.Element => (
    <>
        <div className="columns">
            <div className="column">
                <Field
                    key={5}
                    component={TextInputComponent}
                    name={`maintenanceParts[${index}].ert`}
                    readOnly={readOnly}
                    label="ERT"
                    labelInComponent={true}
                />
            </div>
            <div className="column">
                <Field
                    key={6}
                    component={TextInputComponent}
                    name={`maintenanceParts[${index}].interval`}
                    label="Interval "
                    readOnly={readOnly}
                    labelInComponent={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={8}
                    component={TextInputComponent}
                    name={`maintenanceParts[${index}].jobDescription`}
                    readOnly={readOnly}
                    label="Job description"
                    labelInComponent={true}
                />
            </div>
            <div className="column">
                <Field
                    key={9}
                    component={TextInputComponent}
                    name={`maintenanceParts[${index}].inspDep`}
                    readOnly={readOnly}
                    label="Insp Dep"
                    labelInComponent={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={10}
                    component={TextInputComponent}
                    name={`maintenanceParts[${index}].comment`}
                    readOnly={readOnly}
                    label="Comment"
                    labelInComponent={true}
                />
            </div>
            <div className="column">
                <Field
                    key={11}
                    component={TextInputComponent}
                    name={`maintenanceParts[${index}].lastSuccessor`}
                    readOnly={readOnly}
                    label="Last successor"
                    labelInComponent={true}
                />
            </div>
        </div>
    </>
)

