import { SubmitNewPartValues } from "./../types/model";
import PartsApi from "../api/PartsApi";
import { PartsPerPsmFormValues } from "../types/model";

export const getPartsPerPsm = (values: PartsPerPsmFormValues) => {
  return PartsApi.getPartsPerPsm(values);
};

export const getPartsPerPsmCsvData = (values: PartsPerPsmFormValues) => {
  return PartsApi.getPartsPerPsmCsvData(values);
};

export const submitNewPart = (submitValues: SubmitNewPartValues) => {
  return PartsApi.submitNewPart(submitValues);
};
