import { CustomInput, Input } from "reactstrap"
import { showDate, showMoney } from "../../helpers/formatHelper"

import React from "react"

export const Filter = ({ column }: any) => {
    return (
        <div className="filter-wrapper" style={{ marginTop: 5 }}>
            {column.canFilter && column.render("Filter")}
        </div>
    )
}

export const DefaultColumnFilter = ({
    column: {
        filterValue,
        setFilter,
    } }: any) => {
    return (
        <Input
            value={filterValue || ""}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
        />
    )
}

export const SelectColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id } }: any, i18n: any, enumType: any) => {

    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row: any) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    return (
        <CustomInput
            id="custom-select"
            type="select"
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        >

            <option value="">{i18n.all}</option>
            {options.map((option) => (
                <option key={option as any} value={option as any}>
                    {enumType[option as any] as any}
                </option>
            ))}
        </CustomInput>
    );
};

export const enumFilter = (rows: any, filter: any, enumValues: any) => {
    return rows.filter((r: any) => r._original.alwaysVisible || ((r[filter.id] || r[filter.id] === 0) &&
        (
            enumValues[r[filter.id]].toString()
                .toLowerCase()
                .indexOf(filter.value.toLowerCase()) !== -1
        )))
}


export const filterDate = (rows, id, filterValue) => {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return showDate(rowValue).includes(filterValue);
    })
}

export const filterDecimals = (rows, id, filterValue) => {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue ? showMoney(rowValue).includes(filterValue) : true;
    })
}
