import {
    AccountInfo,
    AuthenticationResult,
    Configuration,
    EndSessionRequest,
    PopupRequest,
    PublicClientApplication,
    RedirectRequest,
} from "@azure/msal-browser";

import MSAL_CONFIG from "./authConfig";

export class AzureAuthenticationContext {
    private myMSALObj: PublicClientApplication;
    private account?: AccountInfo;
    private loginRedirectRequest?: RedirectRequest;
    private loginRequest?: PopupRequest;

    public isAuthenticationConfigured = false;

    constructor() {
        // @ts-ignore
        this.account = null;
        var msalConfig = new MSAL_CONFIG();
        var config = msalConfig.getConfig();

        this.myMSALObj = new PublicClientApplication(
            config
        );

        this.setRequestObjects(config);

        if (config?.auth?.clientId) {
            this.isAuthenticationConfigured = true;
        }
    }

    private setRequestObjects(config: Configuration): void {
        this.loginRequest = {
            scopes: [],
            prompt: "none",
        };

        this.loginRedirectRequest = {
            ...this.loginRequest,
            authority: config.auth.authority,
            redirectStartPage: window.location.href,
        };
    }

    login(signInType: string, setUser: any, setLoginButton: any): void {
        if (signInType === "loginPopup") {
            this.myMSALObj
                .loginPopup(this.loginRequest)
                .then((resp: AuthenticationResult) => {
                    this.handleResponse(resp, setUser);
                })
                .catch((err) => {
                    // eslint-disable-next-line
                    var errorMsg = (err as Error);

                    if (errorMsg.name === "InteractionRequiredAuthError") {
                        this.loginRequest = {
                            scopes: [],
                            prompt: "select_account"
                        };
                        this.myMSALObj.loginPopup(this.loginRequest)
                            .then((resp: AuthenticationResult) => {
                                this.handleResponse(resp, setUser);
                            })
                            .catch((err) => {
                                setLoginButton(false)
                                // eslint-disable-next-line
                                //console.error(err);
                            });
                    }
                });
        } else if (signInType === "loginRedirect") {
            this.myMSALObj.loginRedirect(this.loginRedirectRequest);
        }
    }

    logout(account: AccountInfo): void {
        const logOutRequest: EndSessionRequest = {
            account,
        };

        this.myMSALObj.logout(logOutRequest);
    }
    handleResponse(response: AuthenticationResult, incomingFunction: any) {
        if (response !== null && response.account !== null) {
            this.account = response.account;
        } else {
            this.account = this.getAccount();
        }

        if (this.account) {
            incomingFunction(this.account);
        }
    }
    getAccount(): AccountInfo | undefined {
        const currentAccounts = this.myMSALObj.getAllAccounts();

        if (currentAccounts === null) {
            return undefined;
        }

        if (currentAccounts.length > 1) {
            return currentAccounts[0];
        } else if (currentAccounts.length === 1) {
            return currentAccounts[0];
        }
    }
}

export default AzureAuthenticationContext;