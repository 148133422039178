import './App.scss';
import "bulma/css/bulma.min.css";
import 'react-toastify/dist/ReactToastify.css';

import AppContainer from './containers/AppContainer'
import { ToastContainer } from "react-toastify";
import React from 'react'

function App() {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        closeOnClick
      />
      <AppContainer />

    </>
  )
}

export default App;
