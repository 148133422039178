import React, { FunctionComponent } from 'react';

type ButtonProps = {
    icon?: string;
    text: string;
    onClick?: () => void;
    disabled?: boolean
    style: React.CSSProperties;
}

export const Button: FunctionComponent<ButtonProps> = ({ style, text, icon, onClick, disabled }) => {
    return (
        <button type="button" disabled={disabled} onClick={() => onClick && !disabled ? onClick() : () => { }} className="default-button" style={style}>
            {text}
        </button>
    );
}

