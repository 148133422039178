import DatePickerComponent from '../../components/InputTypes/DatePicker';
import { DropdownComponent } from '../../components/InputTypes/Dropdown';
import { DropdownOption } from '../../types/model';
import { Field } from "redux-form"
import React from "react"
import { TextInputComponent } from '../../components/InputTypes/TextInput';
import { lang } from 'moment';

export const OldDeutzSuppliersEditColumns = (index: number, readOnly: boolean): JSX.Element => (
    <>
        <div className="columns">
            <div className="column">
                <Field
                    key={1}
                    component={TextInputComponent}
                    name={`oldDeutzSuppliers[${index}].source`}
                    label="Source"
                    readOnly={readOnly}
                    labelInComponent={true}
                    maxLength={50}
                />
            </div>
            <div className="column">
                <Field
                    key={2}
                    component={TextInputComponent}
                    name={`oldDeutzSuppliers[${index}].materialDetails`}
                    label="Material details "
                    readOnly={readOnly}
                    labelInComponent={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={3}
                    component={TextInputComponent}
                    name={`oldDeutzSuppliers[${index}].name1`}
                    label="Name 1"
                    readOnly={readOnly}
                    labelInComponent={true}
                    maxLength={100}
                />
            </div>
            <div className="column">
                <Field
                    key={4}
                    component={TextInputComponent}
                    name={`oldDeutzSuppliers[${index}].name2`}
                    label="Name 2"
                    readOnly={readOnly}
                    maxLength={100}
                    labelInComponent={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={5}
                    component={TextInputComponent}
                    name={`oldDeutzSuppliers[${index}].country`}
                    label="Country"
                    readOnly={readOnly}
                    labelInComponent={true}
                    maxLength={2}
                />
            </div>
            <div className="column">
                <Field
                    key={6}
                    component={TextInputComponent}
                    name={`oldDeutzSuppliers[${index}].town1`}
                    label="Town 1"
                    readOnly={readOnly}
                    maxLength={50}
                    labelInComponent={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={7}
                    component={TextInputComponent}
                    name={`oldDeutzSuppliers[${index}].town2`}
                    label="Town 2"
                    maxLength={30}
                    readOnly={readOnly}
                    labelInComponent={true}
                />
            </div>
            <div className="column">
                <Field
                    key={8}
                    component={TextInputComponent}
                    name={`oldDeutzSuppliers[${index}].street`}
                    label="Street"
                    maxLength={30}
                    readOnly={readOnly}
                    labelInComponent={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={9}
                    component={TextInputComponent}
                    name={`oldDeutzSuppliers[${index}].telephone`}
                    label="telephone"
                    readOnly={readOnly}
                    labelInComponent={true}
                    maxLength={20}
                />
            </div>
            <div className="column">
                <Field
                    key={10}
                    component={DatePickerComponent}
                    name={`oldDeutzSuppliers[${index}].date`}
                    label="Date"
                    readOnly={readOnly}
                    labelInComponent={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={11}
                    type="number"
                    component={TextInputComponent}
                    name={`oldDeutzSuppliers[${index}].qtyOrdered`}
                    label="Qty Ordered"
                    readOnly={readOnly}
                    labelInComponent={true}
                    maxLength={3}
                />
            </div>
            <div className="column">
                <Field
                    key={12}
                    type="number"
                    component={TextInputComponent}
                    name={`oldDeutzSuppliers[${index}].nettPrice`}
                    label="Nett price"
                    readOnly={readOnly}
                    labelInComponent={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={13}
                    component={TextInputComponent}
                    name={`oldDeutzSuppliers[${index}].currency`}
                    label="Currency"
                    readOnly={readOnly}
                    labelInComponent={true}
                />
            </div>
            <div className="column">
                <Field
                    key={14}
                    component={TextInputComponent}
                    name={`oldDeutzSuppliers[${index}].orderUnit`}
                    label="Order unit"
                    readOnly={readOnly}
                    labelInComponent={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={15}
                    type="number"
                    component={TextInputComponent}
                    name={`oldDeutzSuppliers[${index}].priceUnit`}
                    label="Price unit"
                    readOnly={readOnly}
                    disallowNummericValues={true}
                    labelInComponent={true}
                />
            </div>
        </div>
    </>
)

