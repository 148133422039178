import "react-tabs/style/react-tabs.css";
import * as React from 'react'

import { ComponentType, Ert, Product, Role } from "../types/model";
import {
  CompetitorPriceColumns,
  GlpHistoryColumns,
  HitrateHistoriesColumns,
  InfoRecordsColumns,
  MaintenancePartsColumns,
  OldDeutzSuppliersColumns,
  OrdQteAllYearsColumns,
  SortProps,
} from "./Gridview/tableColumns";
import {
  destroy,
} from "redux-form";
import { FunctionComponent, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import { AccountInfo } from "@azure/msal-browser";
import { AnyAction } from "redux";
import { CommentSapComponent } from "./CommentSapComponent";
import { CompetitorPriceEditColumns } from "../constants/columns/CompetitorPrice";
import { ERTSComponent } from "./ERTSComponent";
import { EngineTypeEditColumns } from "../constants/columns/EngineType";
import GridviewComponent from "../components/Gridview/GridviewComponent";
import { LoadSpinnerComponent } from "../components/LoadSpinnerComponent";
import MainDataComponent from "./MainDataComponent";
import { MaintenanceEditColumns } from "../constants/columns/Maintenance";
import { Modal } from "../components/ModalComponent";
import { OldDeutzSuppliersEditColumns } from "../constants/columns/OldDeutzSuppliers";
import { ProductDashboardFormName } from "../constants/formNames";
import { RootState } from "../types/state";
import { ThunkDispatch } from "redux-thunk";
import { checkPermission } from "../helpers/permissionHelper";
import { connect } from "react-redux";
import { setRole } from "../actions/profileActions";
import { setSelectedTab } from "../actions/generalActions";

interface StoreProps {
  product: Product;
  profile: AccountInfo | undefined;
  tabIndex: number;
  erts: Ert[];
}

interface ProductDetailsProps {
  isLoading: boolean;
  showModal: boolean;
  setShowModal: (isOpen: boolean) => void;
  isDirty: boolean;
  onFieldChange: (form: string, field: string, value: string) => any;
}

interface MatchParams {
  material: string;
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, AnyAction>
) => ({
  setSelectedTab: (tab: number) => dispatch(setSelectedTab(tab)),
  setRole: (role: string) => dispatch(setRole(role)),
  resetForm: () => dispatch(destroy(ProductDashboardFormName)),
});

const mapStateToProps = (state: RootState): StoreProps => {
  return {
    product: state.product,
    profile: state.profile,
    tabIndex: state.tabIndex,
    erts: state.erts?.erts,
  };
};

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  ProductDetailsProps &
  RouteComponentProps<MatchParams>;

const ProductDetailsComponent: FunctionComponent<Props> = ({
  product,
  showModal,
  setShowModal,
  tabIndex,
  setSelectedTab,
  profile,
  match,
  isLoading,
  isDirty,
  onFieldChange,
  erts
}) => {
  const [selectedId, setSelectedId] = useState(null);

  const competitorPrice = product?.competitorPrices
    ? product?.competitorPrices
    : [];
  const engineTypes = product?.mainData?.partEngineTypes
    ? product?.mainData?.partEngineTypes
    : [];
  const maintenanceParts = product?.maintenanceParts
    ? product?.maintenanceParts
    : [];
  const glpHistories = product?.glpHistories ? product?.glpHistories : [];
  const infoRecords = product?.infoRecords ? product?.infoRecords : [];
  const qteAllYears = product?.qteAllYears ? product?.qteAllYears : [];
  const hitrateHistories = product?.hitrateHistories
    ? product?.hitrateHistories
    : [];
  const oldDeutzSuppliers = product?.oldDeutzSuppliers
    ? product?.oldDeutzSuppliers
    : [];

  var title = "";
  var editColumns = {} as JSX.Element;
  var index = -1;

  if (tabIndex === ComponentType.CompetitorPrice) {
    index = competitorPrice.findIndex((e) => e.id === selectedId);
    editColumns = CompetitorPriceEditColumns(
      index === -1 ? competitorPrice.length : index,
      !checkPermission(profile, Role.SuperUser)
    );
    title = "COMPETITOR PRICE";
  }
  if (tabIndex === ComponentType.Maintenance) {
    index = maintenanceParts.findIndex((e) => e.id === selectedId);
    editColumns = MaintenanceEditColumns(
      index === -1 ? maintenanceParts.length : index,
      !checkPermission(profile, Role.SuperUser)
    );
    title = "MAINTENANCE";
  }
  if (tabIndex === ComponentType.OldDeutzSuppliers) {
    index = oldDeutzSuppliers.findIndex((e) => e.id === selectedId);
    editColumns = OldDeutzSuppliersEditColumns(
      index === -1 ? oldDeutzSuppliers.length : index,
      !checkPermission(profile, Role.SuperUser)
    );
    title = "OLD DEUTZ SUPPLIERS";
  }
  if (tabIndex === ComponentType.MainData) {
    index = engineTypes.findIndex((e) => e.id === selectedId);
    editColumns = EngineTypeEditColumns(
      index === -1 ? engineTypes.length : index,
      !checkPermission(profile, Role.SuperUser)
    );
    title = "ENGINE TYPES";
  }

  var content = (
    <>
      {editColumns}
      {checkPermission(profile, Role.SuperUser) && (
        <div className="columns">
          <div className="column has-text-centered">
            <button type="submit" className="save-button">
              Save
            </button>
          </div>
        </div>
      )}
    </>
  );

  const onRowClick = (row: any) => {
    setSelectedId(row);
    setShowModal(true);
  };
  var loadingSpinner = <LoadSpinnerComponent />;
  var showLoadingSpinnerMainData = false;
  var showLoadingSpinner = false;
  if (
    product?.material !== match.params.material ||
    (!product && match.params.material) ||
    isLoading
  )
    showLoadingSpinnerMainData = true;
  if (
    (!product?.maindataLoaded && product?.restdataloaded) ||
    !product ||
    isLoading
  ) {

    showLoadingSpinner = true;
  }
  if (!match.params.material) {
    showLoadingSpinnerMainData = false;
    showLoadingSpinner = false;
  }


  return (
    <>
      {showModal && (
        <Modal
          content={content}
          title={title}
          onClose={() => setShowModal(false)}
        />
      )}

      <div className="main-container">
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(tabIndex) => {
            setSelectedTab(tabIndex);
            return true;
          }}
        >
          <TabList>
            <Tab>MAIN DATA</Tab>
            <Tab>ERT'S</Tab>
            <Tab>COMPETITOR PRICE</Tab>
            <Tab>MAINT.</Tab>
            <Tab>GLP HISTORY</Tab>
            <Tab>INFO RECORD</Tab>
            <Tab>ORD/QTE ALL YEARS</Tab>
            <Tab>HITRATE HISTORY</Tab>
            <Tab>OLD DEUTZ SUPPLIERS</Tab>
            <Tab>COMMENT SAP</Tab>
          </TabList>
          <div className="content-container">
            <TabPanel>
              {!showLoadingSpinnerMainData ? (
                <MainDataComponent onClick={onRowClick} onFieldChange={onFieldChange} />
              ) : (
                loadingSpinner
              )}
            </TabPanel>
            <TabPanel>
              {!showLoadingSpinnerMainData ? <ERTSComponent erts={erts}   /> : loadingSpinner}
            </TabPanel>
            <TabPanel>
              {!showLoadingSpinner ? (
                <GridviewComponent
                  columns={CompetitorPriceColumns()}
                  data={competitorPrice}
                  onRowClick={onRowClick}
                  canAddRecords={checkPermission(profile, Role.User)}
                />
              ) : (
                loadingSpinner
              )}
            </TabPanel>
            <TabPanel>
              {!showLoadingSpinner ? (
                <GridviewComponent
                  columns={MaintenancePartsColumns()}
                  data={maintenanceParts}
                  onRowClick={onRowClick}
                  canAddRecords={checkPermission(profile, Role.SuperUser)}
                />
              ) : (
                loadingSpinner
              )}
            </TabPanel>
            <TabPanel>
              {!showLoadingSpinner ? (
                <GridviewComponent
                  columns={GlpHistoryColumns()}
                  data={glpHistories}
                  defaultSort={[{ id: 'validFrom', desc: true }] as SortProps[]}
                />
              ) : (
                loadingSpinner
              )}
            </TabPanel>
            <TabPanel>
              {!showLoadingSpinner ? (
                <GridviewComponent
                  columns={InfoRecordsColumns()}
                  data={infoRecords}

                />
              ) : (
                loadingSpinner
              )}
            </TabPanel>
            <TabPanel>
              {!showLoadingSpinner ? (
                <GridviewComponent
                  columns={OrdQteAllYearsColumns()}
                  data={qteAllYears}
                  defaultSort={[{ id: 'createdOn', desc: true }] as SortProps[]}
                />
              ) : (
                loadingSpinner
              )}
            </TabPanel>
            <TabPanel>
              {!showLoadingSpinner ? (
                <GridviewComponent
                  columns={HitrateHistoriesColumns()}
                  data={hitrateHistories}
                  defaultSort={[{ id: 'period', desc: true }] as SortProps[]}
                />
              ) : (
                loadingSpinner
              )}
            </TabPanel>
            <TabPanel>
              {!showLoadingSpinner ? (
                <GridviewComponent
                  columns={OldDeutzSuppliersColumns()}
                  data={oldDeutzSuppliers}
                  onRowClick={onRowClick}
                  canAddRecords={checkPermission(profile, Role.SuperUser)}
                />
              ) : (
                loadingSpinner
              )}
            </TabPanel>
            <TabPanel>
              {!showLoadingSpinner ? (
                <CommentSapComponent commentSap={product?.commentSap} />
              ) : (
                loadingSpinner
              )}
            </TabPanel>
          </div>
        </Tabs>
        {!showModal &&
          product?.maindataLoaded &&
          product?.restdataloaded &&
          product &&
          isDirty && (
            <div
              className="unsaved-changes-wrapper"
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{
                marginLeft: "auto"
              }}
              >
                <span style={{ marginRight: "10px" }}>Save your data here</span>
                <button className="save-button">Save changes</button>
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductDetailsComponent)
);
