import App from './App';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { store } from "./store/store";
import * as React from 'react'

declare global {
  interface Window {
    store: any;
    editor: any;
    settings: any;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

fetch('/settings.json', { method: 'GET' })
  .then(result => result.json())
  .then(json => { 
    localStorage.setItem("baseUrl", json.baseUrl);
    localStorage.setItem("adApplicationClientId", json.adApplicationClientId);
    localStorage.setItem("adApplicationRedirectUri", json.adApplicationRedirectUri);
    localStorage.setItem("adApplicationTentantId", json.adApplicationTentantId);
    localStorage.setItem("hostKey", json.hostKey);
  })
  .then( () => {
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.getElementById('root')
      )
  });

reportWebVitals();
