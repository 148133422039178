import { Action } from "redux";
import { GeneralInformation } from "../model";

export const SET_GENERAL_INFORMATION = "SET_GENERAL_INFORMATION";
export const SET_SELECTED_TAB = "SET_SELECTED_TAB";


export interface GeneralInformationAction extends Action<string> {
    generalInformation: GeneralInformation;
}

export interface TabAction extends Action<string> {
    tabIndex: number;
}