import { DefaultColumnFilter, Filter } from "./filters";
import {
  Hooks,
  useBlockLayout,
  useFilters,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from "react-table";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GridviewFooterComponent } from "./GridviewFooterComponent";
import { Button, Input } from "reactstrap";
import { LoadSpinnerComponent } from "../LoadSpinnerComponent";
import { RenderType, TableColumn } from "../../types/model";
import { getXlsxBase64 } from "../../actions/xlsxActions";
//import { useState } from "react";
import {
  showDate,
  showDecimal,
  showDecimalCsv,
  showPercentage,
} from "../../helpers/formatHelper";
import { renderAsTextList, SortProps } from "./tableColumns";
import React from "react";

const fileSystem = require("browserify-fs");

type GridviewComponentProps = {
  columns: TableColumn[];
  data: any;
  onRowClick?: (row: any) => void;
  isLoading?: boolean;
  isTacticalParts?: boolean;
  canAddRecords?: boolean;
  getCsvData?: any;
  defaultSort?: SortProps[] | undefined;
  defaultFilter?: {field: string, value: string}[] | undefined;
  onFilterChanged?: (field: string, value: string) => any;
};

export const ColumnFilter = ({
  column: {
      filterValue,
      setFilter,
      onFilterChange,
      headerName
  } }: any) => {
  return (
      <Input
          value={filterValue || ""}
          onChange={e => {
              //onFilterChanged(e.target.value);
              if (e.target.value === filterValue)
                return;

              if (onFilterChange) {
                onFilterChange(headerName, e.target.value);
              }
              setFilter(e.target.value || undefined);
          }}
      />
  )
}

type RowProps = {
  row: any;
  onRowClick?: (row: any) => void;
};

export default function ({
  columns,
  data,
  onRowClick,
  isLoading,
  isTacticalParts,
  canAddRecords,
  getCsvData,
  defaultSort,
  defaultFilter,
  onFilterChanged,
}: GridviewComponentProps): JSX.Element {
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 90,
      width: 150,
      maxWidth: 400,
      Filter: ColumnFilter
    }),
    []
  )

  var {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      defaultColumn, //: defaultColumn, //{ defaultColumn, Filter: DefaultColumnFilter },
      columns: columns,
      data: data,
      initialState: {
        pageIndex: 0,
        pageSize: 20,
        csvColumns: [],
        sortBy: defaultSort ? defaultSort : []
      }
    } as any,
    useFilters as any,
    useSortBy,
    useBlockLayout,
    useResizeColumns,
    usePagination as <D extends object = {}>(hooks: Hooks<D>) => void,
  ) as any;

  const [isCsvApiCall, setIsCsvApiCall] = React.useState<boolean>(false);
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  function renderCell(cell: any, cellValue2: any) {
    const splitString = "#$%^&&**";
    const type = cell.column.type;
    const object = page[cell.row.index].original;
    const accessor = cell.column.id;
    var cellValue = object[accessor];
    const language = navigator.language;
    if (type) {
      if (type === RenderType.Date) {
        return showDate(cellValue2).toString() + splitString + RenderType.Date;
      }
      if (type === RenderType.Bool) {
        return (cellValue2 ? "True" : "False") + splitString + RenderType.Bool;
      }
      if (type === RenderType.Money) {
        return (
          showDecimalCsv(cellValue2, language, 2) +
          splitString +
          RenderType.Money
        );
      }
      if (type === RenderType.Percentage) {
        return (
          showPercentage(cellValue2, 2, true).toString().replace(",", ".") +
          splitString +
          RenderType.Percentage
        );
      }
      if (type === RenderType.TrueOrEmpty) {
        return (
          (cellValue2 === true ? "true" : "") +
          splitString +
          RenderType.TrueOrEmpty
        );
      }
      if (type === RenderType.Decimal) {
        return (
          showDecimal(cellValue2, 1, language) +
          splitString +
          RenderType.Decimal
        );
      }
      if (type === RenderType.Number) {
        return (
          cellValue2 as number + splitString + RenderType.Number
        );
      } else {
        return cellValue2
          ? cellValue2 + splitString + RenderType.String
          : cellValue2;
      }
    } else {
      return cellValue2
        ? cellValue2 + splitString + RenderType.String
        : cellValue2;
    }
  }

  const exportToCsv = async () => {
    setIsDownloading(true);
    var allCells = [] as string[][];
    var cellHeaders = [] as string[];
    columns.forEach((e, i) => {
      cellHeaders.push(e.Header);
    });
    allCells.push(cellHeaders);
    data.forEach((row, index1) => {
      var cellValues = [] as string[];
      page[0].cells.forEach((cell, index2) => {
        var columnValue = row[cell.column.id];
        var newCell = renderCell(cell, columnValue);
        cellValues.push(newCell);
      });
      allCells.push(cellValues);
    });

    var base64string = await getXlsxBase64(allCells);

    setIsDownloading(false);

    var link = window.document.createElement("a");
    link.setAttribute(
      "href",
      `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64string}`
    );
    link.setAttribute("download", "download.xlsx");
    link.click();
  };

  const Row = ({ row, onRowClick }: RowProps) => {
    return (
      <div
        key={row.index}
        {...row.getRowProps()}
        onClick={() => (onRowClick ? onRowClick(row?.original?.id) : () => { })}
        className="tr"
      >
        {row.cells.map((cell: any, i: number) => {
          return (
            <div {...cell.getCellProps()} key={i} className="td">
              {cell.render("Cell")}
            </div>
          );
        })}
      </div>
    );
  };

  return isLoading || isDownloading || isCsvApiCall ? (
    <LoadSpinnerComponent showText={isCsvApiCall || isDownloading} />
  ) : (
    <>
      <div style={{ width: "240px", marginBottom: "20px" }}>
        {exportToCsv && data.length > 0 && (
          <>
            <Button
              color="primary"
              type={"button"}
              className="navigation-gridview-button"
              onClick={(): void => {
                if (getCsvData) {
                  setIsCsvApiCall(true);
                  getCsvData().then((e) => {
                    setIsCsvApiCall(false);
                  });
                } else if (exportToCsv) {
                  exportToCsv();
                }
              }}
            >
              {"D"}
            </Button>
            Download XLSX
          </>
        )}
      </div>
      <div>
        <div {...getTableProps()} className="table">
          <div>
            {headerGroups.map((headerGroup: any) => {
              return (
                <div {...headerGroup.getHeaderGroupProps()} className="tr">
                  { headerGroup.headers.map((column: any) => {
                    column.onFilterChange = onFilterChanged;
                    column.headerName = column.Header;
                    var filter = defaultFilter?.find((x) => x.field === column.headerName);
                    if (filter && filter.value && column.filterValue !== filter.value) {
                      column.setFilter(filter.value);
                    }
                    return (
                      <>
                        <div className="th" {...column.getHeaderProps()} >
                          <div {...column.getSortByToggleProps()}>
                            <span>
                              {generateSortingIndicator(column)}
                            </span>
                            <div className="thHeader">{column.render("Header")}</div>
                            <Filter column={column}  />
                          </div>
                          <div
                            {...column.getResizerProps()}
                            className={`resizer ${column.isResizing ? 'isResizing' : ''
                              }`}
                          />
                        </div>
                      </>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div {...getTableBodyProps()}>
            {page.map((row: any, index: any) => {
              prepareRow(row);
              return (
                <Row row={row} onRowClick={onRowClick} {...row.getRowProps()} />
              );
            })}
          </div>
        </div>
        <GridviewFooterComponent
          rows={data.length}
          pageSize={pageSize}
          pageIndex={pageIndex}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          addRow={canAddRecords ? onRowClick : undefined}
        />
      </div>
    </>
  );
}

const generateSortingIndicator = (column: any): JSX.Element => {
  return column.isSorted ? (
    column.isSortedDesc ? (
      <FontAwesomeIcon color="#1C98CC" icon={faChevronDown} />
    ) : (
      <FontAwesomeIcon color="#1C98CC" icon={faChevronUp} />
    )
  ) : (
    <></>
  );
};
