import {
  AssortimentType,
  DropdownOption,
  DropdownOptionNummericValue,
  MissedSale,
  MissedSalesInputValues,
  Role
} from "../types/model";
import { change, Field, InjectedFormProps, reduxForm } from "redux-form";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { DropdownComponent } from "../components/InputTypes/Dropdown";
import GridviewComponent from "../components/Gridview/GridviewComponent";
import { RootState } from "../types/state";
import { MissedSalesColumns } from "../components/Gridview/tableColumns";
import {
  MissedSalesFormName
} from "../constants/formNames";
import { TextInputComponent } from "../components/InputTypes/TextInput";
import { connect } from "react-redux";
import integerOrAllSign from "../validators/integerOrAllSign";
import { getMissedSales } from "../actions/missedSalesActions";
import { checkPermission } from "../helpers/permissionHelper";
import { NoAccessContainer } from "./NoAccessContainer";
import { setFilterSettings } from "../actions/filterSettingsAction";
import { setMissedSalesFindValues } from "../actions/findValuesAction";

interface MatchParams {
  material: string;
}

const mapStateToProps = (state: RootState): any => {
  return {
    profile: state.profile,
    initialValues: {
      psm: null,
      hitrate: null,
      ert: null,
      authGroup: null,
      inAssortiment: AssortimentType.Yes,
    } as MissedSalesInputValues,
    filterSettings: state.setFilters.filterSettingsMissedSales ? state.setFilters.filterSettingsMissedSales : [] as {field: string, value: string}[],
    findValues: state.setMissedSalesFindValues,
    erts: state.erts?.erts,
    psms: state.erts?.psms,
    authGroups: state.authGroups?.authGroups,

  };
};

const mapDispatchToProps = (dispatch: any): any => ({
  getMissedSales: (values: MissedSalesInputValues) =>
    dispatch(getMissedSales(values)),
    setFilterSettings:(filterSettings: {name:string, value:string}[]) => dispatch(setFilterSettings(filterSettings, "MissedSales")),
    setTacticalPartsFindValues: (ert: string, hitrate: string, psm: string, authGroup: string, inAssortiment: string) => dispatch(setMissedSalesFindValues(ert, hitrate, psm, authGroup, inAssortiment)),
    change: (field: string, value: string) => dispatch(change(field, value, ""))
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<MatchParams> &
  InjectedFormProps<{}, any>;

const MissedSalesContainer: React.FunctionComponent<Props> = (props) => {
  var [missedSales, setMissedSales] = useState<MissedSale[]>([]);
  var [isLoading, setIsLoading] = useState<boolean>(false);

  var assortimentOptions = [
    { label: "Yes", value: AssortimentType.Yes },
    { label: "No", value: AssortimentType.No },
    { label: "All", value: AssortimentType.All },
  ] as DropdownOptionNummericValue[];

  const sumbitPartsPerPsm = async (values: MissedSalesInputValues) => {
    if (!values.inAssortiment) values.inAssortiment = AssortimentType.Yes;
    setIsLoading(true);
    await getMissedSales({ ...values, hitrate: values.hitrate === "*" ? 1000 : values.hitrate }).then(async (ms) => {
      props.setTacticalPartsFindValues(values.ert, values.hitrate, values.psm, values.authGroup, values.inAssortiment);
      setIsLoading(false);
      setMissedSales(ms);
    });
  };
  
  const onFilterChanged = (field: string, value: string) => {
    var settings = props.filterSettings;

    var filter = settings.find((x) => x.field === field);

    if (!filter)
      settings = [...settings, {field: field, value: value}]
    else
      settings[settings.indexOf(filter)].value = value;

    props.setFilterSettings(settings);
  }
  
  const setPreviousState = () => {
    sumbitPartsPerPsm(props.findValues);
    props.change('ert', props.findValues.ert);
    props.change('hitrate', props.findValues.hitrate);
    props.change('psm', props.findValues.psm)
    props.change('authGroup', props.findValues.authGroup);
    props.change('inAssortiment', props.findValues.inAssortiment);
  }

  useEffect(() => {
    if (props.findValues.ert !== "") {
      setPreviousState();
    }
  }, [])

  var erts: DropdownOption[] = [];
  erts = props.erts ? props.erts.map(e => { return { label: e.ert, value: e.ert } as DropdownOption })
  : [];

  var psms: DropdownOption[] = [];
  psms = props.psms ? props.psms.map(e => { return { label: e, value: e } as DropdownOption })
  : [];

  var authGroups: DropdownOption[] = [];
  authGroups = props.authGroups ? props.authGroups.map(e => { return { label: e.authGroup, value: e.authGroup } as DropdownOption })
  : [];

  return checkPermission(props.profile, Role.DashboardExportUser) ? (
    <>
      <form
        className="tactical-parts-form"
        onSubmit={props.handleSubmit((e: any) => {
          sumbitPartsPerPsm(e);
        })}
      >
        <div className="columns tactical-parts-header">
          <div className="column">
            <div className="columns">
              <div className="column">
                <Field
                  key={2}
                  options={erts}
                  component={DropdownComponent}
                  name="ert"
                  required={true}
                  isMulti={true}
                  label="ERT:"
                  labelInComponent={true}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="columns">
              <div className="column">
                <Field
                  key={2}
                  component={TextInputComponent}
                  name="hitrate"
                  required={true}
                  label="Hitrate:"
                  labelInComponent={true}
                  validate={integerOrAllSign}
                />
              </div>
              <div className="column description pt-0">
                eg 0.25 to see 25% or lower * for all
              </div>
            </div>
          </div>
          <div className="column is-3">
            <div className="columns">
              <div className="column">
                <Field
                  key={2}
                  options={psms}
                  component={DropdownComponent}
                  isMulti={true}
                  name="psm"
                  required={true}
                  label="PSM:"
                  labelInComponent={true}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="columns">
              <div className="column">
                <Field
                  key={2}
                  options={authGroups}
                  component={DropdownComponent}
                  name="authGroup"
                  required={true}
                  isMulti={true}
                  label="Authorization Group:"
                  labelInComponent={true}
                />
              </div>
            </div>
          </div>
          <div className="column">
            <div className="columns">
              <div className="column">
                <Field
                  key={3}
                  options={assortimentOptions}
                  name="inAssortiment"
                  component={DropdownComponent}
                  labelInComponent={true}
                  hasDefaultOption={true}
                  label={"In assortment:"}
                />
              </div>
              <div className="column description pt-0">eg yes, no, all</div>
            </div>
          </div>
          <div className="column">
            <div className="columns">
              <div className="column">
                <button className="tactical-parts-button">
                  Find Missed sales
                </button>
              </div>
              <div className="column description pt-0">
                {missedSales && missedSales.length > 0
                  ? `Total results: ${missedSales.length}`
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="main-container tactical-parts-container mt-5">
        <GridviewComponent
          columns={MissedSalesColumns()}
          isTacticalParts={true}
          data={missedSales}
          isLoading={isLoading}
          onFilterChanged={onFilterChanged}
          defaultFilter={props.filterSettings}
        />
      </div>
    </>
  ) : (
    <NoAccessContainer />
  );
};
const MissedSalesReduxForm = reduxForm<{}, any>({
  form: MissedSalesFormName,
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
})(MissedSalesContainer);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MissedSalesReduxForm)
);
