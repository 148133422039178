import * as types from '../types/actionTypes/severityGroupsType';

import { SeverityGroupsAction } from "../types/actionTypes/severityGroupsType";

import { AsyncAction } from "./";
import SeverityGroupsApi from '../api/SeverityGroupsApi';

export const getSeverityGroups = (): AsyncAction<SeverityGroupsAction> => {
    return async (dispatch) => {
        var severityGroups = await SeverityGroupsApi.getSeverityGroups();
        return dispatch({ type: types.GET_SEVERITY_GROUPS, severityGroups });
    }
}