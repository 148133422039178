import { Role, UserInfo } from '../types/model';

import { AccountInfo } from '@azure/msal-browser';

export const checkPermission = (profile: AccountInfo | undefined, minUserRole: Role): boolean => {
    if (!profile)
        return false;
    var profileInfo = profile as UserInfo;



    if (!profileInfo.idTokenClaims?.roles)
        return false;

    if (minUserRole === Role.User) {
        var endresultUser = false;
        profileInfo.idTokenClaims.roles.forEach(element => {
            var result = [Role.SuperUser.toString(), Role.Admin.toString(), Role.User.toString(), Role.DashboardExportUser.toString()].includes(element)
            if (result) {
                endresultUser = true;
            }
        });
        return endresultUser;
    }
    if (minUserRole === Role.DashboardExportUser) {
        var endresultDashboardExportUser = false;
        profileInfo.idTokenClaims.roles.forEach(element => {
            var result = [Role.SuperUser.toString(), Role.DashboardExportUser.toString(), Role.Admin.toString()].includes(element)
            if (result) {
                endresultDashboardExportUser = true;
            }
        });
        return endresultDashboardExportUser;
    }
    if (minUserRole === Role.SuperUser) {
        var endresultSuperUser = false;
        profileInfo.idTokenClaims.roles.forEach(element => {
            var result = [Role.SuperUser.toString(), Role.Admin.toString()].includes(element)
            if (result) {
                endresultSuperUser = true;
            }
        });
        return endresultSuperUser;
    }
    if (minUserRole === Role.Admin) {
        var endresultAdmin = false;
        profileInfo.idTokenClaims.roles.forEach(element => {
            var result = [Role.Admin.toString()].includes(element)
            if (result) {
                endresultAdmin = true;
            }
        });
        return endresultAdmin;
    }
    return false
}
