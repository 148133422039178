import { GeneralInformation, Product, ProductDropdownOption } from '../types/model';

import ApiBase from './ApiBase';

const generalInformationEndpoint = 'generalInformation' as string;

class GeneralInformationApi extends ApiBase {
    getInformation(): Promise<GeneralInformation> {
        return this.doHttpRequest<GeneralInformation>('get', `${generalInformationEndpoint}`, null, false);
    }
}

export default new GeneralInformationApi();