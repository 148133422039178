import internal from "stream";

export interface DropdownOption {
  label: string;
  value: string;
}

export interface DropdownOptionNummericValue {
  label: string;
  value: number;
}

export interface ProductDropdownOption {
  value: string;
  product: Product;
}

export interface TableColumn {
  Header: string;
  accessor?: string;
  className?: any;
  style?: any;
  disableFilters?: boolean;
  Cell?: (props?: any) => void;
  type?: RenderType;
  width: number;
  defaultValue: string;
}

export interface CompetitorPrice {
  id?: number;
  priceDate: string;
  grossPrice: number;
  nettPrice: number;
  competitor: string;
  recon: boolean;
}

export interface InfoRecord {
  purchaseInfo: string;
  supplierName: string;
  type: string;
  lastOrderDate?: Date;
  price?: number;
  per?: number;
  unit: string;
  deliveryTime?: number;
  countryOfOrigin: string;
  supplierNo: string;
  plant: string;
  currency: string;
}

export interface GlpHitory {
  globalListPrice: number;
  per: number;
  unit: string;
  validFrom: Date;
  validTo: Date;
}

export interface MaintenancePart {
  comment: string;
  ert: string;
  inspDep: string;
  interval: string;
  jobDescription: string;
  lastSuccessor: string;
  id: number;
}

export interface QteAllYear {
  ordQte: string;
  customerDescription: string;
  installation: string;
  ert: string;
  qty?: number;
  createdOn: Date;
  nettValue?: number;
  totalValue?: number;
  salesDoc: string;
  saTy: string;
  item: string;
  productType: string;
  createdBy: string;
  engineNr: string;
  customer: string;
  sorg: string;
  plnt: string;
}

export interface HitrateHistory {
  period: number;
  quoteQty: number;
  qtyQuoted: number;
  quoteEuro: number;
  orderQty: number;
  qtyOrdered: number;
  orderEuro: number;
  hitrate: number;
}

export interface UserInfo {
  homeAccountId: string;
  environment: string;
  tenantId: string;
  username: string;
  localAccountId: string;
  name?: string;
  idTokenClaims?: IdTokenClaims;
}

export interface IdTokenClaims {
  roles?: string[];
  name: string;
}

export enum Role {
  Admin = "Task.Admin",
  User = "Task.User",
  SuperUser = "Task.Superuser",
  DashboardExportUser = "Task.DashboardExportUser",
}

export interface OldDeutzSupplier {
  id: number;
  source: string;
  materialDetails: string;
  name1: string;
  name2: string;
  country: string;
  town1: string;
  town2: string;
  street: string;
  telephone: string;
  date: Date;
  qtyOrdered: number;
  nettPrice: number;
  currency: string;
  orderUnit: string;
  priceUnit: number;
}

export interface CommentSap {
  basicDataText: string;
  internalComment: string;
  purchaseOrderText: string;
  materialMemo: string;
}

export interface Product {
  atp: string;
  materialStatus?: number;
  material: string;
  materialName: string;
  comment: string;
  ert?: string;
  brand: string;
  tacticalPartGroup: string;
  groupDescription: string;
  casting: boolean;
  recon: boolean;
  nuclearUse: boolean;
  strategical: boolean;
  nonWartsillaDesign: boolean;
  shelfLife: boolean;
  maintenance: boolean;
  mainData: MainData;
  competitorPrices: CompetitorPrice[];
  maintenanceParts: MaintenancePart[];
  glpHistories: GlpHitory[];
  infoRecords: InfoRecord[];
  qteAllYears: QteAllYear[];
  hitrateHistories: HitrateHistory[];
  oldDeutzSuppliers: OldDeutzSupplier[];
  commentSap: CommentSap;
  pdfByteArray: any;
  imageBase64String: string;
  maindataLoaded: boolean;
  restdataloaded: boolean;
}

export interface Document {
  originOfDocuments: string[];
}

export interface MainData {
  totalStock: number;
  MatGroupText: string;
  availableToPr: number;
  safetyStock: number;
  iTOR: number;
  cons1Year: number;
  cons2Year: number;
  cons3Year: number;
  cons4Year: number;
  cons5Year: number;
  cons6Year: number;
  unitGlp: number;
  unitValue: number;
  unitBookValue: number;
  targetPrice: number;
  totalGlpValue: number;
  totalStockValue: number;
  uOM: string;
  totalBookValue: number;
  margin: number;
  competitor: string;
  competitorDate?: Date | string;
  competitorPrice: number;
  mwmNett: number;
  mwmGlp: number;
  quoteQty: number;
  qtyQuoted: number;
  quoteEur: number;
  aVGQuotePrice: number;
  orderQty: number;
  qtyOrdered: number;
  orderEur: number;
  avgOrderPrice: number;
  hitrate: number;
  successor: string;
  lastSuccessor: string;
  productName: string;
  productDescription2: string;
  productDescription3: string;
  materialGroupName: string;
  materialGroupText: string;
  priceFamilyName: string;
  priceFamilyDescription: string;
  psmResponsible: string;
  purchaseGroup: string;
  purchaseGroupName: string;
  materialState: string;
  materialStateDescription: string;
  salesStatus: string;
  salesStatusDescription: string;
  mrpProfile: string;
  mrpProfileDescription: string;
  availabilityCheck: string;
  availabilityCheckDescription: string;
  procType: string;
  procTypeDescription: string;
  goodProcTime: number;
  inHouseProdTime: number;
  plannedDelTime: number;
  abc: string;
  xyz: string;
  severityGroup: string;
  severityGroupDescription: string;
  agreementSupplier: string;
  supplierLastPo: string;
  supplierSecondPo: string;
  supplierThirdPo: string;
  supplierFourthPo: string;
  grossWeight: string;
  grossWeightUom: string;
  dimensions: string;
  basicMaterial: string;
  authorizationGroup: string;
  cityOfOrigin: string;
  reconStatus: string;
  partEngineTypes: PartEngineType[];
  oldPartNo: string;
  natoStockNo: string;
  spare: string;
}

export interface PartEngineType {
  id: number;
  productType: string;
  qtyEngines: number;
}

export enum AssortimentType {
  Yes = 0,
  No = 1,
  All = 2,
}

export enum ComponentType {
  MainData = 0,
  Erts = 1,
  CompetitorPrice = 2,
  Maintenance = 3,
  GlpHistory = 4,
  InfoRecord = 5,
  OrdQteAllYears = 6,
  HitrateHistory = 7,
  OldDeutzSuppliers = 8,
  CommentSap = 9,
}

export interface TacticalPartsInputValues {
  group: {label: string, value: string}[];
  ert: {label: string, value: string}[];
  inAssortiment: AssortimentType;
}

export interface MissedSalesInputValues {
  authGroup: {label: string, value: string}[] | null;
  ert: {label: string, value: string}[] | null;
  hitrate: number | string | null;
  psm: {label: string, value: string}[] | null;
  inAssortiment: AssortimentType;
}

export interface GeneralInformation {
  erts: string[];
  brands: string[];
  ssss: string[];
}

export interface TacticalParts {
  ert: string;
  group?: number;
  material: string;
  materialDescription: string;
  comment: string;
  status: string;
  aTP?: number;
  totalStock?: number;
  safet?: number;
  unitValue?: number;
  unitGlp?: number;
  totalStockValue?: number;
  totalBookValue: number;
  competitor: string;
  competitorPrice?: number;
  competitorDate?: Date;
  leadTime?: number;
  margin?: number;
  successor: string;
  agreementSupplier: string;
  cons1Year?: number;
  cons2Year?: number;
  cons3Year?: number;
  cons4Year?: number;
  cons5Year?: number;
  cons6Year?: number;
  orderEur?: number;
  quote?: number;
  qrder?: number;
  hitrate?: number;
  oldPartNo: string;
  salesStatus: string;
  targetPrice?: number;
  totalTargetPrice?: number;
  iTOR?: number;
  oneYCons?: number;
  twoYCons?: number;
  threeYCons?: number;
  xyz: string;
  lastPoSupplier: string;
  strategical?: boolean;
}

export interface SubmitProductDashboardValues {
  material: string;
  materialName: string;
  comment: string;
  ert?: string;
  brand: string;
  tacticalPartGroup: string;
  groupDescription: string;
  casting: boolean;
  recon: boolean;
  nuclearUse: boolean;
  strategical: boolean;
  nonWartsillaDesign: boolean;
  shelfLife: boolean;
  maintenance: boolean;
  competitorPrices: any;
  maintenanceParts: any;
  mainData: any;
  oldDeutzSuppliers: any;
}

export interface SubmitNewPartValues {
  material: string;
  ert: string;
  group: string;
  erts: SubmitErtSelectionValues;
}

export interface SubmitErtSelectionValues {
  d620: boolean;
  d604BX: boolean;
  d604B: boolean;
  d604: boolean;
  d603: boolean;
  d602: boolean;
  d601: boolean;
  d616: boolean;
  d234: boolean;
  d232: boolean;
  d816: boolean;
  d716: boolean;
  d618: boolean;
  d518: boolean;
  d1822: boolean;
  uD18: boolean;
  uD23: boolean;
  uD25: boolean;
  uD150: boolean;
  uDPD: boolean;
  uDPY: boolean;
  uDPZ: boolean;
  uD30: boolean;
  uD33: boolean;
  uD45: boolean;
  d528: boolean;
  d628: boolean;
  d440: boolean;
  d441: boolean;
  d444: boolean;
  f10: boolean;
  f20: boolean;
  f30: boolean;
  wN25: boolean;
  wN25DF: boolean;
  w25SG: boolean;
  a20: boolean;
  a25: boolean;
  aST25: boolean;
  s20: boolean;
  stork150: boolean;
  stork210: boolean;
  stork240: boolean;
  stork280: boolean;
  wichMannAC: boolean;
  wichMannAX: boolean;
  wichMann28: boolean;
  bolnes190: boolean;
  cR26: boolean;
  vasa14: boolean;
  vasa22: boolean;
  vasa22And26: boolean;
  vasa24: boolean;
  stork410: boolean;
  stork620: boolean;
  gMT210: boolean;
  gMT230: boolean;
  gMT320: boolean;
  gMT420: boolean;
  d350: boolean;
  d358: boolean;
  d484: boolean;
  d500: boolean;
  d501: boolean;
  d510: boolean;
  d511: boolean;
  d540: boolean;
  d545: boolean;
  d640: boolean;
  d645: boolean;
  dummy: boolean;
}

export interface CategoryErt {
  category: string;
  erts: Ert[];
}

export interface Group {
  label: string;
  value: string;
}

export interface Brand {
  label: string;
}

export interface Psm {
  label: string;
}

export interface AuthGroup {
  authGroup: string;
}

export interface PartsPerPsm {
  psm: string;
  brand: string;
  ert: string;
  group: number | null;
  description035Group: string;
  plantMatStatus: string;
  plantStatusDescription: string;
  matGroupText: string;
  material: string;
  materialDescription: string;
  salesStatus: string;
  salesStatusDescription: string;
  avCheck: string;
  aVCheckDescription: string;
  lO: string;
  totalGlpValue: number | null;
  lOName: string;
  comment: string;
  totalStock: number | null;
  unitValue: number | null;
  aTP: number | null;
  totStockValue: number | null;
  bookValue: number | null;
  totBookValue: number | null;
  unitGlp: number | null;
  itor: number | null;
  margin: number | null;
  safet: number | null;
  threeYearAvgCons: number | null
  cons1Y: number | null;
  cons2Y: number | null;
  cons3Y: number | null;
  cons4Y: number | null;
  cons5Y: number | null;
  cons6Y: number | null;
  last1PO: string;
  last2PO: string;
  last3PO: string;
  last4PO: string;
  sucessor: string;
  agreementSupplier: string;
  lastSuccessor: string;
  finalNumber: string;
  orderEur: number | null;
  qtyOrdered: number | null;
  orderQty: number | null;
  qtyQuoted: number | null;
  quoteEur: number | null;
  quoteQty: number | null;
  hitrate: number | null;
  pdtLT: number | null;
  ipt: number | null;
  purchaseGroup: string;
  purchaseGroupName: string;
  dimensions: string;
  targetPrice: number | null;
  oldPartNo: string;
  nsn: string;
  mwmGlp: number | null;
  mwmNett: number | null;
  authGroup: string;
  competitor: string;
  compPrice: number | null;
  compDate: string | null;
  severityGroup: string;
  severityGroupDescription: string;
  strategical: boolean | null;
  maintenance: boolean | null;
  shelfLife: boolean | null;
  recon: boolean | null;
  filter: boolean | null;
  statusDescription: string;
  mrpProf: string;
  mrpProfDescription: string;
  productHier: string;
  hierDesc2: string;
  hierDesc3: string;
  family: string;
  familyDescription: string;
  materialGroup: string;
  materialGroupDescription: string;
  procurementType: string;
  grProcessingTime: number | null;
  abc: string;
  xyz: string;
  agr: string;
  weightUom: string;
  grossWeight: string;
  basicMaterial: string;
  uM: string;
  allErts: string;
  eTL: string;
  reconStatus: string;
  cityOfOrigin: string;
}

export interface MissedSale {
  avCheck: string;
  comment: string;
  compDate: Date | null;
  compPrice: number | null;
  competitor: string;
  ert: string;
  group: number;
  hitrate: number;
  last1Po: string;
  margin: number;
  material: string;
  materialDescription: string;
  a: number;
  orderEur: number;
  orderQty: number;
  qtyOrdered: number;
  qtyQuoted: number;
  quoteEur: number;
  quoteQty: number;
  safet: number;
  ssss: string;
  status: string;
  successor: string;
  targetPrice: null;
  totBookValue: number;
  totStk: number;
  totStockValue: number;
  unitGlp: number;
  unitValue: number;
}

export enum RenderType {
  Date = 1,
  Bool = 2,
  Money = 3,
  Decimal = 4,
  TrueOrEmpty = 5,
  Percentage = 6,
  String = 7,
  Number = 8,
}

export interface PartsPerPsmFormValues {
  psm: {labe: string, value: string}[];
  brand: {labe: string, value: string}[];
  ert: {labe: string, value: string}[];
}

export interface DashboardExportInputValues {
  material: string;
  quantity: number;
  totalValue: number;
  indexRow: number;
}

export interface DashboardExport {
  sequenceNumber: number | null;
  material: string;
  searchMaterial: string;
  quantity: number | null;
  translatedMaterial: string;
  translatedATP: number | null;
  finalSuccessor: string;
  aTP: number | null;
  uM: string;
  iTOR: number | null;
  stockSufficient: string;
  safet: number | null;
  unitValue: number | null;
  totalCostEur: number | null;
  gLPUnit: number | null;
  totalGlpValue: number | null;
  mARGIN: number | null;
  totalValueEur: number | null;
  singleValueEur: number | null;
  mWMPLP: number | null;
  competitor: string;
  competitorprice: number | null;
  competitordate: string;
  convertedDate: string | null;
  targetPrice: number | null;
  sTATUS: string;
  eRT: string;
  pDT_LT: number | null;
  lAST1PO: string;
  comment: string;
  oldPartNo: string;
  gROUP: number | null;
  groupDescription: string;
  lo: string;
  salesStatus: string;
  cONS1Y: number | null;
  cONS2Y: number | null;
  cONS3Y: number | null;
  cONS4Y: number | null;
  cONS5Y: number | null;
  cONS6Y: number | null;
  threeYearAvgCons: number | null;
  quoteQty: number | null;
  qtyQuoted: number | null;
  qUOTE_EUR: number | null;
  orderQty: number | null;
  qtyOrdered: number | null;
  oRDER_EUR: number | null;
  hITRATE: number | null;
  nSN: string;
  agreementSupplier: string;
  purchaseGroupName: string;
  sSSS: string;
  bookValue: number | null;
  totBookValue: number | null;
  materialDescription: string;
  severityGroup: string;
  severityGroupDescription: string;
  avCheck: string;
  authGroup: string;
  mrpProf: string;
  totStk: number | null;
  totStockValue: number | null;
  grProcessingTime: number;
  procType: string;
  recon: number;
  aBC: string;
  xYZ: string;
  aGR: string;
  countryOfOrigin: string;
  last1PO: string;
  last2PO: string;
  last3PO: string;
  last4PO: string;
  grossweight: number | null;
  weightuom: string;
  dimensions: string;
  productHierarchy: string;
  prodHierDesc2: string;
  matlGroup: string;
  materialGroupDescription: string;
}

export interface Ert {
  brand: string;
  ert: string;
  productGroup: string;
  inDashboard: boolean;
  costToServe: number;
  ssss: string;
}

export interface Filter{
  field: string;
  value: string;
}

export interface FilterSettings {
  filterSettingsPartsPerPSM: Filter[];
  filterSettingsTacticalParts: Filter[];
  filterSettingsMissedSales: Filter[];
}

export interface FindValues {
  tacticalParts: {group: string, ert: string, inAssortiment: string};
  partsPerPSM: {psm: string, brand: string, ert: string};
  missedSales: {ert: string, hitrate: string, psm: string, authGroup: string, inAssortiment: string};
}

export interface SeverityGroup {
  severityGroup1: string;
  severityGroupDescription: string;
  extendedDescription: string;
}

export interface TacticalPartGroup {
  group: string;
  description: string;
  type: number;
  classHighSpeed: string;
  classMediumSpeed: string;
}