import React, {
  FunctionComponent,
  useState,
} from "react";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  Field,
  getFormValues,
  InjectedFormProps,
  reduxForm,
  registerField,
  unregisterField,
} from "redux-form";
import { TextInputComponent } from "../components/InputTypes/TextInput";
import GridviewComponent from "../components/Gridview/GridviewComponent";
import { DashboardExportFormName } from "../constants/formNames";
import { DashboardExport, DashboardExportInputValues, Role } from "../types/model";
import { getDashboardExport, getDashboardExportByXlsx } from "../actions/dashboardExportActions";
import { DashboardExportColumns } from "../components/Gridview/tableColumns";
import { checkPermission } from "../helpers/permissionHelper";
import { NoAccessContainer } from "./NoAccessContainer";

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    initialValues: {
      rows: [{ material: "", quantity: 0, totalValue: 0, index: 0 }],
    },
    formValues: getFormValues(DashboardExportFormName)(state) as any,
  };
};

const mapDispatchToProps = (dispatch: any): any => ({
  dispatch,
  // updateFormValue: (field: string, value: string) =>
  //   dispatch(change(DashboardExportFormName, field, value)),
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  InjectedFormProps<{}, any>;

const DashboardExportContainer: FunctionComponent<Props> = (props) => {
  var [dashboardExport, setDashboardExport] = useState<DashboardExport[]>([]);
  var [isLoading, setIsLoading] = useState<boolean>(false);
  const [xlsxFile, setXlsxFile] = useState<File | null>(null);
  const fileRef = React.createRef();

  const initialValues = (index: number): DashboardExportInputValues => {
    return {
      material: "",
      quantity: 0,
      totalValue: 0,
      indexRow: index,
    };
  };

  const sumbitPartsPerPsm = async (values: DashboardExportInputValues[]) => {
    setIsLoading(true);
    await getDashboardExport(values).then(async (ms) => {
      setIsLoading(false);
      setDashboardExport(ms);
    });
  };

  const sumbitXlsxPartsPerPsm = async (value: File) => {
    setIsLoading(true);
    await getDashboardExportByXlsx(value).then(async (ms) => {
      setIsLoading(false);
      setDashboardExport(ms);
    });
  };

  const manageFields = (isAddingRows: boolean, index?: any) => {
    var { formValues } = props;
    var initialVal = initialValues(isAddingRows ? formValues.rows.length : 3);

    Object.keys(initialVal).forEach((key) => {
      if (isAddingRows) {
        props.dispatch(
          registerField(
            DashboardExportFormName,
            `rows[${formValues.rows.length}].${key}`,
            "Field"
          )
        );

        props.change(
          `rows[${formValues.rows.length}].${key}`,
          initialVal[key as keyof DashboardExportInputValues] as any
        );
      } else {
        props.dispatch(
          unregisterField(DashboardExportFormName, `rows[${index}].${key}`)
        );
      }
    });

    if (!isAddingRows) {
      formValues.rows.splice(index, 1);
      props.change(DashboardExportFormName, formValues);
    }
  };


  const submit = () => {
    const file = xlsxFile;
    const reader = new FileReader();

    if (file != null) {
      sumbitXlsxPartsPerPsm(file);
    }
  };

  const handleClickUpload = (): void => {
    const fileElem = fileRef.current as any;
    if (fileElem) {
      fileElem.click();
    }
  };

  return checkPermission(props.profile, Role.DashboardExportUser) ? (
    <>
      <form
        className=""
        onSubmit={props.handleSubmit((e: any) => {
          sumbitPartsPerPsm(e.rows);
        })}
      >
        <div className="columns dashboard-export-header">
          <div className="column">
            <div
              className="columns tactical-parts-header dashboard-export-add-row"
              style={{
                flexDirection: "column",
                maxHeight: "300px",
                borderRight: "1px solid gray",
                overflowY: "auto",
              }}
            >
              <div
                className="column"
                style={{
                  padding: "0px 0.75rem 0px 0.75rem",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Manual
              </div>

              {props.formValues &&
                props.formValues.rows.map((e, i) => {
                  return (
                    <>
                      <div className="column">
                        <div
                          className="columns"
                          style={{ flexDirection: "row" }}
                        >
                          <div className="column">
                            <div className="columns">
                              <div className="column">
                                <Field
                                  key={2}
                                  component={TextInputComponent}
                                  name={`rows[${i}].material`}
                                  required={true}
                                  label="Material:"
                                  labelInComponent={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="column">
                            <div className="columns">
                              <div className="column">
                                <Field
                                  key={2}
                                  component={TextInputComponent}
                                  name={`rows[${i}].quantity`}
                                  required={true}
                                  label="quantity:"
                                  labelInComponent={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="column">
                            <div className="columns">
                              <div className="column">
                                <Field
                                  key={2}
                                  component={TextInputComponent}
                                  name={`rows[${i}].totalValue`}
                                  required={true}
                                  label="Total Value:"
                                  labelInComponent={true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="column is-2">
                            <div className="columns">
                              <div className="column">
                                {i !== 0 && (
                                  <button
                                    type="button"
                                    className="tactical-parts-button"
                                    onClick={() => {
                                      manageFields(false, i);
                                    }}
                                  >
                                    Remove
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              <div className="column">
                <div
                  className="columns"
                  style={{ flexDirection: "row", display: "flex" }}
                >
                  <div className="column">
                    <button
                      type="button"
                      className="tactical-parts-button"
                      onClick={() => {
                        manageFields(true);
                      }}
                    >
                      Add row
                    </button>
                  </div>
                  <div className="column"></div>
                  <div className="column">
                    <button className="tactical-parts-button">Find</button>
                  </div>
                  <div className="column is-2"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-3">
            <div
              className="columns"
              style={{ flexDirection: "column", marginTop: "-5px" }}
            >
              <div
                className="column"
                style={{
                  padding: "0px 0.75rem 0px 0.75rem",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Upload by XLSX
              </div>
              <div className="column" style={{ textAlign: "center" }}>
                {xlsxFile ? xlsxFile.name : "No File Uploaded"}
                <div className="columns" style={{ marginTop: "10px" }}>
                  <div className="column">
                    <button
                      type="button"
                      className="tactical-parts-button"
                      onClick={() => handleClickUpload()}
                    >
                      Upload
                    </button>
                  </div>
                  <div className="column">
                    <button
                      className="tactical-parts-button"
                      onClick={(e) => {
                        e.preventDefault();
                        if (xlsxFile) submit();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
                <input
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    if (e.currentTarget && e.currentTarget.files && e.currentTarget.files?.length > 0)
                      setXlsxFile(e.currentTarget.files[0]);
                  }}
                  readOnly={true}
                  ref={fileRef as any}
                  type="file"
                  style={{ display: "none" }}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="main-container tactical-parts-container mt-5">
        <GridviewComponent
          columns={DashboardExportColumns()}
          data={dashboardExport}
          isLoading={isLoading}
        />
      </div>
    </>
  ) : (
    <NoAccessContainer />
  );
};

const DashboardExportReduxForm = reduxForm<{}, any>({
  form: DashboardExportFormName,
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
})(DashboardExportContainer);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DashboardExportReduxForm)
);
