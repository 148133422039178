import { generalReducer, tabIndexReducer } from './generalReducer';
import { productReducer, productsReducer } from './productReducer';

import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { loadingReducer } from './loadingReducer';
import { profileReducer } from './profileReducer';
import { setFilterSettingsReducer } from './filterSettingsReducer';
import { setMissedSalesFindValues, setPartsPerPSMFindValues, setTacticalPartsFindValues } from './findValuesReducer';
import { severityGroupsReducer } from './severityGroupsReducer';
import { getAllErts } from './ertsReducer';
import { getTacticalPartGroups } from './tacticalPartsReducer';
import { getAuthGroups } from './authGroupsReducer';

export const rootReducer = combineReducers({
    form: formReducer,
    product: productReducer,
    products: productsReducer,
    isLoading: loadingReducer,
    profile: profileReducer,
    generalInformation: generalReducer,
    tabIndex: tabIndexReducer,
    setFilters: setFilterSettingsReducer,
    setPartsPerPSMFindValues: setPartsPerPSMFindValues,
    setTacticalPartsFindValues: setTacticalPartsFindValues,
    setMissedSalesFindValues: setMissedSalesFindValues,
    severityGroups: severityGroupsReducer,
    erts: getAllErts,
    tacticalPartGroups: getTacticalPartGroups,
    authGroups: getAuthGroups,
})