import {
  DropdownOption,
  Ert,
  Product,
  Role,
  TacticalPartGroup,
} from "../types/model";
import React, { FunctionComponent, useEffect } from "react";

import { AccountInfo } from "@azure/msal-browser";
import { Button } from "./InputTypes/Button";
import { Checkbox } from "./InputTypes/Checkbox";
import { DropdownComponent } from "../components/InputTypes/Dropdown";
import { Field, InjectedFormProps, reduxForm, WrappedFieldProps } from "redux-form";
import { AnyAction } from "redux";
import { Modal } from "./ModalComponent";
import { ProductInformationButtonStyle } from "../constants/styles";
import { RootState } from "../types/state";
import { Style } from "../constants/general";
import { TextInputComponent } from "../components/InputTypes/TextInput";
import { checkPermission } from "../helpers/permissionHelper";
import { connect } from "react-redux";
import { getDocuments } from "../actions/documentActions";
import { getSparePartPage } from "../actions/sparePartPageActions";
import { ProductDashboardFormName } from "../constants/formNames";
import { ThunkDispatch } from "redux-thunk";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface productInfoProps{
}

interface mapStateToPropsType {
  erts?: Ert[] | null;
  product?: Product | undefined;
  profile?: AccountInfo | undefined;
  brands: string[] | undefined;
  tacticalPartGroups: TacticalPartGroup[];
}

const mapStateToProps = (state: RootState): mapStateToPropsType => {
  return {
    erts: state.erts?.erts,
    product: state.product,
    profile: state.profile,
    brands: state.erts?.brands,
    tacticalPartGroups: state.tacticalPartGroups?.tacticalPartGroups,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, AnyAction>
) => ({
  
});


type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<productInfoProps> &
  InjectedFormProps<{}, any>;

    

const ProductInformationComponent: FunctionComponent<Props> = ({
  erts,
  product,
  profile,
  change,
  brands,
  tacticalPartGroups,
}) => {
  var [showModal, setShowModal] = React.useState(false);
  var [contentMessage, setContentMessage] = React.useState("");
  var [headerMessage, setHeaderMessage] = React.useState("");
  var [ertBrandOptions, setErtBrandOptions] = React.useState<{label: string, value: string}[]>([]);
  var [brandErtOptions, setBrandErtOptions] = React.useState<{label: string, value: string}[]>([]);
  var [tacticalPartDescription, setTacticalPartDescription] = React.useState("");


  useEffect(() => {
    if (product) {
      OnErtChange(product.ert);
      OnBrandChange(product.brand);
      if (tacticalPartGroups) {
        GetTacticalPartDescription(product.tacticalPartGroup)
      }
    }
  }, [product, tacticalPartGroups]);

  var brandOptions = brands
    ? brands.map((brand) => {
      return { value: brand, label: brand };
    })
    : ([] as DropdownOption[]);

  if (brandErtOptions.length > 0)
    brandOptions = brandErtOptions;

  var ertOptions = erts
    ? erts.map((e, i) => {
      return { value: e.ert, label: e.ert };
    })
    : ([] as DropdownOption[]);

  if (ertBrandOptions.length > 0)
    ertOptions = ertBrandOptions;

  const groups = tacticalPartGroups
  ? tacticalPartGroups.map((g) => {
    return { value: g.group, label: g.group + " | " + g.description  };
  })
  : ([] as DropdownOption[]);

  const base64ToArrayBuffer = (base64: string) => {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  const downloadByteArray = (reportName: string, byte: Uint8Array) => {
    var blob = new Blob([byte], { type: "application/pdf" });
    const fileURL = window.URL.createObjectURL(blob);
    const pdfWindow = window.open();
    if (pdfWindow) pdfWindow.location.href = fileURL;
  };

  const onClickChangeDocument = async () => {
    var documents = await getDocuments(product!.material);

    if (document && documents.originOfDocuments.length === 0) {
      setShowModal(true);
      setContentMessage("Not found");
      setHeaderMessage("There are no documents for this article");
      return;
    }
    for (let i = 0; i < documents.originOfDocuments.length; i++) {
      window.open(documents.originOfDocuments[i]);
    }
  };

  const onClickPdm = () => {
    let material = product?.material.toUpperCase();
    window.open(
      "https://reportingservices.wartsila.com/ReportServer?/PDM/Reports/R02_Part%20List&rs:Command=Render&P_ITEM_ID_IN=" +
      material +
      "&P_ITEM_REVISION_ID_IN=&P_PRODUCT_FACTORY_IN="
    );
  };

  const onClickSparePartPage = async () => {
    var brandIsDeutz = product?.brand.toLowerCase() === "deutz" || product?.brand.toLowerCase() === "nohab" || product?.brand.toLowerCase() === "vasa";

    var containerName = brandIsDeutz ? product?.brand : product?.ert;
    var document = product?.mainData?.spare;

    if (containerName && document) {
      var sparePartPage = await getSparePartPage(containerName, document);
      if (sparePartPage) {
        var convertedBytes = base64ToArrayBuffer(sparePartPage);
        downloadByteArray("Spare part page", convertedBytes);
        return;
      }
    }
    setShowModal(true);
    setContentMessage("Not found");
    setHeaderMessage("There is no spare part page for this article");
  };

  const GetTacticalPartDescription = async (value: string) => {
    var description = tacticalPartGroups.find((x) => x.group === value)?.description;


    if (description) {
      setTacticalPartDescription(value + " | " + description)
      description = description?.replace(value + " | ", "");
      change("groupDescription", description);
      return;
    }
    if (value) setTacticalPartDescription(value)
  }

  const OnBrandChange = async (value) => {
    var ertsBrands = erts?.filter(x => x.brand === value);
    var options = ertsBrands?.map((x) => { return {value: x.ert, label: x.ert}; });
    if (options)
      setErtBrandOptions(options);

    if (options?.length === 1) {
      change("ert", options[0].label);
      updatePsmResponsible(options[0].value)
    } else {
      change("brand", value);
    }
  }

  const OnErtChange = async (value) => {

    var brandsErts = erts?.filter(x => x.ert === value);
    var options = brandsErts?.map((x) => { return {value: x.brand, label: x.brand}; });
    if (options)
      setBrandErtOptions(options);
    if (options?.length === 1) {
      change("brand", options[0].label);
      OnBrandChange(options[0].value);
    } else {
      change("ert", value);
    }
    updatePsmResponsible(value);    
  }

  const updatePsmResponsible = (value) => {
    var ert = erts?.find((x) => x.ert === value);
    change("mainData.psmResponsible", ert?.ssss);
  }

  var hasPermission = checkPermission(profile, Role.SuperUser);

  return showModal ? (
    <Modal
      styleType={Style.Warning}
      content={headerMessage}
      title={contentMessage}
      onClose={() => setShowModal(false)}
    />
  ) : (
    <>
      <div className="columns">
        <div className="column is-2">
          <div className="columns is-flex-direction-column">
            <div className="column">
              <Field
                options={ertOptions}
                name="ert"
                component={
                  hasPermission ? DropdownComponent : TextInputComponent
                }
                labelInComponent={true}
                readOnly={!checkPermission(profile, Role.SuperUser)}
                label={"ERT"}
                onChange={OnErtChange}
                isClearable={true}
              />
            </div>
            <div className="column">
              <Field
                name="brand"
                component={
                  hasPermission ? DropdownComponent : TextInputComponent
                }
                options={brandOptions}
                readOnly={!checkPermission(profile, Role.SuperUser)}
                labelInComponent={true}
                label={"Brand"}
                onChange={OnBrandChange}
                isClearable={true}
              />
            </div>
          </div>
        </div>
        <div className="column">
          <div className="columns is-flex-direction-column">
            <div className="column">
              <div className="columns">
                <div className="column" style={{ maxWidth: "220px" }}>
                  <Field
                    component={TextInputComponent}
                    name="material"
                    label="Material"
                    readOnly={true}
                    labelInComponent={true}
                  />
                </div>
                <div className="column">
                  <Field
                    component={TextInputComponent}
                    name="materialName"
                    readOnly={!checkPermission(profile, Role.SuperUser)}
                    labelInComponent={true}
                  />
                </div>
              </div>
            </div>
            <div className="column">
              <Field
                component={TextInputComponent}
                name="comment"
                readOnly={!checkPermission(profile, Role.DashboardExportUser)}
                label="Comment"
                labelInComponent={true}
              />
            </div>
          </div>
        </div>
        <div className="column is-4">
          <div className="columns is-flex-direction-column">
            <div className="column">
              <Field
                name="tacticalPartGroup"
                component={(fieldProps: WrappedFieldProps) => {
                  const input = {...fieldProps.input, onFocus: (e) => e.preventDefault(), onBlur: (e) => e.preventDefault()};
                  input.value = tacticalPartDescription;
                  return (
                    <TextInputComponent
                      input={input}
                      meta={fieldProps.meta}
                      label="Tactical part group"
                      labelInComponent={true} 
                      readOnly
                    />
                  )
                }}

              />
              <Field
                component={TextInputComponent}
                name="groupDescription"
                readOnly={true || !checkPermission(profile, Role.SuperUser)}
                labelInComponent={true}
                defaultValue="test"
                hidden={true}
              />
            </div>
          </div>
          <div className="columns is-narrow" style={{ minWidth: "350px" }}>
            <div className="column is-5">
              <Button
                text="Drawing / change document"
                disabled={product === null || !checkPermission(profile, Role.SuperUser)}
                onClick={onClickChangeDocument}
                style={ProductInformationButtonStyle}
              />
            </div>
              <div className="column is-3">
                <Button
                  text="PDM"
                  disabled={!checkPermission(profile, Role.User)}
                  onClick={onClickPdm}
                  style={ProductInformationButtonStyle}
                />
              </div>
              <div className="column">
                <Button
                  text="Spare Part Page"
                  disabled={!checkPermission(profile, Role.User)}
                  onClick={onClickSparePartPage}
                  style={ProductInformationButtonStyle}
                />
              </div>
          </div>
        </div>
      </div>
      <div className="columns">
        <div className="column is-narrow">
          <Field
            component={Checkbox}
            name={"casting"}
            label={"Casting/Forging"}
            readOnly={!checkPermission(profile, Role.SuperUser)}
          />
        </div>
        <div className="column is-narrow">
          <Field
            component={Checkbox}
            name={"recon"}
            label={"Recon"}
            readOnly={!checkPermission(profile, Role.SuperUser)}
          />
        </div>
        <div className="column is-narrow">
          <Field
            component={Checkbox}
            name={"nuclearUse"}
            label={"Nuclear Use"}
            readOnly={!checkPermission(profile, Role.SuperUser)}
          />
        </div>
        <div className="column is-narrow">
          <Field
            component={Checkbox}
            name={"strategical"}
            label={"Strategical"}
            readOnly={!checkPermission(profile, Role.SuperUser)}
          />
        </div>
        <div className="column is-narrow">
          <Field
            component={Checkbox}
            name={"nonWartsillaDesign"}
            label={"Non-Wartsila Design"}
            readOnly={!checkPermission(profile, Role.SuperUser)}
          />
        </div>
        <div className="column is-narrow">
          <Field
            component={Checkbox}
            name={"shelfLife"}
            label={"Shelf Life"}
            readOnly={!checkPermission(profile, Role.SuperUser)}
          />
        </div>
        <div className="column is-narrow">
          <Field
            component={Checkbox}
            name={"maintenance"}
            label={"Maintenance"}
            readOnly={!checkPermission(profile, Role.SuperUser)}
          />
        </div>
      </div>
    </>
  );
};

const PartsPerPsmReduxForm = reduxForm<{}, any>({
  form: ProductDashboardFormName,
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
})(ProductInformationComponent);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PartsPerPsmReduxForm)
);
