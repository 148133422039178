import Select, { OptionsType, components } from 'react-select';

import React, { Children } from 'react';
import { Field, WrappedFieldProps } from 'redux-form';

interface Props {
    options: OptionsType<{ label: string; value: string }>;
    label?: string;
    readOnly?: boolean
    showSideBySide?: boolean;
    style?: React.CSSProperties;
    labelInComponent?: boolean
    onValueChanged?: (value: any) => void;
    isBorderError?: boolean
    hasDefaultOption?: boolean;
    required?: boolean;
    isMulti?: boolean;
    isClearable?: boolean;
}

export type DropdownProps = WrappedFieldProps & Props;

const ValueContainer = ({children, ...props}) => {
    if (!props.hasValue || !props.isMulti) {
        return (
            <components.ValueContainer {...children} {...props}>
                {children}
            </components.ValueContainer>
        );
    }

    if (children && children.length > 0) {
        const CHIPS_LIMIT = 1;
        const [chips] = children;
        const overflowCounter = chips.slice(CHIPS_LIMIT).length;
        const displayChips = chips.slice(overflowCounter, overflowCounter + CHIPS_LIMIT);
        const overflow = overflowCounter > 0 && `+ ${overflowCounter}`
        const display = [displayChips, children[1]]
        return (
            <components.ValueContainer {...children} {...props}>
                {display}
                {overflow}
            </components.ValueContainer>
        );
    } 
    return (
        <components.ValueContainer {...children} {...props}>
            {children}
        </components.ValueContainer>
    );
};


export const DropdownComponent = (props: DropdownProps) => {
    const { input, options, labelInComponent, meta: { touched, error }, hasDefaultOption, label, style, readOnly, isMulti, isClearable } = props;

    var optionsAll = [...options];

    if (isMulti) {
        optionsAll = [{ label: "All", value: "*" }, ...options]
    }

    var value = optionsAll && optionsAll.find((o: { value: string, label: string }) => o?.value?.toString() === input.value.toString());

    var values = input.value as { label: string, value: string }[];

    if (isMulti && values.length > 0 && values.find((o) => {
        if (o.value)
            return o.value.toString() === "*";
        return o.label === "All";
    })) {
        optionsAll = [{ label: "All", value: "*" }]
        values = [{ label: "All", value: "*" }]
    }

    var reactSelect = <>
        <Select
            className="field"
            options={optionsAll}
            value={isMulti ? values : value}
            onChange={(option: any) => {
                if (isMulti)
                    input.onChange(option);
                else
                    input.onChange(option ? option.value : "");
            }}
            isDisabled={readOnly}
            isSearchable={true}
            defaultValue={hasDefaultOption ? options[0] : null}
            isMulti={isMulti}
            closeMenuOnSelect={!isMulti || values.length < 1}
            hideSelectedOptions={false}
            components={{ ValueContainer: ValueContainer }}
            isClearable={isClearable}
        />
    </>

    var inputComponent = labelInComponent ? <div className="form-group">
        <label>{label}</label>
        {reactSelect}
    </div> :
        <>{reactSelect}</>
    return (
        <>
            <div className="field-body" style={{ ...style }}>
                <div className="field dropdown-container" >
                    {inputComponent}
                </div>
            </div>
        </>
    );
}

