import * as types from '../types/actionTypes/authGroupsType';
import { AuthGroup } from '../types/model';

const authGroups = null as AuthGroup | null
const initialState = authGroups

type State = typeof initialState

export const getAuthGroups = (
    state: State = initialState,
    action: types.AuthGroupsAction
) => {
    if (action.type === types.SET_AUTH_GROUPS) 
        return { ...state, authGroups: action.authGroups};

    return state;
};