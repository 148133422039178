import React, { FunctionComponent } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from '../images/placeholder-image.png';
import { Lightbox } from "react-modal-image";
import { Product } from '../types/model';
import { RootState } from '../types/state';
import { connect } from 'react-redux';
import { faCamera } from '@fortawesome/free-solid-svg-icons'

interface mapStateToPropsType {
    product?: Product | undefined;
}

const mapStateToProps = (state: RootState): mapStateToPropsType => {
    return ({
        product: state.product
    })
}

type Props = ReturnType<typeof mapStateToProps>;


const ProductImageComponent: FunctionComponent<Props> = ({ product }) => {

    var [open, setOpen] = React.useState(false);

    const toggleImage = (isOpen: boolean) => {
        setOpen(isOpen);
    };

    return (
        <>
            <div className="column is-paddingless is-flex product-image-container ">
                <div className="columns" style={{flexDirection: 'column'}}>
                    {product?.imageBase64String &&
                        <>
                            <div className="column">
                                <img className="product-image" src={product?.imageBase64String} alt="product" />
                            </div>
                            <div className="column is-narrow is-paddingless">
                                <button type="button" className="imageButton" onClick={() => toggleImage(true)}>
                                    <FontAwesomeIcon color="grey" icon={faCamera} />
                                </button>
                            </div>
                        </>
                    }
                    {
                        open &&
                        <Lightbox
                            medium={product?.imageBase64String}
                            large={product?.imageBase64String}
                            alt="Image viewer"
                            onClose={() => toggleImage(false)}
                        />
                    }
                </div>
            </div>
        </>
    );
}

export default connect(mapStateToProps, {})(ProductImageComponent);
