import { CompetitorPrice, Product, SubmitProductDashboardValues } from '../types/model';
import { change, InjectedFormProps, isDirty, reduxForm } from 'redux-form';
import React, { useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getProduct, getProductMainData, getProducts, updateProduct } from '../actions/productActions';
import { mapCompetitorPrices, mapEngineType, mapMaintenanceParts, mapOldDeutzSuppliers } from '../helpers/mapper'

import ProductApi from '../api/ProductDashboardApi';
import { ProductDashboardFormName } from '../constants/formNames'
import ProductDetailsComponent from '../components/ProductDetailsComponent';
import ProductImageComponent from '../components/ProductImageComponent';
import ProductInformationComponent from '../components/ProductInformationComponent';
import { RootState } from '../types/state';
import { connect } from 'react-redux';
import { productsReducer } from '../reducers/productReducer';
import { setSelectedTab } from '../actions/generalActions';
import { toast } from "react-toastify";

interface MatchParams {
    material: string
}

const mapStateToProps = (state: RootState): any => {
    var initialValues = { ...state.product }

    return ({
        isDirty: isDirty(ProductDashboardFormName)(state),
        product: state.product,
        initialValues: state.product?.maindataLoaded === true && state.product?.restdataloaded === true ? initialValues : null //; initialValues as any
    })
}

const mapDispatchToProps = (dispatch: any): any => ({
    change: (field: string, value: string) => dispatch(change(field, value, "")),
    getProduct: (material: string) => dispatch(getProduct(material)),
    getProductMainData: (material: string) => dispatch(getProductMainData(material)),
    setSelectedTab: (index: number) => dispatch(setSelectedTab(index)),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps<MatchParams> & InjectedFormProps<{}, any>;

const ProductDashboardContainer: React.FunctionComponent<Props> = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    React.useEffect(() => {
        if (props.match.params.material && (props.product?.material !== props.match.params.material)) {
            if (!isSearching) {
                setIsSearching(true);
                props.getProductMainData(props.match.params.material)
                props.getProduct(props.match.params.material)
            }
        }
    })

    const onFieldChange = (form: string, field: string, value: string) => {
        props.change(field, value);
    } 

    const submitProductDashboardForm = async (submitValues: SubmitProductDashboardValues) => {
        submitValues.competitorPrices = mapCompetitorPrices(submitValues.competitorPrices);
        submitValues.mainData.partEngineTypes = mapEngineType(submitValues.mainData.partEngineTypes);
        submitValues.maintenanceParts = mapMaintenanceParts(submitValues.maintenanceParts);
        submitValues.oldDeutzSuppliers = mapOldDeutzSuppliers(submitValues.oldDeutzSuppliers)
        setIsLoading(true);
        
        await updateProduct(submitValues).then(async () => {
            await props.getProductMainData(submitValues.material);
            props.getProduct(submitValues.material);
            toast.success("Changes have been saved!");
        }).catch(e => {
            toast.error("Changes could not be saved!");
        }).finally(() => {
            setSelectedTab(0)
            setIsLoading(false)
        });
    }

    return (
        <form onSubmit={props.handleSubmit((e: SubmitProductDashboardValues) => { submitProductDashboardForm(e); setShowModal(false) })}>
            <div className="columns information-wrapper">
                <div className="column is-narrow image-wrapper is-paddingless is-flex">
                    <ProductImageComponent />
                </div>
                <div className="column product-information-container ">
                    <ProductInformationComponent />
                </div>
            </div>
            <ProductDetailsComponent
                showModal={showModal}
                setShowModal={setShowModal}
                isLoading={isLoading}
                isDirty={props.isDirty}
                onFieldChange={onFieldChange}
            />
        </form>
    );
}

const ProductReduxForm = reduxForm<{}, any>({
    form: ProductDashboardFormName,
    keepDirtyOnReinitialize: false,
    enableReinitialize: true,
})(ProductDashboardContainer);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductReduxForm));
