import {
  AssortimentType,
  DropdownOption,
  DropdownOptionNummericValue,
  Role,
  TacticalParts,
  TacticalPartsInputValues,
} from "../types/model";
import { change, Field, InjectedFormProps, reduxForm } from "redux-form";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { DropdownComponent } from "../components/InputTypes/Dropdown";
import GridviewComponent from "../components/Gridview/GridviewComponent";
import { RootState } from "../types/state";
import { TacticalPartsColumns } from "../components/Gridview/tableColumns";
import { TacticalPartsFormName } from "../constants/formNames";
import { connect } from "react-redux";
import { getTacticalParts } from "../actions/tacticalPartsActions";
import { NoAccessContainer } from "./NoAccessContainer";
import { checkPermission } from "../helpers/permissionHelper";
import { setFilterSettings } from "../actions/filterSettingsAction";
import { setTacticalPartsFindValues } from "../actions/findValuesAction";

interface MatchParams {
  material: string;
}

const mapStateToProps = (state: RootState): any => {
  return {
    profile: state.profile,
    initialValues: {
      group: "",
      ert: "",
      inAssortiment: AssortimentType.Yes,
    } as any,
    filterSettings: state.setFilters.filterSettingsTacticalParts ? state.setFilters.filterSettingsTacticalParts : [] as {field: string, value: string}[],
    findValues: state.setTacticalPartsFindValues,
    tacticalPartGroups: state.tacticalPartGroups?.tacticalPartGroups,
    erts: state.erts?.erts,
  };
};

const mapDispatchToProps = (dispatch: any): any => ({
  getTacticalParts: (values: TacticalPartsInputValues) =>
    dispatch(getTacticalParts(values)),
    setFilterSettings:(filterSettings: {name:string, value:string}[]) => dispatch(setFilterSettings(filterSettings, "TacticalParts")),
    setTacticalPartsFindValues: (group: string, ert: string, inAssortiment: string) => dispatch(setTacticalPartsFindValues(group, ert, inAssortiment)),
    change: (field: string, value: string) => dispatch(change(field, value, ""))
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<MatchParams> &
  InjectedFormProps<{}, any>;

const TacticalPartsContainer: React.FunctionComponent<Props> = (props) => {
  var [tacticalParts, setTacticalParts] = useState<TacticalParts[]>([]);
  var [isLoading, setIsLoading] = useState<boolean>(false);

  var erts: DropdownOption[] = [];
  erts = props.erts ?
    props.erts.map(e => { return { label: e.ert, value: e.ert } as DropdownOption })
    : [];

  var groups: DropdownOption[] = [];
  groups = props.tacticalPartGroups ?
    props.tacticalPartGroups.map(g => { return { label: g.group + " | " + g.description, value: g.group } as DropdownOption })
    : [];

  var assortimentOptions = [
    { label: "Yes", value: AssortimentType.Yes },
    { label: "No", value: AssortimentType.No },
    { label: "All", value: AssortimentType.All },
  ] as DropdownOptionNummericValue[];

  const sumbitTacticalParts = async (values: TacticalPartsInputValues) => {
    setIsLoading(true);
    if (!values.inAssortiment) values.inAssortiment = AssortimentType.Yes;
    await getTacticalParts(values).then(async (tacticalParts) => {
      props.setTacticalPartsFindValues(values.group, values.ert, values.inAssortiment);
      setIsLoading(false);
      setTacticalParts(tacticalParts);
    });
  };

  const onFilterChanged = (field: string, value: string) => {
    var settings = props.filterSettings;

    var filter = settings.find((x) => x.field === field);

    if (!filter)
      settings = [...settings, {field: field, value: value}]
    else
      settings[settings.indexOf(filter)].value = value;

    props.setFilterSettings(settings);
  }

  const setPreviousState = () => {
    sumbitTacticalParts(props.findValues);
    props.change('group', props.findValues.group);
    props.change('ert', props.findValues.ert);
    props.change('inAssortiment', props.findValues.inAssortiment);
  }

  useEffect(() => {
    if (props.findValues.group !== "") {
      setPreviousState();
    }
  }, [])

  return checkPermission(props.profile, Role.DashboardExportUser) ? (
    <>
      <form
        className="tactical-parts-form"
        onSubmit={props.handleSubmit((e: any) => {
          sumbitTacticalParts(e);
        })}
      >
        <div className="columns tactical-parts-header">
          <div className="column is-4">
            <div className="columns">
              <div className="column">
                <Field
                  key={1}
                  options={groups}
                  component={DropdownComponent}
                  name="group"
                  required={true}
                  label="Group:"
                  labelInComponent={true}
                  isMulti={true}
                />
              </div>
              <div className="column description pt-0">eg 10 or all</div>
            </div>
          </div>
          <div className="column is-3">
            <div className="columns">
              <div className="column">
                <Field
                  key={2}
                  options={erts}
                  component={DropdownComponent}
                  name="ert"
                  required={true}
                  label="ERT:"
                  labelInComponent={true}
                  isMulti={true}
                />
              </div>
              <div className="column description pt-0">eg 410 or all</div>
            </div>
          </div>
          <div className="column is-3">
            <div className="columns">
              <div className="column">
                <Field
                  key={3}
                  options={assortimentOptions}
                  name="inAssortiment"
                  component={DropdownComponent}
                  labelInComponent={true}
                  hasDefaultOption={true}
                  label={"In assortment:"}
                />
              </div>
              <div className="column description pt-0">eg yes, no, all</div>
            </div>
          </div>
          <div className="column">
            <div className="columns">
              <div className="column">
                <button className="tactical-parts-button">
                  Find Tactical Parts
                </button>
              </div>
              <div className="column description pt-0">
                {tacticalParts && tacticalParts.length > 0
                  ? `Total results: ${tacticalParts.length}`
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="main-container tactical-parts-container mt-5">
        <GridviewComponent
          columns={TacticalPartsColumns()}
          isTacticalParts={true}
          data={tacticalParts}
          isLoading={isLoading}
          onFilterChanged={onFilterChanged}
          defaultFilter={props.filterSettings}
        />
      </div>
    </>
  ) : (
    <NoAccessContainer />
  );
};
const TacticalPartsReduxForm = reduxForm<{}, any>({
  form: TacticalPartsFormName,
  keepDirtyOnReinitialize: false,
  enableReinitialize: true,
})(TacticalPartsContainer);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TacticalPartsReduxForm)
);
