import {
  GeneralInformation,
  Product,
  ProductDropdownOption,
  TacticalParts,
  TacticalPartsInputValues,
} from "../types/model";

import ApiBase from "./ApiBase";

const xlsxApiEndpoint = "xlsx" as string;

class XlsxApi extends ApiBase {
  getXlsxBase64(csvString: string[][]): Promise<string> {
    return this.doHttpRequest<any>(
      "post",
      `${xlsxApiEndpoint}`,
      { csvString: csvString },
      false
    );
  }
}

export default new XlsxApi();
