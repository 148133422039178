import { WrappedFieldProps } from 'redux-form';
import classnames from 'classnames'
import * as React from 'react'

interface Props {
    placeholder?: string;
    label?: string;
    hidden?: boolean;
    readOnly?: boolean
    type?: string;
    labelInComponent?: boolean
    required?: boolean;
    isBorderError?: boolean;
    measurementValue?: string;
    formatValue?: any;
    decimals?: number;
    symbol?: string;
    showSymbolRight?: boolean;
    maxLength?: number;
    disallowNummericValues?: boolean;
}

export type TextInputProps = WrappedFieldProps & Props;


export const TextInputComponent = (props: TextInputProps) => {
    const { input, label, type = "text", decimals, formatValue, required, placeholder, readOnly, hidden, labelInComponent, measurementValue, symbol, showSymbolRight, maxLength, disallowNummericValues } = props;
    let { meta: { touched, error } } = props;

    if (decimals && !props.meta.active) {
        input.value = Number(input.value).toFixed(decimals);
    }

    var baseComponent = <>
        {label ? <label style={{ backgroundColor: readOnly ? "#f7f7f7" : 'white' }}>{label} &nbsp;</label> : <label style={{ visibility: 'hidden' }}>-</label>}
        <div style={{ display: 'flex' }}>
            <span style={{ display: symbol && showSymbolRight !== true ? 'inline' : 'none' }}>{symbol}</span>
            <input
                {...input}
                maxLength={maxLength ? maxLength : undefined}
                type={type}
                required={required}
                pattern={disallowNummericValues ? "[0-9]" : undefined}
                onKeyDown={(event) => disallowNummericValues ? event.key === "." ? event.preventDefault() : () => { } : () => { }}
                step={disallowNummericValues ? "2" : undefined}
                className={classnames("input")}
                style={{ position: 'inherit', backgroundColor: readOnly ? "#f7f7f7" : 'transparent' }}
                placeholder={placeholder}
                readOnly={readOnly}
            />
            <span style={{ display: symbol && showSymbolRight ? 'inline' : 'none' }}>{symbol}</span>
        </div>
    </>

    var inputComponent = labelInComponent ?
        <>
            <div className={"form-group"} style={{ backgroundColor: readOnly ? "#f7f7f7" : 'transparent' }}>
                {baseComponent}
            </div>
        </> : baseComponent

    return (
        <>
            {!hidden &&
                <>
                    <div className="field-body">
                        <div className="field">
                            {inputComponent}
                        </div>
                    </div>
                    {touched && error && <p className="help is-danger">{error}</p>}
                </>
            }
        </>
    );
}
