import { Button, Col, CustomInput, Input, Row } from "reactstrap"
import { PartEngineType, Role, SeverityGroup } from "../../types/model";

import { AccountInfo } from "@azure/msal-browser";
import { Field, formValueSelector, getFormValues, isDirty, WrappedFieldProps } from "redux-form"
import React from "react"
import { TextInputComponent } from '../../components/InputTypes/TextInput';
import { checkPermission } from "../../helpers/permissionHelper";
import { DropdownComponent } from "../../components/InputTypes/Dropdown";
import { useHistory } from "react-router-dom";

export const Stock = (profile: AccountInfo | undefined): JSX.Element => (
    <>
        <div className="columns">
            <div className="column is-4">
                <Field
                    key={1}
                    component={TextInputComponent}
                    name="mainData.totalStock"
                    label="Total Stock"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column is-4">
                <Field
                    key={73}
                    component={TextInputComponent}
                    name="mainData.uom"
                    label="UoM"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column is-4">
                <Field
                    key={2}
                    component={TextInputComponent}
                    name="mainData.availableToPr"
                    label="Avail To Pr."
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={3}
                    component={TextInputComponent}
                    name="mainData.safetyStock"
                    label="Safety stock"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={4}
                    component={TextInputComponent}
                    name="mainData.itor"
                    label="ITOR"
                    decimals={1}
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
    </>
)


export const Consumption = (profile: AccountInfo | undefined): JSX.Element => (
    <>
        <div className="columns">
            <div className="column">
                <Field
                    key={5}
                    component={TextInputComponent}
                    name="mainData.cons1Year"
                    label="Cons 1Y"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={6}
                    component={TextInputComponent}
                    name="mainData.cons2Year"
                    label="Cons 2Y"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={7}
                    component={TextInputComponent}
                    name="mainData.cons3Year"
                    label="Cons 3Y"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={8}
                    component={TextInputComponent}
                    name="mainData.cons4Year"
                    label="Cons 4Y"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={9}
                    component={TextInputComponent}
                    name="mainData.cons5Year"
                    label="Cons 5Y"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={10}
                    component={TextInputComponent}
                    name="mainData.cons6Year"
                    label="Cons 6Y"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
    </>
)


export const Costs = (profile: AccountInfo | undefined): JSX.Element => (
    <>
        <div className="columns">
            <div className="column">
                <Field
                    key={11}
                    component={TextInputComponent}
                    name="mainData.unitGlp"
                    label="Unit Glp"
                    symbol={"€"}
                    decimals={2}
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={12}
                    component={TextInputComponent}
                    name="mainData.unitValue"
                    label="Unit Value"
                    symbol={"€"}
                    decimals={2}
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={13}
                    component={TextInputComponent}
                    name="mainData.unitBookValue"
                    label="Unit Book Value"
                    decimals={2}
                    symbol={"€"}
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={14}
                    component={TextInputComponent}
                    decimals={2}
                    name="mainData.targetPrice"
                    type={"number"}
                    readOnly={!checkPermission(profile, Role.SuperUser)}
                    symbol={"€"}
                    label="Target Price"
                    labelInComponent={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={15}
                    component={TextInputComponent}
                    name="mainData.totalGlpValue"
                    label="Total GLP Value"
                    symbol={"€"}
                    decimals={2}
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={16}
                    component={TextInputComponent}
                    name="mainData.totalStockValue"
                    label="Total Stock Value"
                    symbol={"€"}
                    decimals={2}
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={17}
                    component={TextInputComponent}
                    name="mainData.totalBookValue"
                    symbol={"€"}
                    decimals={2}
                    label="Total Book Value"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={18}
                    component={TextInputComponent}
                    name="mainData.margin"
                    showSymbolRight={true}
                    symbol="%"
                    label="Margin"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={19}
                    component={TextInputComponent}
                    name="mainData.competitor"
                    label="Competitor"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={20}
                    component={TextInputComponent}
                    name="mainData.competitorPrice"
                    label="Competitor Price"
                    symbol={"€"}
                    decimals={2}
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={21}
                    component={TextInputComponent}
                    name="mainData.competitorDate"
                    label="Competitor Date"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={22}
                    component={TextInputComponent}
                    name="mainData.mwmNett"
                    label="MWM Nett"
                    symbol={"€"}
                    decimals={2}
                    type={"number"}
                    readOnly={!checkPermission(profile, Role.SuperUser)}
                    labelInComponent={true}
                />
            </div>
            <div className="column">
                <Field
                    key={23}
                    component={TextInputComponent}
                    name="mainData.mwmGlp"
                    label="MWM GLP"
                    symbol={"€"}
                    decimals={2}
                    type={"number"}
                    readOnly={!checkPermission(profile, Role.SuperUser)}
                    labelInComponent={true}
                />
            </div>
        </div>
    </>
)

export const HitrateDetails = (profile: AccountInfo | undefined): JSX.Element => (
    <>
        <div className="columns">
            <div className="column">
                <Field
                    key={24}
                    component={TextInputComponent}
                    name="mainData.quoteQty"
                    label="Quote qty"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={25}
                    component={TextInputComponent}
                    name="mainData.qtyQuoted"
                    label="Qty quoted"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>

        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={26}
                    component={TextInputComponent}
                    name="mainData.quoteEur"
                    label="Quote"
                    symbol={"€"}
                    decimals={2}
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={27}
                    component={TextInputComponent}
                    name="mainData.avgQuotePrice"
                    decimals={2}
                    label="Avg quote €/pce"
                    symbol={"€"}
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={28}
                    component={TextInputComponent}
                    name="mainData.orderQty"
                    label="Order qty"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={29}
                    component={TextInputComponent}
                    name="mainData.qtyOrdered"
                    label="Qty ordered"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>

        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={30}
                    component={TextInputComponent}
                    name="mainData.orderEur"
                    label="Order"
                    symbol={"€"}
                    decimals={2}
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={31}
                    component={TextInputComponent}
                    name="mainData.avgOrderPrice"
                    label="Avg Order €/pce"
                    decimals={2}
                    symbol={"€"}
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={32}
                    component={TextInputComponent}
                    name="mainData.hitrate"
                    label="Hitrate"
                    showSymbolRight={true}
                    symbol="%"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
            </div>
        </div>
    </>
)

export const Successor = (profile: AccountInfo | undefined, mainData: any): JSX.Element => {
    const history = useHistory();

    const handleColumnClick = (route: string) => {
        history.push(route);
        history.go(0)
    };

    return (
        <>
            <div className="columns">
                <div
                    className="column"
                    onDoubleClick={() => {
                        if (mainData.successor && mainData.successor.trim().length > 0) {
                            handleColumnClick('/Products/' + mainData.successor)
                        }

                    }}
                >
                    <Field
                        key={33}
                        component={TextInputComponent}
                        name="mainData.successor"
                        label="Successor"
                        labelInComponent={true}
                        readOnly={true}

                    />
                </div>
            </div>
            <div className="columns">
                <div
                    className="column"
                    onDoubleClick={() => {
                        if (mainData.lastSuccessor && mainData.lastSuccessor.trim().length > 0) {
                            handleColumnClick('/Products/' + mainData.lastSuccessor)
                        }
                    }}
                >
                    <Field
                        key={34}
                        component={TextInputComponent}
                        name="mainData.lastSuccessor"
                        label="Last Successor"
                        labelInComponent={true}
                        readOnly={true}
                    />
                </div>
            </div>
        </>
    );
};

export const ProductHierarchy = (profile: AccountInfo | undefined): JSX.Element => (
    <>
        <div className="columns">
            <div className="column">
                <Field
                    key={35}
                    component={TextInputComponent}
                    name="mainData.productName"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={36}
                    component={TextInputComponent}
                    name="mainData.productDescription2"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={37}
                    component={TextInputComponent}
                    name="mainData.productDescription3"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column is-4">
                <Field
                    key={38}
                    component={TextInputComponent}
                    name="mainData.materialGroupName"
                    label="Material Group"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={39}
                    component={TextInputComponent}
                    labelInComponent={true}
                    name="mainData.materialGroupText"
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column is-4">
                <Field
                    key={40}
                    component={TextInputComponent}
                    name="mainData.priceFamilyName"
                    label="Price family"
                    readOnly={!checkPermission(profile, Role.SuperUser)}
                    labelInComponent={true}
                />
            </div>
            <div className="column">
                <Field
                    key={41}
                    component={TextInputComponent}
                    name="mainData.priceFamilyDescription"
                    readOnly={!checkPermission(profile, Role.SuperUser)}
                    labelInComponent={true}
                />
            </div>
        </div>
    </>
)

export const SourcingDetails = ({ mainData, profile }: { mainData: any, profile: AccountInfo | undefined }): JSX.Element => {
    return (
        <>
        <div className="columns">
            <div className="column is-5">
                <Field
                    key={42}
                    component={TextInputComponent}
                    name="mainData.psmResponsible"
                    readOnly={!checkPermission(profile, Role.SuperUser)}
                    label="PSM Responsible"
                    labelInComponent={true}
                />
            </div>
            <div className="column is-3">
                <Field
                    key={44}
                    component={TextInputComponent}
                    name="mainData.purchaseGroup"
                    label="Purchase group"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={45}
                    component={TextInputComponent}
                    name="mainData.purchaseGroupName"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={47}
                    component={TextInputComponent}
                    name="mainData.materialState"
                    label="Material Status"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={48}
                    component={TextInputComponent}
                    name="mainData.materialStateDescription"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={50}
                    component={TextInputComponent}
                    name="mainData.salesStatus"
                    label="Sales status"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={51}
                    component={TextInputComponent}
                    name="mainData.salesStatusDescription"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={53}
                    component={TextInputComponent}
                    name="mainData.mrpProfile"
                    label="MRP Profile"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={54}
                    component={TextInputComponent}
                    name="mainData.mrpProfileDescription"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={56}
                    component={TextInputComponent}
                    name="mainData.availabilityCheck"
                    label="Availability check"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column is-7">
                <Field
                    key={57}
                    component={TextInputComponent}
                    name="mainData.availabilityCheckDescription"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={61}
                    component={TextInputComponent}
                    name="mainData.dimensions"
                    label="Dimensions"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={58}
                    component={TextInputComponent}
                    name="mainData.grossWeight"
                    measurementValue={mainData?.grossWeightUom}
                    label="Gross Weight"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={64}
                    component={TextInputComponent}
                    name="mainData.basicMaterial"
                    label="Basic material"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={43}
                    component={TextInputComponent}
                    name="mainData.agreementSupplier"
                    label="Agreement supplier"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={46}
                    component={TextInputComponent}
                    name="mainData.supplierLastPo"
                    label="Supplier last PO"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={46}
                    component={TextInputComponent}
                    name="mainData.supplierSecondPo"
                    label="Supplier 2nd last PO"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={52}
                    component={TextInputComponent}
                    name="mainData.supplierThirdPo"
                    label="Supplier 3rd last PO"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={55}
                    component={TextInputComponent}
                    name="mainData.supplierFourthPo"
                    label="Supplier 4th last PO"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={62}
                    component={TextInputComponent}
                    name="mainData.goodProcTime"
                    label="Goods proc time"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={63}
                    component={TextInputComponent}
                    name="mainData.inHouseProdTime"
                    label="In house prod.time(days)"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={65}
                    component={TextInputComponent}
                    name="mainData.plannedDelTime"
                    label="Planned Del. Time"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={66}
                    component={TextInputComponent}
                    name="mainData.authorizationGroup"
                    label="Authorization Group"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column is-4">
                <Field
                    key={59}
                    component={TextInputComponent}
                    name="mainData.procType"
                    label="Proc Type"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={60}
                    component={TextInputComponent}
                    name="mainData.procTypeDescription"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={67}
                    component={TextInputComponent}
                    name="mainData.abc"
                    label="ABC"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={68}
                    component={TextInputComponent}
                    name="mainData.xyz"
                    label="XYZ"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
            <div className="column">
                <Field
                    key={69}
                    component={TextInputComponent}
                    name="mainData.cityOfOrigin"
                    label="Ctry of Origin"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    key={70}
                    name=""
                    component={(fieldProps: WrappedFieldProps) => {
                        const input = {...fieldProps.input, onFocus: (e) => e.preventDefault(), onBlur: (e) => e.preventDefault()};
                        input.value = `${mainData?.severityGroup ?? ""} ${mainData?.severityGroup ? ": " : ""} ${mainData?.severityGroupDescription ?? ""}`;
                        return (
                        <TextInputComponent
                            input={input}
                            meta={fieldProps.meta}
                            label="Severity Group & Description"
                            labelInComponent={true}
                            readOnly={true}
                        />)
                    }}
                />
            </div>
            <div className="column is-4">
                <Field
                    key={71}
                    component={TextInputComponent}
                    name="mainData.reconStatus"
                    label="Recon. Status"
                    labelInComponent={true}
                    readOnly={true}
                />
            </div>
        </div>
    </>
    );
};
export const UsedInEngineType = (engineTypes: PartEngineType[], color: string, onClick: (index: number) => void, profile: AccountInfo | undefined): JSX.Element => {
    var totalValue = 0;

    var rows = engineTypes?.map(engineType => {
        totalValue += engineType.qtyEngines;
        return (
            <tr onClick={() => { onClick(engineType.id) }} key={Math.random()}>
                <td>{engineType.productType}</td>
                <td>{engineType.qtyEngines}</td>
            </tr>
        )
    })

    return (
        <>
            <div className="columns">
                <div className="column">
                    <table className="engine-type-table" style={{ maxHeight: '450px', overflowY: 'auto', display: 'block' }}>
                        <thead>
                            <tr key={0}>
                                <th key={1}>Product type</th>
                                <th key={2}>Quantity Engines</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows}
                            <tr key={3}>
                                <td key={4}></td>
                                <td key={5}></td>
                            </tr>
                            <tr key={6}>
                                <td key={7} style={{ color: color }}>Totaal</td>
                                <td key={8} style={{ color: color, display: 'inline-flex', width: '100%', justifyContent: 'space-between' }}>
                                    <div>{totalValue}</div>
                                    <div>
                                        {checkPermission(profile, Role.SuperUser) &&
                                            <Button
                                                color="primary"
                                                className="navigation-gridview-button"
                                                onClick={() => onClick(0)}
                                            >
                                                {"+"}
                                            </Button>
                                        }
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
export const OtherPartsReferences = (profile: AccountInfo | undefined): JSX.Element => (
    <>
        <div className="columns">
            <div className="column">
                <Field
                    component={TextInputComponent}
                    name="mainData.oldPartNo"
                    label="Old part no"
                    readOnly={!checkPermission(profile, Role.SuperUser)}
                    labelInComponent={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    component={TextInputComponent}
                    name="mainData.natoStockNo"
                    label="Nato Stock NO"
                    readOnly={!checkPermission(profile, Role.SuperUser)}
                    labelInComponent={true}
                />
            </div>
        </div>
        <div className="columns">
            <div className="column">
                <Field
                    component={TextInputComponent}
                    name="mainData.spare"
                    label="Spare/ETL"
                    readOnly={!checkPermission(profile, Role.SuperUser)}
                    labelInComponent={true}
                />
            </div>
        </div>
    </>
)
