import { FunctionComponent } from 'react';
import * as React from 'react'

type MainDataColumnProps = {
    headerTitle: string;
    backgroundColorHeader: string;
    content: JSX.Element;
}

export const MainDataColumnComponent: FunctionComponent<MainDataColumnProps> = ({ headerTitle, backgroundColorHeader, content }) => {
    return (
        <div className="column main-data-column">
            <span style={{ backgroundColor: backgroundColorHeader }} >{headerTitle}</span>
            {content}
        </div>
    );
}

