import { CSSProperties } from "react";
import { Styles } from "react-select";

export const ProductInformationButtonStyle = {
    height: '40px',
    backgroundColor: '#999999',
    color: '#FFFFFF',
    width: '100%'
} as React.CSSProperties;

export const NavigationButtonStyle = {
    backgroundColor: '#F2F8FF',
} as React.CSSProperties;


export const ProductDropdownStyle = {
    option: (cssProps: CSSProperties) => ({
        ...cssProps,
    }),

    container: (cssProps: CSSProperties) => ({
        ...cssProps,
        width: '100%',
        border: '1px solid #DDDDDD'
    }),

    indicatorSeparator: (cssProps: CSSProperties) => ({
        ...cssProps,
        width: '0px'
    }),
    
    input: (cssProps: CSSProperties) => ({
        ...cssProps,
        /* expand the Input Component div */
        width: "100% !important",
        
        /* expand the Input Component child div */
        "> div": {
          width: "100%"
         },
      
        /* expand the Input Component input */
        input: {
          width: "100% !important",
          textAlign: "left"
        },
        
      }),
      placeholder: (cssProps) => ({
        ...cssProps,
        pointerEvents: "none"
    })
} as Partial<Styles<any, any, any>>;

