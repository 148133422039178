import { CategoryErt, Role } from '../types/model';
import React, { FunctionComponent } from 'react';

import { AccountInfo } from '@azure/msal-browser';
import { Checkbox } from '../components/InputTypes/Checkbox';
import { Field } from 'redux-form';
import { RootState } from '../types/state';
import { checkPermission } from '../helpers/permissionHelper';
import { connect } from 'react-redux';

interface ErtComponentProps {
    maxColumnWidth?: number;
    title: string;
    color: string;
    categoryErts: CategoryErt[]
}

interface StoreProps {
    profile: AccountInfo | undefined;
}

const mapStateToProps = (state: RootState): StoreProps => {
    return ({
        profile: state.profile
    })
}

type Props = ReturnType<typeof mapStateToProps> & ErtComponentProps;

const ERTComponent: FunctionComponent<Props> = ({ title, maxColumnWidth, color, categoryErts, profile }) => {
    var parentColumns = [] as JSX.Element[];
    var columns = [] as JSX.Element[];
    categoryErts.forEach((ce, i) => {
        if (i % (maxColumnWidth ? maxColumnWidth : Infinity) === 0) {
            parentColumns.push(<div key={Math.random()} className="columns"> {columns} </div>)
            columns = [];
        }
        columns.push(<div key={i * 2} className="column">
            <span key={Math.random()} className="ert-category-header" style={{ color }}>{ce.category}</span>
            {ce.erts.map(e => {
                var name = e.ert;
                if (!Number.isNaN(+e.ert))
                    name = e.brand + e.ert
                else if (e.ert.includes("/"))
                    name = e.brand + e.ert.replace('/', "and");
                var reduxName = `erts.${name.charAt(0).toLocaleLowerCase() + name.slice(1)}`;
                return (
                    <Field
                        key={Math.random()}
                        component={Checkbox}
                        customClassLabel="ert-label"
                        readOnly={!checkPermission(profile, Role.SuperUser)}
                        name={reduxName}
                        label={e.ert}
                    />
                )
            })}
        </div>)
        if (categoryErts.length === i + 1)
            parentColumns.push(<div key={Math.random()} className="columns"> {columns} </div>)
    });

    return (
        <div className="columns">
            <div className="column ert-container">
                <div className="columns">
                    <div className="column ert-header" style={{ color: color }}>
                        {title}
                    </div>
                </div>
                {parentColumns}
            </div>
        </div>
    );
}

export default connect(mapStateToProps, {})(ERTComponent)

