import { filterDate, filterDecimals } from "./filters";
import {
  showDate,
  showDecimal,
  showMoney,
  showPercentage,
} from "../../helpers/formatHelper";

import { CustomInput } from "reactstrap";
import React from "react";
import { RenderType, TableColumn } from "../../types/model";

export const CompetitorPriceColumns = (): TableColumn[] =>
  [
    {
      Header: "Price date",
      accessor: "priceDate",
      Cell: (props: any) => showDate(props.data[props.row.index].priceDate),
      filter: filterDate,
      type: RenderType.Date,
    },
    {
      Header: "Gross € price/pce",
      accessor: "grossPrice",
      Cell: (props: any) => showMoney(props.data[props.row.index].grossPrice),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "Nett € price/pce",
      accessor: "nettPrice",
      Cell: (props: any) => showMoney(props.data[props.row.index].nettPrice),


      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "Competitor",
      accessor: "competitor",
    },
    {
      Header: "Recon",
      accessor: "recon",
      Cell: (props: any) => {
        var row = props.data[props.row.index];
        return row.recon === true ? "true" : "";
      },
      type: RenderType.TrueOrEmpty,
    },
  ] as TableColumn[];

export const MaintenancePartsColumns = (): TableColumn[] =>
  [
    {
      Header: "ERT",
      accessor: "ert",
    },
    {
      Header: "Interval",
      accessor: "interval",
    },
    {
      Header: "Job description",
      accessor: "jobDescription",
    },
    {
      Header: "Insp Dep",
      accessor: "inspDep",
    },
    {
      Header: "Comment",
      accessor: "comment",
    },
    {
      Header: "Last successor",
      accessor: "lastSuccessor",
    },
  ] as TableColumn[];

export const GlpHistoryColumns = (): TableColumn[] =>
  [
    {
      Header: "Global List Price (€)",
      accessor: "globalListPrice",
      Cell: (props: any) =>
        showMoney(props.data[props.row.index].globalListPrice),
      filter: filterDecimals,
      type: RenderType.Money,
      width: 200
    },
    {
      Header: "Per",
      accessor: "per",
      width: 70
    },
    {
      Header: "Unit",
      accessor: "unit",
      width: 70,
    },
    {
      Header: "Valid from",
      accessor: "validFrom",
      width: 150,
      Cell: (props: any) => showDate(props.data[props.row.index].validFrom),
      type: RenderType.Date,
      filter: filterDate,
    },
    {
      Header: "Valid to",
      accessor: "validTo",
      Cell: (props: any) => showDate(props.data[props.row.index].validTo),
      width: 150,
      filter: filterDate,
      type: RenderType.Date,
    },
  ] as TableColumn[];

export const InfoRecordsColumns = (): TableColumn[] =>
  [
    {
      Header: "Purchase Info",
      accessor: "purchaseInfo",
    },
    {
      Header: "Supplier Name",
      accessor: "supplierName",
      width: 200
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Last Order Date",
      accessor: "lastOrderDate",
      Cell: (props: any) => showDate(props.data[props.row.index].lastOrderDate),
      filter: filterDate,
      type: RenderType.Date,
    },
    {
      Header: "Price (€)",
      accessor: "price",
      Cell: (props: any) => showMoney(props.data[props.row.index].price),
      filter: filterDecimals,
      type: RenderType.Money,
      width: 120
    },
    {
      Header: "Per",
      accessor: "per",
      width: 70
    },
    {
      Header: "Unit",
      accessor: "unit",
      width: 70
    },
    {
      Header: "Delivery Time",
      accessor: "deliveryTime",
    },
    {
      Header: "Country of origin",
      accessor: "countryOfOrigin",
    },
    {
      Header: "Supplier No",
      accessor: "supplierNo",
    },
    {
      Header: "Plant",
      accessor: "plant",
    },
    {
      Header: "Currency",
      accessor: "currency",
      width: 120
    },
  ] as TableColumn[];

export const OrdQteAllYearsColumns = (): TableColumn[] =>
  [
    {
      Header: "Ord/qte",
      accessor: "ordQte",
    },
    {
      Header: "Customer description",
      accessor: "customerDescription",
    },
    {
      Header: "Installation",
      accessor: "installation",
    },
    {
      Header: "ERT",
      accessor: "ert",
    },
    {
      Header: "Qty",
      accessor: "qty",
      type: RenderType.Number
    },
    {
      Header: "Created on",
      accessor: "createdOn",
      Cell: (props: any) => showDate(props.data[props.row.index].createdOn),
      filter: filterDate,
      type: RenderType.Date,
    },
    {
      Header: "Nett value (€)",
      accessor: "nettValue",
      Cell: (props: any) => showMoney(props.data[props.row.index].nettValue),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "Total Value",
      accessor: "totalValue",
      Cell: (props: any) => showMoney(props.data[props.row.index].totalValue),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "Sales Doc",
      accessor: "salesDoc",
    },
    {
      Header: "SaTy",
      accessor: "saTy",
    },
    {
      Header: "Item",
      accessor: "item",
    },
    {
      Header: "Product Type",
      accessor: "productType",
    },
    {
      Header: "Created by",
      accessor: "createdBy",
    },
    {
      Header: "Engine nr",
      accessor: "engineNr",
    },
    {
      Header: "Customer",
      accessor: "customer",
    },
    {
      Header: "SORG",
      accessor: "sorg",
    },
    {
      Header: "Plnt",
      accessor: "plnt",
    },
  ] as TableColumn[];

export const HitrateHistoriesColumns = (): TableColumn[] =>
  [
    {
      Header: "Period",
      accessor: "period",
      type: RenderType.String
    },
    {
      Header: "Quote Qty",
      accessor: "quoteQty",
      type: RenderType.Decimal,
    },
    {
      Header: "Qty Quoted",
      accessor: "qtyQuoted",
      type: RenderType.Decimal,
    },
    {
      Header: "Quote EUR",
      accessor: "quoteEuro",
      Cell: (props: any) => showMoney(props.data[props.row.index].quoteEuro),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "Order Qty",
      accessor: "orderQty",
      type: RenderType.Decimal,
    },
    {
      Header: "Qty Ordered",
      accessor: "qtyOrdered",
      type: RenderType.Decimal,
    },
    {
      Header: "Order EUR",
      accessor: "orderEuro",
      Cell: (props: any) => showMoney(props.data[props.row.index].orderEuro),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "Hitrate (%)",
      accessor: "hitrate",
      Cell: (props: any) =>
        showPercentage(props.data[props.row.index].hitrate, 0),
      filter: filterDecimals,
      type: RenderType.Percentage,
    },
  ] as TableColumn[];

export const OldDeutzSuppliersColumns = (): TableColumn[] =>
  [
    {
      Header: "Source",
      accessor: "source",
    },
    {
      Header: "Material details",
      accessor: "materialDetails",
    },
    {
      Header: "Name 1",
      accessor: "name1",
    },
    {
      Header: "Name 2",
      accessor: "name2",
    },
    {
      Header: "Country",
      accessor: "country",
    },
    {
      Header: "Town 1",
      accessor: "town1",
    },
    {
      Header: "Town 2",
      accessor: "town2",
    },
    {
      Header: "Street",
      accessor: "street",
    },
    {
      Header: "Telephone",
      accessor: "telephone",
    },
    {
      Header: "Date",
      accessor: "date",
      Cell: (props: any) => showDate(props.data[props.row.index].date),
      filter: filterDate,
      type: RenderType.Date,
    },
    {
      Header: "Qty ordered",
      accessor: "qtyOrdered",
      type: RenderType.Number,
    },
    {
      Header: "Nett price",
      accessor: "nettPrice",
      type: RenderType.Date,
    },
    {
      Header: "Currency",
      accessor: "currency",
    },
    {
      Header: "Order unit",
      accessor: "orderUnit",
    },
    {
      Header: "Price Unit",
      accessor: "priceUnit",
      type: RenderType.Number,

    },
  ] as TableColumn[];

export const UpdateColumns = (): TableColumn[] =>
  [
    {
      Header: "Group",
      accessor: "group",
    },
    {
      Header: "ERT",
      accessor: "ert",
    },
    {
      Header: "Comment",
      accessor: "comment",
    },
    {
      Header: "Target Price",
      accessor: "name2",
    },
    {
      Header: "Country",
      accessor: "country",
    },
    {
      Header: "Town 1",
      accessor: "town1",
    },
    {
      Header: "Town 2",
      accessor: "town2",
    },
    {
      Header: "Street",
      accessor: "street",
    },
    {
      Header: "Telephone",
      accessor: "telephone",
    },
    {
      Header: "Date",
      accessor: "date",
      Cell: (props: any) => showDate(props.data[props.row.index].date),
      type: RenderType.Date,
    },
    {
      Header: "Qty ordered",
      accessor: "qtyOrdered",
    },
    {
      Header: "Nett price",
      accessor: "nettPrice",
    },
    {
      Header: "Currency",
      accessor: "currency",
    },
    {
      Header: "Order unit",
      accessor: "orderUnit",
    },
    {
      Header: "Price Unit",
      accessor: "priceUnit",
    },
  ] as TableColumn[];

export const TacticalPartsColumns = (): TableColumn[] =>
  [
    {
      Header: "ERT",
      accessor: "ert",
      headerClassName: "is-flex",
    },
    {
      Header: "Group",
      accessor: "group",
      type: RenderType.Number,
    },
    {
      Header: "Material",
      accessor: "material",
      width: 150,
    },
    {
      Header: "Material description",
      accessor: "materialDescription",
      width: 280,
    },
    {
      Header: "Comment",
      accessor: "comment",
      width: 200,
    },
    {
      Header: "Status",
      accessor: "status",
      width: 15,
    },
    {
      Header: "ATP",
      accessor: "atp",
      width: 15,
      type: RenderType.Decimal,
    },
    {
      Header: "TotStk",
      accessor: "totalStock",
      width: 15,
      type: RenderType.Decimal,
    },
    {
      Header: "Safet",
      accessor: "safet",
      type: RenderType.Decimal,
    },
    {
      Header: "Unit Value",
      accessor: "unitValue",
      Cell: (props: any) => showMoney(props.data[props.row.index].unitValue),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "Unit GLP",
      accessor: "unitGlp",
      Cell: (props: any) => showMoney(props.data[props.row.index].unitGlp),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "TotStockValue",
      accessor: "totalStockValue",
      Cell: (props: any) =>
        showMoney(props.data[props.row.index].totalStockValue),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "TotBookValue",
      accessor: "totalBookValue",
      Cell: (props: any) =>
        showMoney(props.data[props.row.index].totalBookValue),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "Competitor",
      accessor: "competitor",
    },
    {
      Header: "Comp price",
      accessor: "competitorPrice",
      Cell: (props: any) =>
        showMoney(props.data[props.row.index].competitorPrice),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "Comp date",
      accessor: "competitorDate",
      Cell: (props: any) =>
        showDate(props.data[props.row.index].competitorDate),
      filter: filterDate,
      type: RenderType.Date,
    },
    {
      Header: "Lead Time",
      accessor: "leadTime",
      type: RenderType.Decimal,
    },
    {
      Header: "Margin",
      accessor: "margin",
      Cell: (props: any) =>
        showPercentage(props.data[props.row.index].margin, 0),
      filter: filterDecimals,
      type: RenderType.Percentage,
    },
    {
      Header: "Successor",
      accessor: "successor",
    },
    {
      Header: "Agreement supplier",
      accessor: "agreementSupplier",
      width: 280,
    },
    {
      Header: "Cons1Y",
      accessor: "cons1Year",
      type: RenderType.Decimal,
    },
    {
      Header: "Cons2Y",
      accessor: "cons2Year",
      type: RenderType.Decimal,
    },
    {
      Header: "Cons3Y",
      accessor: "cons3Year",
      type: RenderType.Decimal,
    },
    {
      Header: "Cons4Y",
      accessor: "cons4Year",
      type: RenderType.Decimal,
    },
    {
      Header: "Cons5Y",
      accessor: "cons5Year",
      type: RenderType.Decimal,
    },
    {
      Header: "Cons6Y",
      accessor: "cons6Year",
      type: RenderType.Decimal,
    },
    {
      Header: "Quote",
      accessor: "quote",
      Cell: (props: any) => showMoney(props.data[props.row.index].quote),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "Order",
      accessor: "order",
      Cell: (props: any) => showMoney(props.data[props.row.index].order),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "Hitrate",
      accessor: "hitrate",
      Cell: (props: any) =>
        showPercentage(props.data[props.row.index].hitrate, 0),
      filter: filterDecimals,
      type: RenderType.Percentage,
    },
    {
      Header: "Old part no",
      accessor: "oldPartNo",
    },
    {
      Header: "Sales status",
      accessor: "salesStatus",
    },
    {
      Header: "Target price",
      accessor: "targetPrice",
      Cell: (props: any) => showMoney(props.data[props.row.index].targetPrice),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "Total target price",
      accessor: "totalTargetPrice",
      Cell: (props: any) =>
        showMoney(props.data[props.row.index].totalTargetPrice),
      filter: filterDecimals,
      type: RenderType.Money,
      width: 170
    },
    {
      Header: "ITOR",
      accessor: "itor",
      type: RenderType.Decimal,
    },
    {
      Header: "1Y cons",
      accessor: "oneYCons",
      Cell: (props: any) => showMoney(props.data[props.row.index].oneYCons),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "2Y cons",
      accessor: "twoYCons",
      Cell: (props: any) => showMoney(props.data[props.row.index].twoYCons),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "3Y cons",
      accessor: "threeYCons",
      Cell: (props: any) => showMoney(props.data[props.row.index].threeYCons),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "XYZ",
      accessor: "xyz",
    },
    {
      Header: "Last PO supplier",
      accessor: "lastPoSupplier",
      width: 180,
    },
    {
      Header: "Strategical",
      accessor: "strategical",
      type: RenderType.Bool,
      Cell: (props: any) => {
        var strategical = props.data[props.row.index].strategical;
        return strategical ? "True" : "False";
      },
    },
  ] as TableColumn[];

export const PartsPerPsmColumns = (): TableColumn[] =>
  [    
    {
      Header: "Psm",
      accessor: "psm",
      width: 150,
    },
    {
      Header: "Brand",
      accessor: "brand",
    },
    {
      Header: "ERT",
      accessor: "ert",
    },
    {
      Header: "Severity Group",
      accessor: "severityGroup",
    },
    {
      Header: "Severity Group Description",
      accessor: "severityGroupDescription",
      width: 240,
    },
    {
      Header: "Tactical Part Group",
      accessor: "group",
    },
    {
      Header: "Tactical Part Group Description",
      accessor: "description035Group",
      width: 200,
    },
    {
      Header: "Material",
      accessor: "material",
    },
    {
      Header: "Material Description",
      accessor: "materialDescription",
      width: 200,
    },
    {
      Header: "Successor",
      accessor: "successor",
    },
    {
      Header: "Final Number",
      accessor: "finalNumber",
      width: 200,
    },
    {
      Header: "Comment",
      accessor: "comment",
      width: 300,
    },
    {
      Header: "Material Plant Status",
      accessor: "plantMatStatus",
      width: 190
    },
    {
      Header: "Availability Check",
      accessor: "avCheck",
      width: 175
    },
    {
      Header: "Sales Status",
      accessor: "salesStatus",
    },
    {
      Header: "Auth Group",
      accessor: "authGroup",
    },
    {
      Header: "MRP Profile",
      accessor: "mrpProf",
    },
    {
      Header: "Total Stock",
      accessor: "totalStock",
      type: RenderType.Decimal,
    },
    {
      Header: "ATP",
      accessor: "atp",
      type: RenderType.Decimal,
    },
    {
      Header: "Safety Stock",
      accessor: "safet",
      type: RenderType.Decimal,
    },
    {
      Header: "Unit Value",
      accessor: "unitValue",
      Cell: (props: any) => showMoney(props.data[props.row.index].unitValue),
      type: RenderType.Money,
    },
    {
      Header: "Unit GLP",
      accessor: "unitGlp",
      Cell: (props: any) => showMoney(props.data[props.row.index].unitGlp),
      type: RenderType.Money,
    },
    {
      Header: "Target Price",
      accessor: "targetPrice",
      Cell: (props: any) => showMoney(props.data[props.row.index].targetPrice),
      type: RenderType.Money,
    },
    {
      Header: "Margin",
      accessor: "margin",
      Cell: (props: any) =>
        showPercentage(props.data[props.row.index].margin, 0),
      filter: filterDecimals,
      type: RenderType.Percentage,
    },
    {
      Header: "Total Stock Value",
      accessor: "totStockValue",
      Cell: (props: any) => showMoney(props.data[props.row.index].totStockValue),
      type: RenderType.Money,
    },
    {
      Header: "Book Value",
      accessor: "bookValue",
      Cell: (props: any) => showMoney(props.data[props.row.index].bookValue),
      type: RenderType.Money,
    },
    {
      Header: "Total Book Value",
      accessor: "totBookValue",
      Cell: (props: any) => showMoney(props.data[props.row.index].totBookValue),
      type: RenderType.Money,
    },
    {
      Header: "Hitrate",
      accessor: "hitrate",
      Cell: (props: any) =>
        showPercentage(props.data[props.row.index].hitrate, 0),
      filter: filterDecimals,
      type: RenderType.Percentage,
    },
    {
      Header: "3yr Avg CONS",
      accessor: "threeYearAvgCons",
      Cell: (props: any) =>
        Math.round(props.data[props.row.index].threeYearAvgCons),
      filter: filterDecimals,
      type: RenderType.Number,
    },    
    {
      Header: "CONS1Y",
      accessor: "cons1Y",
      type: RenderType.Decimal,
    },
    {
      Header: "CONS2Y",
      accessor: "cons2Y",
      type: RenderType.Decimal,
    },
    {
      Header: "CONS3Y",
      accessor: "cons3Y",
      type: RenderType.Decimal,
    },
    {
      Header: "CONS4Y",
      accessor: "cons4Y",
      type: RenderType.Decimal,
    },
    {
      Header: "CONS5Y",
      accessor: "cons5Y",
      type: RenderType.Decimal,
    },
    {
      Header: "CONS6Y",
      accessor: "cons6Y",
      type: RenderType.Decimal,
    },
    {
      Header: "QTY QUOTED",
      accessor: "qtyQuoted",
    },
    {
      Header: "Purchase Group Name",
      accessor: "purchaseGroupName",
      width: 210,
    },
    {
      Header: "Product Lead Time",
      accessor: "pdtLT",
      type: RenderType.Decimal,
    },
    {
      Header: "IPT",
      accessor: "ipt",
      type: RenderType.Decimal,

    },
    {
      Header: "ITOR",
      accessor: "itor",
      Cell: (props: any) => showPercentage(props.data[props.row.index].itor, 0),
      filter: filterDecimals,
      type: RenderType.Percentage,
    },
    {
      Header: "GR Processing Time",
      accessor: "grProcessingTime",
    },
    {
      Header: "Procurement Type",
      accessor: "procurementType",
    },
    {
      Header: "Recon",
      accessor: "recon",
      Cell: (props: any) => {
        var recon = props.data[props.row.index].recon;
        return recon ? "True" : "False";
      },
      type: RenderType.Bool,
    },
    {
      Header: "ABC",
      accessor: "abc",
    },
    {
      Header: "XYZ",
      accessor: "xyz",
    },
    {
      Header: "Agreement Supplier",
      accessor: "agr",
    },
    {
      Header: "Country of Origin",
      accessor: "cityOfOrigin",
    },
    {
      Header: "LAST1PO",
      accessor: "last1PO",
      width: 200,
    },
    {
      Header: "LAST2PO",
      accessor: "last2PO",
      width: 200,
    },
    {
      Header: "LAST3PO",
      accessor: "last3PO",
      width: 200,
    },
    {
      Header: "LAST4PO",
      accessor: "last4PO",
      width: 200,
    },
    {
      Header: "Gross Weight",
      accessor: "grossWeight",
      type: RenderType.Decimal,
    },
    {
      Header: "Weight UOM",
      accessor: "weightUom",
    },
    {
      Header: "Dimensions",
      accessor: "dimensions",
    },
    {
      Header: "UM",
      accessor: "um",
      width: 70
    },
    {
      Header: "Product Hierarchy",
      accessor: "productHier",
      width: 175
    },
    {
      Header: "Product Hierarchy Description",
      accessor: "hierDesc2",
      width: 200
    },
    {
      Header: "Material Group",
      accessor: "materialGroup",
    },
    {
      Header: "Material group Description",
      accessor: "materialGroupDescription",
      width: 250,
    },
    {
      Header: "L/O",
      accessor: "lo",
    },
    {
      Header: "OLD part no",
      accessor: "oldPartNo",
    },
    {
      Header: "NSN",
      accessor: "nsn",
    },
    {
      Header: "MWM GLP",
      accessor: "mwmGlp",
      Cell: (props: any) => showMoney(props.data[props.row.index].mwmGlp),
      type: RenderType.Money,
    },
    {
      Header: "Competitor",
      accessor: "competitor",
      width: 200,
    },
    {
      Header: "Comp. price",
      accessor: "compPrice",
      type: RenderType.Money,
    },
    {
      Header: "Comp. date",
      accessor: "compDate",
      Cell: (props: any) => showDate(props.data[props.row.index].compDate),
      type: RenderType.Date,
    }   
    
  ] as TableColumn[];

export const MissedSalesColumns = (): TableColumn[] =>
  [
    {
      Header: "Material",
      accessor: "material",
      width: 120,
    },
    {
      Header: "Material description",
      accessor: "materialDescription",
      width: 340,
    },
    {
      Header: "Comment",
      accessor: "comment",
      width: 180,
    },
    {
      Header: "ERT",
      accessor: "ert",
      width: 90
    },
    {
      Header: "QUOTE QTY",
      accessor: "quoteQty",
      type: RenderType.Decimal,
      width: 90
    },
    {
      Header: "Qty Quoted",
      accessor: "qtyQuoted",
      type: RenderType.Decimal,
      width: 90
    },
    {
      Header: "QUOTE_EUR",
      accessor: "quoteEur",
      Cell: (props: any) => showMoney(props.data[props.row.index].quoteEur),
      type: RenderType.Money,
    },
    {
      Header: "ORDER QTY",
      accessor: "orderQty",
      type: RenderType.Decimal,
    },
    {
      Header: "QtyOrdered",
      accessor: "qtyOrdered",
      type: RenderType.Decimal,
    },
    {
      Header: "Order_EUR",
      accessor: "orderEur",
      Cell: (props: any) => showMoney(props.data[props.row.index].orderEur),
      type: RenderType.Money,
    },
    {
      Header: "Hitrate",
      accessor: "hitrate",
      Cell: (props: any) =>
        showPercentage(props.data[props.row.index].hitrate, 0),
      filter: filterDecimals,
      type: RenderType.Percentage,
    },
    {
      Header: "Unit Value",
      accessor: "unitValue",
      Cell: (props: any) => showMoney(props.data[props.row.index].unitValue),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "Unit GLP",
      accessor: "unitGlp",
      Cell: (props: any) => showMoney(props.data[props.row.index].unitGlp),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "Margin",
      accessor: "margin",
      Cell: (props: any) =>
        showPercentage(props.data[props.row.index].margin, 0),
      filter: filterDecimals,
      type: RenderType.Percentage,
    },
    {
      Header: "Total Stock",
      accessor: "totStk",
      type: RenderType.Decimal,
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Availability Check",
      accessor: "avCheck",
    },
    {
      Header: "Target price",
      accessor: "targetPrice",
      Cell: (props: any) => showMoney(props.data[props.row.index].targetPrice),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "Group",
      accessor: "group",
    },
    {
      Header: "Successor",
      accessor: "successor",
    },
    {
      Header: "LAST1PO",
      accessor: "last1Po",
      width: 320,
    },
    {
      Header: "Missed sales",
      accessor: "missedSales",
      Cell: (props: any) => showMoney(props.data[props.row.index].missedSales),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "SSSS",
      accessor: "ssss",
      width: 240,
    },
    {
      Header: "Total stock value",
      accessor: "totStockValue",
      Cell: (props: any) =>
        showMoney(props.data[props.row.index].totStockValue),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "Total book value",
      accessor: "totBookValue",
      Cell: (props: any) => showMoney(props.data[props.row.index].totBookValue),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "Safet",
      accessor: "safet",
      type: RenderType.Decimal,
    },
    {
      Header: "Competitor",
      accessor: "competitor",
      width: 260
    },
    {
      Header: "Comp price",
      accessor: "compPrice",
      Cell: (props: any) => showMoney(props.data[props.row.index].compPrice),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "Comp date",
      accessor: "compDate",
      Cell: (props: any) => showDate(props.data[props.row.index].compDate),
      filter: filterDate,
      type: RenderType.Date,
    },
  ] as TableColumn[];

export const DashboardExportColumns = (): TableColumn[] =>
  [
    {
      Header: "Item No",
      accessor: "sequenceNumber",
      width: 50
    },
    {
      Header: "Material Input",
      accessor: "searchMaterial",
      width: 155
    },
    {
      Header: "Requested Quantity",
      accessor: "quantity",
      width: 105
    },
    {
      Header: "Price Customer Request",
      accessor: "singleValueEur",
      Cell: (props: any) => showMoney(props.data[props.row.index].singleValueEur),
      type: RenderType.Decimal,
      width: 130
    },
    {
      Header: "Translated Material",
      accessor: "translatedMaterial",
      width: 130
    },
    {
      Header: "ATP Translated material",
      accessor: "translatedATP",
      type: RenderType.Decimal,
      width: 75
    },
    {
      Header: "Final Successor",
      accessor: "finalSuccessor",
      width: 130
    },
    {
      Header: "ATP Final Successor",
      accessor: "atp",
      type: RenderType.Decimal,
      width: 75
    },
    {
      Header: "Stock Sufficient",
      accessor: "stockSufficient",
      width: 160
    },
    {
      Header: "Material Description",
      accessor: "materialDescription",
      width: 250
    },
    {
      Header: "UM",
      accessor: "um",
      width: 70
    },
    {
      Header: "Material Status",
      accessor: "status",
    },
    {
      Header: "Sales Status",
      accessor: "salesStatus",
    },
    {
      Header: "Comment",
      accessor: "comment",
      width: 240,
    },
    {
      Header: "Availability Check",
      accessor: "AvCheck",
    },
    {
      Header: "Auth Group",
      accessor: "authGroup",
    },
    {
      Header: "MRP Profile",
      accessor: "mrpProf",
    },
    {
      Header: "Total Stock",
      accessor: "totStk",
      width: 15,
    },    
    {
      Header: "Safety stock",
      accessor: "safet",
      type: RenderType.Decimal,
      width: 90
    },
    {
      Header: "Cost price per pc",
      accessor: "unitValue",
      Cell: (props: any) => showMoney(props.data[props.row.index].unitValue),
      filter: filterDecimals,
      type: RenderType.Money,
      width: 130
    },
    {
      Header: "Total Cost Price",
      accessor: "totalCostEur",
      Cell: (props: any) => showMoney(props.data[props.row.index].totalCostEur),
      filter: filterDecimals,
      type: RenderType.Money,
      width: 120
    },
    {
      Header: "GLP per pc",
      accessor: "glpUnit",
      Cell: (props: any) => showMoney(props.data[props.row.index].glpUnit),
      filter: filterDecimals,
      type: RenderType.Money,
      width: 115
    },
    {
      Header: "Total GLP",
      accessor: "totalGlpValue",
      Cell: (props: any) =>
        showMoney(props.data[props.row.index].totalGlpValue),
      filter: filterDecimals,
      type: RenderType.Money,
      width: 150
    },
    // Potential duplicate (totalValueEur is now replaced with singleValueEur)
    // Price Customer Request * Request Quantity (according to XML)
    // (Total) Price Customer Request / Request Quantity => SingleValueEur
    // (Total) Price Customer Request => totalValueEur
    // REMOVE COMMENT BEFORE MERGING
    { 
      Header: "Total Price Customer Request",
      accessor: "totalValueEur",
      Cell: (props: any) =>
        showMoney(props.data[props.row.index].totalValueEur),
      type: RenderType.Money,
      width: 130
    },
    {
      Header: "Target Price",
      accessor: "targetPrice",
      Cell: (props: any) => showMoney(props.data[props.row.index].targetPrice),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "Margin",
      accessor: "margin",
      Cell: (props: any) =>
        showPercentage(props.data[props.row.index].margin, 0),
      filter: filterDecimals,
      type: RenderType.Percentage,
    },
    {
      Header: "Total Stock Value",
      accessor: "totStockValue",
      Cell: (props: any) =>
        showMoney(props.data[props.row.index].totStockValue),
      type: RenderType.Money,
    },
    {
      Header: "Book Value",
      accessor: "bookValue",
      Cell: (props: any) => showMoney(props.data[props.row.index].bookValue),
      type: RenderType.Money,
    },
    {
      Header: "Total Book Value",
      accessor: "totBookValue",
      Cell: (props: any) => showMoney(props.data[props.row.index].totBookValue),
      type: RenderType.Money,
    },
    {
      Header: "Hitrate",
      accessor: "hitrate",
      Cell: (props: any) =>
        showPercentage(props.data[props.row.index].hitrate, 0),
      filter: filterDecimals,
      type: RenderType.Percentage,
    },  
    {
      Header: "3yr avg cons",
      accessor: "threeYearAvgCons",
      filter: filterDecimals,
    },
    {
      Header: "Cons1Y",
      accessor: "conS1Y",
      type: RenderType.Decimal,
    },
    {
      Header: "Cons2Y",
      accessor: "conS2Y",
      type: RenderType.Decimal,
    },
    {
      Header: "Cons3Y",
      accessor: "conS3Y",
      type: RenderType.Decimal,
    },
    {
      Header: "Cons4Y",
      accessor: "conS4Y",
      type: RenderType.Decimal,
    },
    {
      Header: "Cons5Y",
      accessor: "conS5Y",
      type: RenderType.Decimal,
    },
    {
      Header: "Cons6Y",
      accessor: "conS6Y",
      type: RenderType.Decimal,
    },
    {
      Header: "Quote Qty",
      accessor: "quoteQty",
      type: RenderType.Decimal,
    },
    {
      Header: "Qty Quoted",
      accessor: "qtyQuoted",
      type: RenderType.Decimal,
    },
    {
      Header: "QUOTE EUR",
      accessor: "quotE_EUR",
      Cell: (props: any) => showMoney(props.data[props.row.index].quotE_EUR),
      filter: filterDecimals,
      type: RenderType.Money,
    },
    {
      Header: "OrderQty",
      accessor: "orderQty",
      type: RenderType.Decimal,
    },
    {
      Header: "Qty Ordered",
      accessor: "qtyOrdered",
      type: RenderType.Decimal,
    },
    {
      Header: "ORDER EUR",
      accessor: "ordeR_EUR",
      Cell: (props: any) => showMoney(props.data[props.row.index].ordeR_EUR),
      filter: filterDecimals,
      type: RenderType.Money,
    },    
    {
      Header: "ITOR",
      accessor: "itor",
      Cell: (props: any) =>
        showDecimal(props.data[props.row.index].itor, 1, navigator.language),
      type: RenderType.Decimal,
      width: 80
    },
    {
      Header: "Product Lead-time",
      accessor: "pdT_LT",
      type: RenderType.Decimal,
    },
    {
      Header: "GR Processing Time",
      accessor: "grProcessingTime",
    },
    {
      Header: "Procurement Type",
      accessor: "ProcType",
    },
    {
      Header: "Recon",
      accessor: "recon",
      Cell: (props: any) => {
        var recon = props.data[props.row.index].recon;
        return recon ? "1" : "0";
      },
      type: RenderType.Bool,
    },
    {
      Header: "ABC",
      accessor: "abc",
    },
    {
      Header: "XYZ",
      accessor: "xyz",
    },
    {
      Header: "Agreement supplier",
      accessor: "agreementSupplier",
      width: 280,
    },
    {
      Header: "Purchase GroupName",
      accessor: "purchaseGroupName",
      width: 240,
    },
    {
      Header: "Country of origin",
      accessor: "countryOfOrigin",
    },
    {
      Header: "Last 1po",
      accessor: "lasT1PO",
      width: 255,
    },
    {
      Header: "LAST2PO",
      accessor: "last2PO",
      width: 200,
    },
    {
      Header: "LAST3PO",
      accessor: "last3PO",
      width: 200,
    },
    {
      Header: "LAST4PO",
      accessor: "last4PO",
      width: 200,
    },
    {
      Header: "Psm",
      accessor: "ssss",
      width: 150,
    },
    {
      Header: "Tactical Group",
      accessor: "group",
    },
    {
      Header: "Tact Group description",
      accessor: "groupDescription",
      width: 240,
    },
    {
      Header: "Severity Group",
      accessor: "severityGroup",
      width: 100,
    },
    {
      Header: "Severity Group Description",
      accessor: "severityGroupDescription",
      width: 100,
    },
    {
      Header: "Gross Weight",
      accessor: "grossWeight",
      type: RenderType.Decimal,
    },
    {
      Header: "Weight UOM",
      accessor: "weightUom",
    },
    {
      Header: "Dimensions",
      accessor: "dimensions",
    },
    // { // DUPLICATE
    //   Header: "UM",
    //   accessor: "um",
    //   width: 70
    // },
    {
      Header: "Product Hierarchy",
      accessor: "productHierarchy",
      width: 175
    },
    { // NEW
      Header: "Product Hierarchy description 2",
      accessor: "productHierDesc2",
      width: 175
    },
    {
      Header: "Material Group",
      accessor: "matlGroup",
    },
    {
      Header: "Material Group Description",
      accessor: "materialGroupDescription",
    },
    {
      Header: "L/O",
      accessor: "lo",
    },
    {
      Header: "Old Part No",
      accessor: "oldPartNo",
    },
    {
      Header: "NSN",
      accessor: "nsn",
    },
    {
      Header: "MWM GLP",
      accessor: "MWMPLP",
      Cell: (props: any) => showMoney(props.data[props.row.index].mwmplp ?? 0),
      type: RenderType.Money,
    },
    {
      Header: "Competitor",
      accessor: "competitor",
      width: 200,
    },
    {
      Header: "Comp. price",
      accessor: "compPrice",
      type: RenderType.Money,
    },
    {
      Header: "Comp. date",
      accessor: "compDate",
      Cell: (props: any) => showDate(props.data[props.row.index].compDate),
      type: RenderType.Date,
    }
  ] as TableColumn[];

export const renderAsTextList = [
  "Material",
  "Status",
  "Group",
  "ERT",
  "Severity Group",
  "Material Plant Status",
  "Sales Status",
  "Group",
  "Final Number",
  "Material Group",
  "Interval",
  "Comment",
  "Last successor",
  "Comment",
  "Purchase Info",
  "Supplier Name",
  "Type",
  "Supplier No",
  "Plant",
  "Sales Doc",
  "Item",
  "Engine nr",
  "Customer",
  "Telephone",
  "Successor",
  "Search Material",
];
// Material Plant Status Sales Status Group Final Number Material Group

export interface SortProps {
  id: string;
  desc: boolean;
}